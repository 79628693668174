"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _yandex = require("./yandex");

Object.defineProperty(exports, "YandexGeocoding", {
  enumerable: true,
  get: function get() {
    return _interopRequireDefault(_yandex).default;
  }
});

var _google = require("./google");

Object.defineProperty(exports, "GoogleGeocoding", {
  enumerable: true,
  get: function get() {
    return _interopRequireDefault(_google).default;
  }
});

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }