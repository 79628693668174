"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _operators = require("rxjs/operators");

var firstToPromise = function firstToPromise(observable) {
  return observable.pipe((0, _operators.first)()).toPromise();
};

exports.default = firstToPromise;