// @flow
import type { Reducer } from "redux";
import {
  USER_APPLICATION_SUBMITTED,
  USER_APPLICATION_SUBMIT_RESULT,
} from "../actions";
import type { ApplicationId } from "../models";
import type { Action } from "../actions";
import { persistReducer } from "../persistence";
import { combineReducers } from "./utils";

export type State = {|
  applicationId: ?ApplicationId,
  submitting: boolean,
|};

const enhance = persistReducer({
  key: "signup",
  whitelist: ["applicationId"],
});

const signupReducer: Reducer<State, Action> = combineReducers({
  applicationId: (state = null, action) => {
    switch (action.type) {
      case USER_APPLICATION_SUBMIT_RESULT:
        return action.payload.application.id;
      default:
        return state;
    }
  },
  submitting: (state = false, action) => {
    switch (action.type) {
      case USER_APPLICATION_SUBMITTED:
        return true;
      case USER_APPLICATION_SUBMIT_RESULT:
        return false;
      default:
        return state;
    }
  },
});

export default enhance(signupReducer);
