// @flow
import type { List as ImmutableList } from "immutable";
import invariant from "invariant";
import * as React from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import {
  VirtualizedList,
  ApplicationApproveDialog,
  ApplicationRejectDialog,
} from "../components";
import {
  applicationListShown,
  applicationListHidden,
  applicationResolve,
} from "../actions";
import {
  makeGetApplicationListIds,
  makeIsApplicationListLoaded,
} from "../selectors";
import type { Application, ApplicationId } from "../models";
import ApplicationListItemContainer from "./ApplicationListItemContainer";
import { withSideEffects } from "../hocs";

type Props = {
  ids: ImmutableList<ApplicationId>,
  loaded: boolean,
  applicationResolve: (
    application: Application,
    approved: boolean,
    comment?: ?string
  ) => void,
};

type State = {|
  pendingResolution: ?{|
    application: Application,
    approved: boolean,
  |},
|};

const makeMapStateToProps = () =>
  createStructuredSelector({
    ids: makeGetApplicationListIds(),
    loaded: makeIsApplicationListLoaded(),
  });

const enhance = compose(
  connect(
    makeMapStateToProps,
    {
      applicationListShown,
      applicationListHidden,
      applicationResolve,
    }
  ),
  withSideEffects({
    onMount: ({ applicationListShown }) => applicationListShown(),
    onUnmount: ({ applicationListHidden }) => applicationListHidden(),
  })
);

class ApplicationListContainer extends React.Component<Props, State> {
  state = { pendingResolution: null };

  handleApproveClick = application => {
    this.setState({
      pendingResolution: {
        application,
        approved: true,
      },
    });
  };

  handleRejectClick = application => {
    this.setState({
      pendingResolution: {
        application,
        approved: false,
      },
    });
  };

  handleDialogClose = () => {
    this.setState({
      pendingResolution: null,
    });
  };

  handleApproveConfirm = () => {
    invariant(this.state.pendingResolution, "Resolution must be pending");

    const { application } = this.state.pendingResolution;

    this.setState({ pendingResolution: null }, () => {
      this.props.applicationResolve(application, true);
    });
  };

  handleRejectConfirm = ({ comment }: { comment: ?string }) => {
    invariant(this.state.pendingResolution, "Resolution must be pending");

    const { application } = this.state.pendingResolution;

    this.setState({ pendingResolution: null }, () => {
      this.props.applicationResolve(application, false, comment);
    });
  };

  render() {
    const { ids, loaded } = this.props;
    const { pendingResolution } = this.state;

    return (
      <React.Fragment>
        <VirtualizedList
          loading={!loaded}
          items={ids}
          rowRenderer={({ item }) => (
            <ApplicationListItemContainer
              id={item}
              onApproveClick={this.handleApproveClick}
              onRejectClick={this.handleRejectClick}
            />
          )}
        />
        <ApplicationApproveDialog
          open={pendingResolution && pendingResolution.approved}
          application={pendingResolution && pendingResolution.application}
          onClose={this.handleDialogClose}
          onConfirm={this.handleApproveConfirm}
        />
        <ApplicationRejectDialog
          open={pendingResolution && !pendingResolution.approved}
          application={pendingResolution && pendingResolution.application}
          onClose={this.handleDialogClose}
          onConfirm={this.handleRejectConfirm}
        />
      </React.Fragment>
    );
  }
}

export default enhance(ApplicationListContainer);
