// @flow
import * as React from "react";
import styled from "styled-components";
import { I18n } from "react-i18next";
import { Paper, Typography } from "material-ui";
import ListItem from "./ListItem";
import type { Place } from "../models";
import DateFns from "./DateFns";

type Props = {
  ...$Exact<React.ElementProps<typeof ListItem>>,
  place: Place,
};

const Container = styled.div`
  display: flex;
  align-items: flex-start;
`;

const PictureContainer = styled(Paper)`
  margin-right: 16px;
`;

const Picture = styled.img`
  display: block;
  width: 72px;
  height: 72px;
  object-fit: cover;
  border-radius: 4px;
`;

const Content = styled.div`
  flex: 1;
`;

const PlaceListItem = ({ place, ...rest }: Props) => (
  <ListItem {...rest}>
    <Container>
      <PictureContainer>
        <Picture src={place.getLeadingPhoto().w300} />
      </PictureContainer>
      <Content>
        <Typography gutterBottom variant="body1">
          {place.getTitle()}
        </Typography>
        <Typography gutterBottom variant="caption">
          {place.getDisplayAddress()}
        </Typography>
        <I18n>
          {t => (
            <DateFns>
              {({ formatRelative }) => (
                <Typography gutterBottom variant="caption">
                  {place.updatedAt > place.createdAt
                    ? t("general.updated_at", {
                        time: formatRelative(place.updatedAt, Date.now()),
                      })
                    : t("general.created_at", {
                        time: formatRelative(place.createdAt, Date.now()),
                      })}
                </Typography>
              )}
            </DateFns>
          )}
        </I18n>
      </Content>
    </Container>
  </ListItem>
);

export default PlaceListItem;
