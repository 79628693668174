export { formatRoute } from "react-router-named-routes";

export const ROOT = "/";
export const AUTH = "/auth";
export const INVITE_ACCEPT = "/invite/:id";
export const APPLICATION_CREATE = "/signup";
export const APPLICATION_UPDATE = "/signup/update";
export const MEMBER_LIST = "/members";
export const MEMBER_DETAILS = "/members/:id";
export const INVITE_LIST = "/invites";
export const INVITE_DETAILS = "/invites/:id";
export const ORGANIZATION_LIST = "/organizations";
export const ORGANIZATION_CREATE = "/organizations/create";
export const ORGANIZATION_DETAILS = "/organizations/:id";
export const ORGANIZATION_MEMBERS = "/organizations/:id/members";
export const ORGANIZATION_PLACES = "/organizations/:id/places";
export const ORGANIZATION_INVITES = "/organizations/:id/invites";
export const PLACE_LIST = "/places";
export const PLACE_CREATE = "/places/new";
export const PLACE_DETAILS = "/places/:id";
export const PLACE_EDIT_DETAILS = "/places/:id/details";
export const PLACE_EDIT_ADDRESS = "/places/:id/address";
export const PLACE_EDIT_PHOTOS = "/places/:id/photos";
export const PLACE_EDIT_SCHEDULE = "/places/:id/schedule";
export const PLACE_PHOTOS = "/places/:id/photos";
export const APPLICATION_LIST = "/applications";
