// @flow
import * as React from "react";
import { connect } from "react-redux";
import { I18n } from "react-i18next";
import { PersonAdd as PersonAddIcon } from "material-ui-icons";
import { FloatingActionButton } from "../components";
import { memberCreateButtonPressed } from "../actions";

type Props = {
  memberCreateButtonPressed: () => void,
};

const enhance = connect(
  null,
  { memberCreateButtonPressed }
);

const MemberCreateButtonContainer = ({ memberCreateButtonPressed }: Props) => (
  <I18n>
    {t => (
      <FloatingActionButton
        aria-label={t("invites.list.create_action")}
        icon={<PersonAddIcon />}
        color="secondary"
        onClick={memberCreateButtonPressed}
      />
    )}
  </I18n>
);

export default enhance(MemberCreateButtonContainer);
