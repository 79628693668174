"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require("react");

var React = _interopRequireWildcard(_react);

var _GoogleStaticMap = require("./GoogleStaticMap");

var _GoogleStaticMap2 = _interopRequireDefault(_GoogleStaticMap);

var _GoogleMap = require("./GoogleMap");

var _GoogleMap2 = _interopRequireDefault(_GoogleMap);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var GoogleAdapter = function GoogleAdapter(_ref) {
  var _this = this;

  var language = _ref.language,
      apiKey = _ref.apiKey,
      _ref$libraries = _ref.libraries,
      libraries = _ref$libraries === undefined ? [] : _ref$libraries;

  _classCallCheck(this, GoogleAdapter);

  this.MapComponent = function (props) {
    return props.static ? React.createElement(_GoogleStaticMap2.default, _extends({
      language: _this.language,
      apiKey: _this.apiKey,
      libraries: _this.libraries
    }, props)) : React.createElement(_GoogleMap2.default, _extends({
      language: _this.language,
      apiKey: _this.apiKey,
      libraries: _this.libraries
    }, props));
  };

  this.language = language;
  this.apiKey = apiKey;
  this.libraries = libraries;
};

exports.default = GoogleAdapter;