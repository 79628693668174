// @flow
import * as React from "react";
import { getDisplayName } from "recompose";

/**
 * Captures specified props on initial mount and ignores further
 * changes.
 *
 * Useful for avoiding updates when passing a function created in
 * render mehtod to callback prop.
 */
const withStaticHandlers = <T: {}>(keys: Array<$Keys<T>>) => (
  InnerComponent: React.ComponentType<T>
) => {
  class WithStaticHandlers extends React.Component<T> {
    injectedProps: $Shape<T>;

    constructor(props: T) {
      super();

      this.injectedProps = keys.reduce(
        (injectedProps, key) =>
          props[key]
            ? {
                ...injectedProps,
                [key]: props[key],
              }
            : injectedProps,
        {}
      );
    }

    render() {
      return <InnerComponent {...this.props} {...this.injectedProps} />;
    }
  }

  WithStaticHandlers.displayName = `withStaticHandlers<${getDisplayName(
    InnerComponent
  )}>`;

  return WithStaticHandlers;
};

export default withStaticHandlers;
