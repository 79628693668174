// @flow
import type { Reducer } from "redux";
import { List } from "immutable";
import { LOCATION_CHANGE } from "react-router-redux";
import {
  DISMISS_NOTIFICATION,
  INVITE_LIST_LOADED,
  USER_APPLICATION_SUBMIT_RESULT,
  APPLICATION_RESOLVE_RESULT,
  ACCEPT_INVITE_RESULT,
  PLACE_UPLOAD_COMPLETE,
  type Action,
} from "../actions";
import { makeNotification, type Notification } from "../models";
import { NOTIFICATION_DURATION_SHORT as SHORT } from "../constants";

export type State = List<Notification>;

const initialState: State = List();

const addNotification = (
  state: State,
  message: string,
  args: Object = {},
  duration: number = SHORT
) => state.push(makeNotification({ message, args, duration }));

const notificationReducer: Reducer<State, Action> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    // Clear notifications on route change
    case LOCATION_CHANGE:
      return initialState;

    case DISMISS_NOTIFICATION:
      return state.shift();

    case USER_APPLICATION_SUBMIT_RESULT:
      if (action.payload instanceof Error) {
        return addNotification(state, "applications.submit_error");
      }

      if (action.payload.update) {
        return addNotification(state, "applications.update_success");
      }

      return addNotification(state, "applications.create_success");

    case APPLICATION_RESOLVE_RESULT: {
      if (action.payload instanceof Error) {
        return addNotification(state, "applications.resolve_error");
      }

      const { application } = action.payload;

      if (action.payload.approved) {
        return addNotification(state, "applications.approve_success", {
          fullName: application.fullName,
        });
      }

      return addNotification(state, "applications.reject_success", {
        fullName: application.fullName,
      });
    }

    case INVITE_LIST_LOADED: {
      if (!(action.payload instanceof Error)) {
        return state;
      }

      return addNotification(state, "invites.list_load_error");
    }

    case ACCEPT_INVITE_RESULT: {
      if (action.payload instanceof Error) {
        return addNotification(state, "invites.accept_error");
      }

      return addNotification(state, "invites.accept_success");
    }

    case PLACE_UPLOAD_COMPLETE: {
      if (action.payload instanceof Error) {
        return addNotification(state, "upload.error");
      }

      return state;
    }

    default:
      return state;
  }
};

export default notificationReducer;
