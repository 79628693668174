// @flow

import styled from "styled-components";

const Row = styled.div`
  margin-bottom: 8px;
`;

Row.displayName = "Row";

export default Row;
