// @flow
import * as React from "react";
import type { HOC } from "recompose";

const withSideEffects = <T: {}>(options: {|
  onMount?: (props: T) => void,
  onUpdate?: (prevProps: T, nextProps: T) => void,
  onUnmount?: (props: T) => void,
|}): HOC<T, T> => (Component: React.ComponentType<T>) =>
  class extends React.Component<T> {
    prevProps: T = this.props;

    componentDidMount() {
      const { onMount } = options;

      if (onMount !== undefined && onMount !== null) {
        onMount(this.props);
      }
    }

    componentDidUpdate() {
      const { onUpdate } = options;

      if (onUpdate !== undefined && onUpdate !== null) {
        onUpdate(this.props, this.prevProps);
      }

      this.prevProps = this.props;
    }

    componentWillUnmount() {
      const { onUnmount } = options;

      if (onUnmount !== undefined && onUnmount !== null) {
        onUnmount(this.props);
      }
    }

    render() {
      return <Component {...this.props} />;
    }
  };

export default withSideEffects;
