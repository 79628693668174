// @flow
import invariant from "invariant";
import * as React from "react";
import styled from "styled-components";
import { I18n } from "react-i18next";
import Downshift from "downshift";
import {
  Input,
  Paper,
  MenuList,
  MenuItem,
  ListItemIcon,
  ListItemText,
  CircularProgress,
} from "material-ui";
import type { List } from "immutable";
import { Place as PlaceIcon } from "material-ui-icons";
import type { AddressSuggestion } from "../models";

type Props = {
  value: string,
  loading?: boolean,
  autoFocus?: boolean,
  suggestions: List<AddressSuggestion>,
  onChange: (e: SyntheticEvent<HTMLInputElement>) => void,
  onComplete: (item: AddressSuggestion) => void,
};

/**
 * Highlight matches in a string.
 *
 * Returns an array with all highilghted parts processed as specified by highlightFn
 */
const highlight = (
  text: string,
  highlights: Array<[number, number]>,
  highlightFn: (text: string) => React.Node
) =>
  // This helps avoid 'Each child in an array should have a unique "key" prop.' error.
  React.createElement(
    React.Fragment,
    {},
    ...highlights.reduceRight(
      ([text, ...tail], [start, end]) => {
        invariant(typeof text === "string", "Text must be a string");

        return [
          text.substring(0, start),
          highlightFn(text.substring(start, end)),
          text.substring(end),
          ...tail,
        ];
      },
      [text]
    )
  );

const defaultProps = {
  loading: false,
  autoFocus: false,
};

const Container = styled.div`
  position: relative;
`;

const MenuContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  z-index: 100;
`;

const AddressField = ({
  value,
  loading,
  autoFocus,
  suggestions,
  onChange,
  onComplete,
  ...rest
}: Props) => (
  <I18n>
    {t => (
      <Downshift
        inputValue={value}
        itemToString={item => item && item.value}
        onSelect={onComplete}
        render={({
          getRootProps,
          getInputProps,
          getItemProps,
          isOpen,
          highlightedIndex,
        }) => (
          <Container {...getRootProps({ refKey: "innerRef" })}>
            <Input
              {...rest}
              fullWidth
              placeholder={t("general.address.placeholder")}
              autoFocus={autoFocus}
              inputProps={getInputProps({
                style: {
                  height: 24,
                  padding: "8px 0 8px 16px",
                },
                onKeyDown: event => {
                  // By default downshift will prevent using Return
                  // key to submit the form.  We need to disable this
                  // behavior unless user has highlighted an item, in
                  // which case the key should be used to select
                  // highlighted item.
                  // See: https://github.com/paypal/downshift/blob/a9f17d01fd39f09d65f2ebd753df320a46f1d478/README.md#customizing-handlers
                  if (event.key === "Enter" && highlightedIndex === null) {
                    event.preventDownshiftDefault = true; // eslint-disable-line no-param-reassign
                  }
                },
                onChange,
              })}
              endAdornment={loading ? <CircularProgress size={40} /> : null}
            />
            {suggestions.size && isOpen ? (
              <MenuContainer>
                <Paper>
                  <MenuList data-cy="suggestions">
                    {suggestions.map((item, index) => (
                      <MenuItem
                        key={item.value}
                        selected={index === highlightedIndex}
                        {...getItemProps({ item })}
                      >
                        <ListItemIcon>
                          <PlaceIcon />
                        </ListItemIcon>
                        <ListItemText
                          inset
                          primary={highlight(
                            item.displayName,
                            item.hl,
                            string => <strong>{string}</strong>
                          )}
                          secondary={item.value}
                        />
                      </MenuItem>
                    ))}
                  </MenuList>
                </Paper>
              </MenuContainer>
            ) : null}
          </Container>
        )}
      />
    )}
  </I18n>
);

AddressField.defaultProps = defaultProps;

export default AddressField;
