// @flow
import { AUTHENTICATION_STATUS_CHANGE, LOGOUT } from "../actions";
import type { User, Member } from "../models";

export type State = {|
  ready: boolean,
  user: ?User,
  member: ?Member,
|};

const initialState: State = {
  ready: false,
  user: null,
  member: null,
};

const authReducer = (state: State = initialState, action: Object) => {
  switch (action.type) {
    case AUTHENTICATION_STATUS_CHANGE: {
      const { user, member } = action.payload;

      return {
        ready: true,
        user,
        member,
      };
    }

    case LOGOUT: {
      return {
        ...state,
        user: null,
        member: null,
      };
    }

    default:
      return state;
  }
};

export default authReducer;
