// @flow
import * as React from "react";
import { compose } from "recompose";
import { withRouter, type ContextRouter } from "react-router";
import { translate, type TranslatorProps } from "react-i18next";
import { Toolbar, Form, FormActionsToolbar, AddressForm } from "../components";
import {
  LayoutContainer,
  PlaceContainer,
  PlaceEditContainer,
} from "../containers";
import { formatRoute, PLACE_DETAILS } from "../routes";
import type { PlaceId } from "../models";

type Props = {
  ...$Exact<ContextRouter>,
  ...$Exact<TranslatorProps>,
  id: PlaceId,
};

const enhance = compose(
  withRouter,
  translate()
);

class PlaceEditLocationScreen extends React.Component<Props> {
  goBack = () => {
    const { id, history } = this.props;

    history.push(formatRoute(PLACE_DETAILS, { id }));
  };

  render() {
    const { t, id } = this.props;

    return (
      <PlaceContainer
        id={id}
        render={() => (
          <PlaceEditContainer
            id={id}
            render={({
              address,
              coordinates,
              addressSuggestions,
              addressSuggestionsLoading,
              geocodeLoading,
              changeAddress,
              changeCoordinates,
              selectAddressSuggestion,
              updatePlace,
            }) => (
              <AddressForm
                loading={addressSuggestionsLoading || geocodeLoading}
                address={address}
                coordinates={coordinates}
                suggestions={addressSuggestions}
                onAddressChange={changeAddress}
                onCoordinatesChange={changeCoordinates}
                onSuggestionSelect={selectAddressSuggestion}
                onSubmit={location =>
                  updatePlace({
                    location,
                  })
                }
                render={({ children, onSubmit }) => (
                  <Form preventDefault onSubmit={onSubmit}>
                    <LayoutContainer
                      toolbar={
                        <Toolbar
                          onGoBack={this.goBack}
                          title={t("places.title.location")}
                        />
                      }
                      footer={
                        <FormActionsToolbar
                          onCancel={this.goBack}
                          onSubmit={onSubmit}
                        />
                      }
                    >
                      {children}
                    </LayoutContainer>
                  </Form>
                )}
              />
            )}
          />
        )}
      />
    );
  }
}

export default enhance(PlaceEditLocationScreen);
