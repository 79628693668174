// @flow
import * as React from "react";
import type { PlacePhoto as PlacePhotoType } from "../models";

type Props = {
  photo: PlacePhotoType,
};

const PlacePhoto = ({ photo, ...rest }: Props) => (
  // eslint-disable-next-line jsx-a11y/alt-text
  <img
    {...rest}
    srcSet={`
          ${photo.w300} 300w,
          ${photo.w780} 780w,
          ${photo.w1280} 1280w
        `}
    sizes="
          (max-width: 320px) 160px,
          320px
        "
    src={photo.w1280}
  />
);

export default PlacePhoto;
