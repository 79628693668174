// @flow
import { Record, type RecordOf, type RecordFactory } from "immutable";
import type { User as BaseUser, UserId as BaseUserId } from "outnabout-api";

/**
 * It may seem that User is kind of redundant considering we have
 * Member, but its actually needed for a situation where we need to
 * distinguish unauthenticated user from authenticated user without
 * member record.
 *
 * One such example, is InviteAcceptScreen, where we need to know user
 * id dispite them not having a Member Record, for this record to be
 * created.
 *
 * It may be worth, however, removing usages of User from other places.
 */

export type UserId = BaseUserId;

export type UserFields = BaseUser;

export type User = RecordOf<UserFields>;

const makeUser: RecordFactory<UserFields> = Record(
  {
    uid: "",
    displayName: null,
    photoURL: null,
    email: null,
    isAnonymous: true,
  },
  "User"
);

export default makeUser;
