// @flow
import * as React from "react";
import { compose, branch, renderNothing } from "recompose";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { makeGetAuthenticatedMember } from "../selectors";
import { logout } from "../actions";
import type { Member } from "../models";

type RenderProps = {
  member: Member,
  logout: () => void,
};

type Props = {
  ...$Exact<RenderProps>,
  children: (props: RenderProps) => React.Node,
};

const makeMapStateToProps = createStructuredSelector({
  member: makeGetAuthenticatedMember(),
});

const enhance = compose(
  connect(
    makeMapStateToProps,
    { logout }
  ),
  branch(({ member }) => !member, renderNothing)
);

const CurrentMemberContainer = ({ member, children, logout }: Props) => {
  if (typeof children === "function") {
    return children({ member, logout });
  }

  return React.Children.only(children);
};
export default enhance(CurrentMemberContainer);
