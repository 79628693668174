// @flow
import * as React from "react";
import styled from "styled-components";
import SvgLogo from "./SvgLogo";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 16px;
`;

const StyledSvgLogo = styled(SvgLogo)`
  margin-bottom: 12px;
`;

const Logo = () => (
  <Container>
    <StyledSvgLogo />
  </Container>
);

export default Logo;
