// @flow
import type { Reducer } from "redux";
import { LOCATION_CHANGE } from "react-router-redux";
import { DRAWER_OPEN, DRAWER_CLOSE, type Action } from "../actions";
import { combineReducers } from "./utils";

export type State = {|
  drawerOpen: boolean,
|};

const uiReducer: Reducer<State, Action> = combineReducers({
  drawerOpen: (state = false, action) => {
    switch (action.type) {
      case LOCATION_CHANGE:
        return false;
      case DRAWER_OPEN:
        return true;
      case DRAWER_CLOSE:
        return false;
      default:
        return state;
    }
  },
});

export default uiReducer;
