// @flow
import * as React from "react";
import * as colors from "material-ui/colors";

type Props = {
  text: string,
};

const COLORS = [
  "red",
  "pink",
  "purple",
  "deepPurple",
  "indigo",
  "blue",
  "lightBlue",
  "cyan",
  "teal",
  "green",
  "lightGreen",
  "lime",
  "yellow",
  "amber",
  "orange",
  "deepOrange",
  "brown",
].map(color => colors[color][800]);

// See: http://werxltd.com/wp/2010/05/13/javascript-implementation-of-javas-string-hashcode-method/
const hashCode = text => {
  let hash = 0;

  if (text.length === 0) {
    return hash;
  }

  for (let i = 0; i < text.length; i += 1) {
    const chr = text.charCodeAt(i);
    hash = (hash << 5) - hash + chr; // eslint-disable-line no-bitwise
    hash |= 0; // eslint-disable-line no-bitwise
  }
  return hash;
};

const getInitials = text => {
  const capitals = text.replace(/[^A-Z]/g, "");

  if (capitals.length > 1) {
    return capitals.slice(0, 2);
  }

  const words = text.split(/\s/);

  if (words.length > 1) {
    return words[0][0] + words[1][0];
  }

  return text.slice(0, 2);
};

const getColor = text => COLORS[hashCode(text) % COLORS.length];

const InitialsAvatar = ({ text }: Props) => {
  const initials = getInitials(text);
  const color = getColor(text);

  return (
    <svg viewBox="0 0 48 48" width="100%" height="100%">
      <rect x="0" y="0" width="100%" height="100%" fill={color} />
      <text
        x="50%"
        y="32px"
        textAnchor="middle"
        fontFamily="Verdana"
        fontSize="24"
        fill="#FFF"
      >
        {initials}
      </text>
    </svg>
  );
};

export default InitialsAvatar;
