// @flow
import invariant from "invariant";
import { evolve } from "ramda";
import type { Reducer } from "redux";
import { Record, Map, List, type RecordOf } from "immutable";
import {
  PLACE_CHANGE_DETAILS,
  PLACE_CHANGE_ADDRESS,
  PLACE_CHANGE_COORDINATES,
  PLACE_GEOCODE_RESULT,
  PLACE_ADDRESS_SUGGESTIONS_LOADED,
  PLACE_ADDRESS_SUGGESTION_SELECTED,
  PLACE_PHOTO_MOVE,
  PLACE_REMOVE_PHOTOS,
  PLACE_UPLOAD_PHOTOS,
  PLACE_UPLOAD_PROGRESS,
  PLACE_UPLOAD_COMPLETE,
  PLACE_CHANGE_SCHEDULE,
  PLACE_CREATE_RESULT,
  type Action,
} from "../actions";
import type {
  PlaceId,
  PlaceDetails,
  PlaceAddress,
  PlaceCoordinates,
  PlaceEditPhotoId,
  PlaceEditPhoto,
  PlaceSchedule,
  AddressSuggestion,
} from "../models";

type InnerState = RecordOf<{
  details: ?PlaceDetails,
  address: ?PlaceAddress,
  coordinates: ?PlaceCoordinates,
  addressSuggestions: List<AddressSuggestion>,
  addressSuggestionsLoading: boolean,
  geocodeLoading: boolean,
  photosChanged: boolean,
  photoIds: List<PlaceEditPhotoId>,
  photoById: Map<PlaceEditPhotoId, PlaceEditPhoto>,
  schedule: ?PlaceSchedule,
}>;

const initialInnerState: InnerState = Record({
  details: null,
  address: null,
  coordinates: null,
  addressSuggestions: List(),
  addressSuggestionsLoading: false,
  geocodeLoading: false,
  photosChanged: false,
  photoIds: List(),
  photoById: Map(),
  schedule: null,
})();

export type State = {
  create: InnerState,
  byId: Map<PlaceId, InnerState>,
};

const initialState = {
  create: initialInnerState,
  byId: Map(),
};

/**
 * Perform action on the state, as if the photos were initialized.
 *
 * The photos state is initialized lazily, which means photoIds,
 * photoById will be empty until they differ from the photos of the
 * place they correspond to.
 *
 * To make individual modifications possible, such as PHOTO_MOVE and
 * UPLOAD_PHOTOS each action specifies what it considers to be the
 * current photos. When the action is processed this information will
 * be merged into the state and the photos will be initialized.
 */
const withLazyPhotos = (
  state: InnerState,
  photos: List<PlaceEditPhoto>,
  callback: (state: InnerState) => InnerState
) => {
  if (state.photosChanged) {
    return callback(state);
  }

  return callback(
    state.merge({
      photosChanged: true,
      photoIds: photos.map(photo => photo.id),
      photoById: photos.reduce(
        (byId, photo) => byId.set(photo.id, photo),
        Map()
      ),
    })
  );
};

const moveInListByIndex = <T>(
  index: number,
  newIndex: number,
  list: List<T>
): List<T> => {
  const item = list.get(index);

  invariant(item, "Item referenced by index must exist");

  return list.remove(index).insert(newIndex, item);
};

const reduceForPlace: Reducer<InnerState, Action> = (
  state = initialInnerState,
  action
) => {
  switch (action.type) {
    case PLACE_CHANGE_DETAILS:
      return state.set("details", action.payload.value);

    case PLACE_CHANGE_ADDRESS:
      return state.merge({
        address: action.payload.value,
        addressSuggestionsLoading: true,
      });

    case PLACE_ADDRESS_SUGGESTIONS_LOADED:
      if (action.payload instanceof Error) {
        return state;
      }

      return state.merge({
        addressSuggestions: action.payload.suggestions,
        addressSuggestionsLoading: false,
      });

    case PLACE_ADDRESS_SUGGESTION_SELECTED:
      return state.merge({
        address: action.payload.address,
        geocodeLoading: true,
      });

    case PLACE_CHANGE_COORDINATES:
      return state.merge({
        coordinates: action.payload.value,
        geocodeLoading: true,
      });

    case PLACE_GEOCODE_RESULT: {
      if (action.payload instanceof Error) {
        return state.merge({
          geocodeLoading: false,
        });
      }

      const { request, address, coordinates } = action.payload;

      return state.merge({
        address: request.address ? state.address : address,
        coordinates: request.coordinates ? state.coordinates : coordinates,
        geocodeLoading: false,
      });
    }
    case PLACE_PHOTO_MOVE: {
      const { prevIndex, newIndex, photos } = action.payload;

      return withLazyPhotos(state, photos, state =>
        state.update("photoIds", photoIds =>
          moveInListByIndex(prevIndex, newIndex, photoIds)
        )
      );
    }

    case PLACE_REMOVE_PHOTOS: {
      const { selectedPhotos, photos } = action.payload;

      const selectedPhotoIds = selectedPhotos.map(photo => photo.id);

      return withLazyPhotos(state, photos, state =>
        state.update("photoIds", state =>
          state.filterNot(id => selectedPhotoIds.includes(id))
        )
      );
    }

    case PLACE_UPLOAD_PHOTOS: {
      const { newPhotos, photos } = action.payload;

      return withLazyPhotos(state, photos, state =>
        state
          .update("photoIds", state => state.concat(newPhotos.keys()))
          .update("photoById", state => state.merge(newPhotos))
      );
    }

    case PLACE_UPLOAD_PROGRESS: {
      const { photoId, progress } = action.payload;

      return state.setIn(["photoById", photoId, "progress"], progress);
    }

    case PLACE_UPLOAD_COMPLETE: {
      if (action.payload instanceof Error) {
        const { photoId } = action.payload;

        return state.update("photoIds", photos =>
          photos.filter(value => value !== photoId)
        );
      }

      const { photoId, result } = action.payload;

      return state.mergeIn(["photoById", photoId], {
        progress: null,
        result,
      });
    }

    case PLACE_CHANGE_SCHEDULE:
      return state.set("schedule", action.payload.value);

    default:
      return state;
  }
};

const reducer: Reducer<State, Action> = (state = initialState, action) => {
  switch (action.type) {
    case PLACE_CHANGE_DETAILS:
    case PLACE_CHANGE_ADDRESS:
    case PLACE_CHANGE_COORDINATES:
    case PLACE_GEOCODE_RESULT:
    case PLACE_ADDRESS_SUGGESTIONS_LOADED:
    case PLACE_ADDRESS_SUGGESTION_SELECTED:
    case PLACE_PHOTO_MOVE:
    case PLACE_REMOVE_PHOTOS:
    case PLACE_UPLOAD_PHOTOS:
    case PLACE_UPLOAD_PROGRESS:
    case PLACE_UPLOAD_COMPLETE:
    case PLACE_CHANGE_SCHEDULE: {
      const { placeId } = action.payload;

      if (placeId) {
        return evolve(
          {
            byId: state =>
              state.update(placeId, state => reduceForPlace(state, action)),
          },
          state
        );
      }

      return evolve(
        {
          create: state => reduceForPlace(state, action),
        },
        state
      );
    }

    // Reset edit state when the place is created
    case PLACE_CREATE_RESULT:
      return {
        ...state,
        create: initialInnerState,
      };

    default:
      return state;
  }
};

export default reducer;
