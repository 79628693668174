// @flow
import type { OrderedMap } from "immutable";
import nanoid from "nanoid";
import {
  makeMember,
  makePlace,
  type Member,
  type MemberId,
  type MemberFields,
  type InviteId,
  type Place,
  type PlaceId,
  type PlaceFields,
} from "../models";
import {
  withErrorVariant,
  normalizeEntities,
  type WithErrorVariant,
} from "./utils";

export const MEMBER_LIST_SHOWN: "MEMBER_LIST_SHOWN" = "MEMBER_LIST_SHOWN";
export const MEMBER_LIST_HIDDEN: "MEMBER_LIST_HIDDEN" = "MEMBER_LIST_HIDDEN";
export const MEMBER_LIST_LOADED: "MEMBER_LIST_LOADED" = "MEMBER_LIST_LOADED";
export const MEMBER_SHOWN: "MEMBER_SHOWN" = "MEMBER_SHOWN";
export const MEMBER_HIDDEN: "MEMBER_HIDDEN" = "MEMBER_HIDDEN";
export const MEMBER_LOADED: "MEMBER_LOADED" = "MEMBER_LOADED";
export const MEMBER_PLACES_LOADED: "MEMBER_PLACES_LOADED" =
  "MEMBER_PLACES_LOADED";
export const MEMBER_CREATE_BUTTON_PRESSED: "MEMBER_CREATE_BUTTON_PRESSED" =
  "MEMBER_CREATE_BUTTON_PRESSED";
export const MEMBER_UPDATE: "MEMBER_UPDATE" = "MEMBER_UPDATE";
export const MEMBER_UPDATE_RESULT: "MEMBER_UPDATE_RESULT" =
  "MEMBER_UPDATE_RESULT";
export const MEMBER_SUSPEND: "MEMBER_SUSPEND" = "MEMBER_SUSPEND";
export const MEMBER_SUSPEND_RESULT: "MEMBER_SUSPEND_RESULT" =
  "MEMBER_SUSPEND_RESULT";
export const MEMBER_RESTORE: "MEMBER_RESTORE" = "MEMBER_RESTORE";
export const MEMBER_RESTORE_RESULT: "MEMBER_RESTORE_RESULT" =
  "MEMBER_RESTORE_RESULT";

export type Action =
  | {|
      type: typeof MEMBER_LIST_SHOWN,
    |}
  | {|
      type: typeof MEMBER_LIST_HIDDEN,
    |}
  | {|
      type: typeof MEMBER_LIST_LOADED,
      payload: {|
        members: OrderedMap<MemberId, Member>,
      |},
    |}
  | {|
      type: typeof MEMBER_SHOWN,
      payload: {|
        id: MemberId,
      |},
    |}
  | {|
      type: typeof MEMBER_HIDDEN,
      payload: {|
        id: MemberId,
      |},
    |}
  | {|
      type: typeof MEMBER_LOADED,
      payload: {|
        member: Member,
      |},
    |}
  | {|
      type: typeof MEMBER_PLACES_LOADED,
      payload: {|
        memberId: MemberId,
        places: OrderedMap<PlaceId, Place>,
      |},
    |}
  | {|
      type: typeof MEMBER_CREATE_BUTTON_PRESSED,
      payload: {|
        id: InviteId,
      |},
    |}
  | {|
      type: typeof MEMBER_UPDATE,
      payload: {|
        member: Member,
        update: $Shape<MemberFields>,
      |},
    |}
  | WithErrorVariant<{|
      type: typeof MEMBER_UPDATE_RESULT,
      payload: {|
        member: Member,
      |},
    |}>
  | {|
      type: typeof MEMBER_SUSPEND,
      payload: {|
        member: Member,
      |},
    |}
  | WithErrorVariant<{|
      type: typeof MEMBER_SUSPEND_RESULT,
      payload: {|
        member: Member,
      |},
    |}>
  | {|
      type: typeof MEMBER_RESTORE,
      payload: {|
        member: Member,
      |},
    |}
  | WithErrorVariant<{|
      type: typeof MEMBER_RESTORE_RESULT,
      payload: {|
        member: Member,
      |},
    |}>;

export const memberListShown = (): Action => ({
  type: MEMBER_LIST_SHOWN,
});

export const memberListHidden = (): Action => ({
  type: MEMBER_LIST_HIDDEN,
});

export const memberListLoaded = withErrorVariant(
  MEMBER_LIST_LOADED,
  (members: Array<MemberFields>): Action => ({
    type: MEMBER_LIST_LOADED,
    payload: {
      members: normalizeEntities(members).map(makeMember),
    },
  })
);

export const memberShown = (id: MemberId): Action => ({
  type: MEMBER_SHOWN,
  payload: {
    id,
  },
});

export const memberHidden = (id: MemberId): Action => ({
  type: MEMBER_HIDDEN,
  payload: {
    id,
  },
});

export const memberLoaded = withErrorVariant(
  MEMBER_LOADED,
  (member: MemberFields): Action => ({
    type: MEMBER_LOADED,
    payload: {
      member: makeMember(member),
    },
  })
);

export const memberPlacesLoaded = withErrorVariant(
  MEMBER_PLACES_LOADED,
  (memberId: MemberId, places: Array<PlaceFields>): Action => ({
    type: MEMBER_PLACES_LOADED,
    payload: {
      memberId,
      places: normalizeEntities(places).map(makePlace),
    },
  })
);

export const memberCreateButtonPressed = (): Action => ({
  type: MEMBER_CREATE_BUTTON_PRESSED,
  payload: {
    id: nanoid(),
  },
});

export const memberUpdate = (
  member: Member,
  update: $Shape<MemberFields>
): Action => ({
  type: MEMBER_UPDATE,
  payload: {
    member,
    update,
  },
});

export const memberUpdateResult = withErrorVariant(
  MEMBER_UPDATE_RESULT,
  (member: MemberFields): Action => ({
    type: MEMBER_UPDATE_RESULT,
    payload: {
      member: makeMember(member),
    },
  })
);

export const memberSuspend = (member: Member): Action => ({
  type: MEMBER_SUSPEND,
  payload: {
    member,
  },
});

export const memberSuspendResult = withErrorVariant(
  MEMBER_SUSPEND_RESULT,
  (member: Member): Action => ({
    type: MEMBER_SUSPEND_RESULT,
    payload: {
      member,
    },
  })
);

export const memberRestore = (member: Member): Action => ({
  type: MEMBER_RESTORE,
  payload: {
    member,
  },
});

export const memberRestoreResult = withErrorVariant(
  MEMBER_RESTORE_RESULT,
  (member: Member): Action => ({
    type: MEMBER_RESTORE_RESULT,
    payload: {
      member,
    },
  })
);
