// @flow
import * as React from "react";
import styled from "styled-components";
import Circle from "./Circle";
import Text from "./Text";

type Props = {|
  avatar?: boolean,
  lines?: number,
|};

const defaultProps = {
  avatar: true,
  lines: 2,
};

const Container = styled.div`
  display: flex;
  padding: 16px 8px;
`;

const Avatar = styled.div`
  padding: 0 8px;
`;

const Content = styled.div`
  flex: 1;
  padding: 0 8px;
  align-self: center;
`;

const ListItem = ({ avatar, lines }: Props) => (
  <Container>
    {avatar && (
      <Avatar>
        <Circle size={40} />
      </Avatar>
    )}
    <Content>
      <Text lines={lines} />
    </Content>
  </Container>
);

ListItem.defaultProps = defaultProps;

export default ListItem;
