// @flow
import * as React from "react";
import type { List } from "immutable";
import {
  makePlaceAddress,
  makePlaceCoordinates,
  type PlaceAddress,
  type PlaceCoordinates,
  type AddressSuggestion,
} from "../models";
import { AddressField, Map } from "../components";
import MinHeightExpander from "./MinHeightExpander";

type Value = {
  address: $Shape<PlaceAddress>,
  coordinates: $Shape<PlaceCoordinates>,
};

type RenderProps = {
  address: ?PlaceAddress,
  coordinates: ?PlaceCoordinates,
  children: React.Node,
  onSubmit: () => void,
};

type Props = {
  autoFocus: boolean,
  loading: boolean,
  address: ?PlaceAddress,
  coordinates: ?PlaceCoordinates,
  suggestions: List<AddressSuggestion>,
  onAddressChange: (address: PlaceAddress) => void,
  onCoordinatesChange: (coordinates: PlaceCoordinates) => void,
  onSuggestionSelect: (suggestion: AddressSuggestion) => void,
  onSubmit: (value: Value) => void,
  render: (props: RenderProps) => React.Node,
};

const defaultProps = {
  autoFocus: false,
  loading: false,
  initialValue: {
    address: null,
    coordinates: null,
  },
};

class AddressForm extends React.Component<Props> {
  static defaultProps = defaultProps;

  handleAddressChange = (e: SyntheticEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    const address = makePlaceAddress({
      value,
      displayName: value,
    });

    this.props.onAddressChange(address);
  };

  handleCoordinatesChange = ([latitude, longitude]: [number, number]) => {
    const coordinates = makePlaceCoordinates({
      latitude,
      longitude,
    });

    this.props.onCoordinatesChange(coordinates);
  };

  handleSelectSuggestion = (suggestion: AddressSuggestion) => {
    this.props.onSuggestionSelect(suggestion);
  };

  handleSubmit = (e: ?Event) => {
    if (e) {
      e.preventDefault();
    }

    const { address, coordinates, onSubmit } = this.props;

    if (address && coordinates && onSubmit) {
      onSubmit({ address: address.toJS(), coordinates: coordinates.toJS() });
    }
  };

  render() {
    const {
      autoFocus,
      loading,
      address,
      coordinates,
      suggestions,
      render,
    } = this.props;

    const children = (
      <React.Fragment>
        <AddressField
          name="address"
          autoFocus={autoFocus}
          loading={loading}
          value={address ? address.displayName : ""}
          suggestions={suggestions}
          onChange={this.handleAddressChange}
          onComplete={this.handleSelectSuggestion}
        />
        <MinHeightExpander>
          <Map
            marker={
              coordinates ? [coordinates.latitude, coordinates.longitude] : null
            }
            onClick={this.handleCoordinatesChange}
          />
        </MinHeightExpander>
      </React.Fragment>
    );

    return render({
      address,
      coordinates,
      children,
      onSubmit: this.handleSubmit,
    });
  }
}

export default AddressForm;
