// @flow
import invariant from "invariant";
import * as React from "react";
import { keyframes } from "styled-components";

const ENTERING = "ENTERING";
const ENTERED = "ENTERED";
const EXITING = "EXITING";
const EXITED = "EXITED";

export const KIND_EXPAND = "expand";
export const KIND_SLIDE = "slide";

type Style = Object;

type TransitionKind = typeof KIND_EXPAND | typeof KIND_SLIDE;

type InjectedProps = {
  style: Style,
  ref: (node: HTMLElement | null) => void,
};

type Props = {|
  kind?: TransitionKind,
  appear?: boolean,
  in?: boolean,
  onExited?: () => void,
  children: (props: InjectedProps) => React.Node,
|};

const defaultProps = {
  kind: KIND_EXPAND,
  appear: false,
  in: false,
  onExited: () => {},
};

type State = {|
  status: typeof ENTERING | typeof ENTERED | typeof EXITING | typeof EXITED,
|};

const shrink = keyframes`
  from { transform: scale(1); }
  to { transform: scale(0); }
`;

const expand = keyframes`
  from { transform: scale(0); }
  to { transform: scale(1); }
`;

const slideUp = keyframes`
  from { 
    transform: translateY(24px);
    opacity: 0;
  }
  to { 
    transform: translateY(0); 
    opacity: 1;
  }
`;

const slideDown = keyframes`
  from { 
    transform: translateY(0);
    opacity: 1;
  }
  to { 
    transform: translateY(24px);
    opacity: 0;
  }
`;

const transitionStyles: {
  [key: TransitionKind]: {|
    ENTERING: Style,
    ENTERED: Style,
    EXITING: Style,
    EXITED: Style,
  |},
} = {
  [KIND_EXPAND]: {
    [ENTERING]: { animation: `${expand} .225s ease-out` },
    [ENTERED]: {},
    [EXITING]: { animation: `${shrink} .195s ease-in` },
    [EXITED]: {},
  },
  [KIND_SLIDE]: {
    [ENTERING]: { animation: `${slideUp} .250s ease-in-out` },
    [ENTERED]: {},
    [EXITING]: { animation: `${slideDown} .250s ease-in-out` },
    [EXITED]: {},
  },
};

const nextStatus = status => {
  switch (status) {
    case ENTERING:
      return ENTERED;
    case EXITING:
      return EXITED;
    default:
      throw new Error(`Invalid transition status: ${status}`);
  }
};

class Transition extends React.Component<Props, State> {
  static defaultProps = defaultProps;
  static KIND_EXPAND = KIND_EXPAND;
  static KIND_SLIDE = KIND_SLIDE;

  constructor(props: Props) {
    super(props);

    if (!props.in) {
      this.state = {
        status: EXITED,
      };

      return;
    }

    // This props allows us to avoid transition on initial mount, when Transition mounds simultaneously with TransitionGroup
    if (props.appear) {
      this.state = {
        status: ENTERED,
      };

      return;
    }

    this.state = {
      status: ENTERING,
    };
  }

  componentWillReceiveProps(nextProps: Props) {
    this.setState({ status: nextProps.in ? ENTERING : EXITING });
  }

  handleRef = (node: HTMLElement | null) => {
    if (node === null) {
      return;
    }

    node.addEventListener(
      "animationend",
      (e: AnimationEvent) => {
        if (node !== e.target) {
          return;
        }

        this.setState(
          state => ({
            status: nextStatus(state.status),
          }),
          () => {
            if (this.state.status === EXITED && this.props.onExited) {
              this.props.onExited();
            }
          }
        );
      },
      false
    );
  };

  render() {
    const { kind, children } = this.props;
    const { status } = this.state;

    invariant(kind, "Transition kind must be defined");

    const style = transitionStyles[kind][status];

    return children({
      style,
      ref: this.handleRef,
    });
  }
}

export default Transition;
