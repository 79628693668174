// @flow
import * as React from "react";
import styled from "styled-components";
import { Redirect } from "react-router";
import { I18n } from "react-i18next";
import { PublicLayout, Layout, Button, LoginForm } from "../components";
import { AuthContainer } from "../containers";
import { APPLICATION_CREATE } from "../routes";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const SignupButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const AuthScreen = () => (
  <PublicLayout>
    <Layout.Content>
      <Container>
        <AuthContainer logout>
          {({ user, login }) =>
            user ? (
              <Redirect push to={APPLICATION_CREATE} />
            ) : (
              <LoginForm onLogin={login} />
            )
          }
        </AuthContainer>
        {/* TODO: What is this container for? */}
        <SignupButtonContainer>
          <I18n>
            {t => (
              <Button link={APPLICATION_CREATE}>
                {t("auth.signup_action")}
              </Button>
            )}
          </I18n>
        </SignupButtonContainer>
      </Container>
    </Layout.Content>
  </PublicLayout>
);

export default AuthScreen;
