// @flow
import * as React from "react";
import SwipeableViews from "react-swipeable-views";
import WizardStepper from "./WizardStepper";

type RenderProps = {
  children: React.Node,
  extra: any,
};

type Props = {
  step: number,
  pages: Array<{
    isValid: boolean,
    children: React.Node,
    onNext: () => void,
    extra: any,
  }>,
  render: (props: RenderProps) => React.Node,
  onBack: () => void,
};

/**
 * This component is introduced for performance purposes.
 *
 * `pages` prop changes with every rerender of any page in the
 * wizard. The handler for next button must invoke the handler
 * provided by the page during render.
 *
 * This component helps us pass a static handler to the button.
 */
class WizardContent extends React.Component<Props> {
  handleNext = () => {
    const { pages, step } = this.props;

    pages[step].onNext();
  };

  render() {
    const { render, pages, step, onBack } = this.props;
    const { isValid } = pages[step];

    return render({
      extra: pages[step].extra,
      onSubmit: this.handleNext,
      children: (
        <React.Fragment>
          <SwipeableViews
            disabled
            index={step}
            style={{ display: "flex", flexDirection: "column", flex: 1 }}
            containerStyle={{ flex: 1 }}
          >
            {pages.map((props, i) => (
              <div
                key={
                  // eslint-disable-next-line react/no-array-index-key
                  i
                }
                style={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                }}
              >
                {props.children}
              </div>
            ))}
          </SwipeableViews>
          <WizardStepper
            steps={pages.length}
            activeStep={step}
            nextDisabled={!isValid}
            onBack={onBack}
            onNext={this.handleNext}
          />
        </React.Fragment>
      ),
    });
  }
}

export default WizardContent;
