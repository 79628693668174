// @flow
import { pipe, evolve, unless, isNil } from "ramda";
import { Record, type RecordOf, type RecordFactory } from "immutable";

export type GalleryId = string;
export type GalleryPhotoId = string;

export type GalleryUploadProgressFields = {|
  bytesTransferred: number,
  totalBytes: number,
|};

export type GalleryUploadProgress = RecordOf<GalleryUploadProgressFields>;

export const makeGalleryUploadProgress: RecordFactory<
  GalleryUploadProgressFields
> = Record({
  bytesTransferred: 0,
  totalBytes: 0,
});

export type GalleryPhotoFields<T> = {|
  id: GalleryPhotoId,
  original: ?T,
  progress: ?GalleryUploadProgress,
  file: ?File,
|};

export type GalleryPhoto<T> = RecordOf<GalleryPhotoFields<T>>;

export const makeGalleryPhoto: <T>(
  values: GalleryPhotoFields<T>
) => GalleryPhoto<T> = pipe(
  evolve({
    progress: unless(isNil, makeGalleryUploadProgress),
  }),
  Record(
    {
      id: "",
      original: null,
      progress: null,
      file: null,
    },
    "GalleryPhoto"
  )
);
