"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});


/**
 * Grant or revoke admin privileges for invite
 */
var setInviteAdmin = function setInviteAdmin(firebase, id, isAdmin) {
  return firebase.database().ref("/invites/" + id + "/admin").set(isAdmin);
};

exports.default = setInviteAdmin;