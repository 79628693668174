"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _firebase = require("firebase");

var _firebase2 = _interopRequireDefault(_firebase);

var _Observable = require("rxjs/Observable");

var _concat = require("rxjs/observable/concat");

var _from = require("rxjs/observable/from");

var _operators = require("rxjs/operators");

var _tasks = require("../tasks");

var _utils = require("../utils");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var uploadPlacePhoto = function uploadPlacePhoto(api, file) {
  var filename = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : file.name;
  var uid = api.auth().currentUser.uid;


  var source$ = (0, _from.from)(new Promise(function (resolve) {
    _utils.ImageTools.resize(file, {
      width: 1280,
      height: 768
    }, resolve);
  }));

  return source$.pipe((0, _operators.switchMap)(function (source) {
    var uploadTask = api.storage().ref("/userUploads/" + uid + "/" + filename).put(source);

    var progress$ = _Observable.Observable.create(function (observer) {
      return (
        // $FlowFixMe
        uploadTask.on(
        // $FlowFixMe
        _firebase2.default.storage.TaskEvent.STATE_CHANGED, observer.next.bind(observer), observer.error.bind(observer), observer.complete.bind(observer))
      );
    });

    var complete$ = (0, _from.from)(uploadTask);

    return (0, _concat.concat)(progress$.pipe((0, _operators.map)(function (_ref) {
      var bytesTransferred = _ref.bytesTransferred,
          totalBytes = _ref.totalBytes;
      return {
        state: "uploading",
        bytesTransferred: bytesTransferred,
        totalBytes: totalBytes
      };
    })), complete$.pipe((0, _operators.switchMap)(function (_ref2) {
      var metadata = _ref2.metadata;
      return (0, _tasks.runTask)(api, "placePhoto", {
        location: "gs://" + metadata.bucket + "/" + metadata.fullPath
      });
    }), (0, _operators.map)(function (result) {
      return {
        state: "complete",
        result: result
      };
    })));
  }));
};
exports.default = uploadPlacePhoto;