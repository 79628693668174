"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _operators = require("rxjs/operators");

var _listEvents = require("../listEvents");

var _members = require("../members");

var _utils = require("../utils");

var getPlaces = function getPlaces(firebase) {
  return (0, _members.getCurrentMember)(firebase).pipe((0, _operators.switchMap)(function (member) {
    return member.admin ? (0, _listEvents.getListEventsFromRef)(function (snap) {
      return snap.val();
    }, (0, _utils.adjustListRef)({
      sort: "createdAt"
    }, firebase.database().ref("/placesAll"))) : (0, _members.getMemberPlaces)(firebase, member.id);
  }));
};
exports.default = getPlaces;