// @flow
import * as React from "react";

type Props = {
  resetToken: mixed,
  predicate?: ?(error: Error) => boolean,
  render: React.Node | ((error: Error) => React.Node),
  children: React.Node,
};

const defaultProps = {
  resetToken: null,
  predicate: null,
  render: null,
};

type State = {
  resetToken: mixed,
  error: ?Error,
};

class ErrorBoundary extends React.Component<Props, State> {
  static defaultProps = defaultProps;

  state = { resetToken: this.props.resetToken, error: null };

  componentWillReceiveProps({ resetToken }: Props) {
    if (this.state.resetToken !== resetToken) {
      this.setState({
        resetToken,
        error: null,
      });
    }
  }

  componentDidCatch(error: Error) {
    const { predicate } = this.props;

    if (!predicate || predicate(error)) {
      this.setState({ error });
    } else {
      throw error;
    }
  }

  render() {
    if (!this.state.error) {
      return this.props.children;
    }

    if (typeof this.props.render === "function") {
      return this.props.render(this.state.error);
    }

    return this.props.render;
  }
}

export default ErrorBoundary;
