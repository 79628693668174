// @flow
import * as React from "react";
import styled from "styled-components";
import { compose } from "recompose";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { translate, type TranslatorProps } from "react-i18next";
import { ListItem, MemberAvatar } from "../components";
import { makeGetMember } from "../selectors";
import { formatRoute, MEMBER_DETAILS } from "../routes";
import type { Member, MemberId } from "../models";

type Props = {
  ...$Exact<TranslatorProps>,
  style: Object,
  id: MemberId,
  member: Member,
};

const StyledAvatar = styled(MemberAvatar)`
  margin-right: 16px;
`;

const makeMapStateToProps = () =>
  createStructuredSelector({
    member: makeGetMember(),
  });

const enhance = compose(
  translate(),
  connect(
    makeMapStateToProps,
    null
  )
);

const translateMemberType = (t, member) => {
  if (member.isAdmin()) {
    return t("members.type_admin");
  }
  if (member.canPublish()) {
    return t("members.type_publisher");
  }
  return t("members.type_collaborator");
};

const MemberListItemContainer = ({ t, member, style }: Props) => (
  <ListItem
    borderBottom
    style={style}
    link={formatRoute(MEMBER_DETAILS, { id: member.getId() })}
    avatar={<StyledAvatar alt={member.getDisplayName()} member={member} />}
    label={member.getDisplayName()}
    secondaryText={translateMemberType(t, member)}
  />
);

export default enhance(MemberListItemContainer);
