// @flow
import styled from "styled-components";

const List = styled.div`
  flex: 1;
  width: 100%;
  padding: 8px 0;
  box-sizing: border-box;
`;

export default List;
