// @flow
import invariant from "invariant";
import * as React from "react";

type Props = {
  file: File,
  children: (props: { dataUrl: string }) => React.Node,
};

class FileReaderDataUrl extends React.Component<Props, { dataUrl: ?string }> {
  state = {
    dataUrl: null,
  };

  reader: ?FileReader = null;

  constructor(props: Props) {
    super();

    this.readFile(props.file);
  }

  componentWillReceiveProps(nextProps: Props) {
    if (this.props.file !== nextProps.file) {
      this.readFile(nextProps.file);
    }
  }

  readFile(file: File) {
    if (this.reader) {
      this.cancelRead();
    }

    const reader = new FileReader();

    reader.readAsDataURL(file);

    reader.addEventListener("load", this.onReaderLoad);

    this.reader = reader;
  }

  cancelRead() {
    invariant(
      this.reader instanceof FileReader,
      "Reader must be an instance of FileReader"
    );

    this.reader.removeEventListener("load", this.onReaderLoad);
  }

  onReaderLoad = () => {
    invariant(
      this.reader instanceof FileReader,
      "Reader must be an instance of FileReader"
    );

    invariant(
      typeof this.reader.result === "string",
      "Reader result must be a string"
    );

    this.setState({ dataUrl: this.reader.result });
  };

  render() {
    const { dataUrl } = this.state;

    if (!dataUrl) {
      return null;
    }

    return this.props.children({ dataUrl });
  }
}

export default FileReaderDataUrl;
