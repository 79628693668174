// @flow
import type { List as ImmutableList } from "immutable";
import * as React from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { placeListShown, placeListHidden } from "../actions";
import { makeGetPlaceListIds, makeIsPlaceListLoaded } from "../selectors";
import { VirtualizedList } from "../components";
import { withSideEffects } from "../hocs";
import type { PlaceId } from "../models";
import PlaceListItemContainer from "./PlaceListItemContainer";

type Props = {
  ids: ImmutableList<PlaceId>,
  loaded: boolean,
  placeListShown: () => void,
  placeListHidden: () => void,
};

const makeMapStateToProps = () =>
  createStructuredSelector({
    ids: makeGetPlaceListIds(),
    loaded: makeIsPlaceListLoaded(),
  });

const enhance = compose(
  connect(
    makeMapStateToProps,
    { placeListShown, placeListHidden }
  ),
  withSideEffects({
    onMount: ({ placeListShown }) => placeListShown(),
    onUnmount: ({ placeListHidden }) => placeListHidden(),
  })
);

const PlaceListContainer = ({ ids, loaded }: Props) => (
  <VirtualizedList
    loading={!loaded}
    items={ids}
    rowRenderer={({ item }) => <PlaceListItemContainer id={item} />}
  />
);

export default enhance(PlaceListContainer);
