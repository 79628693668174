"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _Rx = require("rxjs/Rx");

var _fromEvent = require("./fromEvent");

var _fromEvent2 = _interopRequireDefault(_fromEvent);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var fromValue = function fromValue(ref) {
  return (0, _fromEvent2.default)(ref, "value", function (snap) {
    return snap;
  });
};

exports.default = fromValue;