// @flow
import { createSelector, type Selector } from "reselect";
import type { List } from "immutable";
import type { State } from "../reducers";
import type { Invite, InviteId } from "../models";

export const makeGetInvite = (): Selector<State, { id: InviteId }, ?Invite> =>
  createSelector(
    state => state.invite.byId,
    (state, ownProps) => ownProps.id,
    (byId, id) => byId.get(id)
  );

export const makeIsInviteLoaded = (): Selector<
  State,
  { id: InviteId },
  boolean
> =>
  createSelector(
    state => state.invite.loadedById,
    (state, ownProps) => ownProps.id,
    (loadedById, id) => loadedById.get(id, false)
  );

export const makeGetInviteListIds = (): Selector<
  State,
  any,
  ?List<InviteId>
> => state => state.invite.listIds;

export const makeIsInviteListLoaded = (): Selector<
  State,
  any,
  boolean
> => state => state.invite.listLoaded;

/**
 * User is currently accepting an invite
 */
export const makeIsInviteAccepting = (): Selector<
  State,
  any,
  boolean
> => state => state.invite.accepting;

/**
 * Return the number of pending invites
 */
export const makeGetPendingInviteCount = (): Selector<State, any, number> =>
  createSelector(state => state.invite.listIds, ids => ids.size);
