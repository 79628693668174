// @flow
import * as React from "react";
import styled from "styled-components";
import MediaQuery from "react-responsive";
import { Paper } from "material-ui";
import { grey } from "material-ui/colors";
import { BREAKPOINT_SMALL } from "../../constants";
import ScrollContainer from "../ScrollContainer";

type Props = {
  header?: React.Node,
  footer?: React.Node,
  children: React.Node,
};

const defaultProps = {
  header: null,
  footer: null,
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  background: ${grey[50]};
  align-items: center;
`;

const Header = styled.div`
  width: 100%;

  @media (min-width: ${BREAKPOINT_SMALL}px) {
    margin-bottom: 24px;
  }
`;

const Content = styled(Paper)`
  display: flex;
  flex-direction: column;

  width: 100%;
  max-width: ${BREAKPOINT_SMALL}px;

  /* Expand vertically on smal lscreen */
  @media (max-width: ${BREAKPOINT_SMALL}px) {
    flex-grow: 1;
  }
`;

const Layout = ({ header, footer, children }: Props) => (
  <MediaQuery maxDeviceWidth={BREAKPOINT_SMALL}>
    {smallScreen => (
      <ScrollContainer>
        <Container>
          {smallScreen ? null : <Header>{header}</Header>}

          <Content>
            {smallScreen ? header : null}
            {children}
            {footer}
          </Content>
        </Container>
      </ScrollContainer>
    )}
  </MediaQuery>
);

Layout.defaultProps = defaultProps;

export default Layout;
