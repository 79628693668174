// @flow
import * as React from "react";
import styled from "styled-components";
import { CircularProgress, Checkbox } from "material-ui";
import SortableHandle from "./SortableHandle";
import CursorMoveIcon from "./CursorMoveIcon";
import BaseFilePhoto from "./FilePhoto";
import BasePlacePhoto from "./PlacePhoto";
import type { PlaceEditPhoto } from "../models";

type Props = {
  photo: PlaceEditPhoto,
  isSelected: boolean,
  onChangeSelected: (photo: PlaceEditPhoto, isSelected: boolean) => void,
};

const defaultProps = {
  showCheckbox: false,
  progress: null,
};

const Square = styled.div`
  width: 140px;
  height: 140px;
  padding: 1px;
  display: flex;
  box-sizing: border-box;

  @media (max-width: 768px) {
    width: calc(100vw / 2 - 2px);
    height: calc(100vw / 2 - 2px);
  }
`;

const Content = styled.div`
  flex: 1;
  position: relative;
`;

const Photo = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const FilePhoto = Photo.withComponent(BaseFilePhoto);
const PlacePhoto = Photo.withComponent(BasePlacePhoto);

const ProgressContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.24);
`;

const DragHandleContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`;

const DragHandle = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 24px;
  padding: 8px;
  margin: 8px;
  color: rgba(255, 255, 255, 0.72);
  background: rgba(0, 0, 0, 0.12);
`;

const CheckboxContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.48);
`;

class PlaceGalleryPhoto extends React.Component<Props> {
  static defaultProps = defaultProps;

  handleToggle = () => {
    const { photo, isSelected, onChangeSelected } = this.props;

    onChangeSelected(photo, !isSelected);
  };

  render() {
    const { photo, isSelected } = this.props;
    const { file, result, progress } = photo;

    return (
      <Square>
        <Content data-cy="photo" onClick={this.handleToggle}>
          {file ? <FilePhoto file={file} /> : <PlacePhoto photo={result} />}
          {progress ? (
            <ProgressContainer>
              <CircularProgress
                variant={
                  progress.bytesTransferred === progress.totalBytes
                    ? "indeterminate"
                    : "determinate"
                }
                value={(progress.bytesTransferred / progress.totalBytes) * 100}
              />
            </ProgressContainer>
          ) : null}
          <DragHandleContainer>
            <SortableHandle>
              <DragHandle>
                <CursorMoveIcon data-cy="dnd-handle" color="inherit" />
              </DragHandle>
            </SortableHandle>
          </DragHandleContainer>
          {isSelected && (
            <CheckboxContainer>
              <Checkbox checked={isSelected} />
            </CheckboxContainer>
          )}
        </Content>
      </Square>
    );
  }
}

export default PlaceGalleryPhoto;
