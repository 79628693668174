// @flow
/* eslint-disable jsx-a11y/label-has-for */
import invariant from "invariant";
import * as React from "react";
import { compose, pure } from "recompose";
import {
  FormControl,
  Typography,
  Switch as BaseSwitch,
  withStyles,
} from "material-ui";
import classNames from "classnames";
import SideGutter from "./SideGutter";

type Props = {
  classes?: ?Object,
  id?: ?string,
  name?: ?string,
  value?: string,
  checked?: boolean,
  disabled?: boolean,
  label?: React.Node,
  required?: boolean,
  onChange?: ?(e: SyntheticEvent<HTMLInputElement>) => void,
};

const defaultProps = {
  classes: {},
  id: null,
  name: null,
  value: "1",
  checked: false,
  disabled: false,
  label: null,
  required: false,
  onChange: null,
};

export const styles = (theme: any) => ({
  root: {
    display: "inline-flex",
    alignItems: "center",
    cursor: "pointer",
    verticalAlign: "middle",
    WebkitTapHighlightColor: "transparent",
    marginRight: -14,
  },
  disabled: {
    color: theme.palette.text.disabled,
    cursor: "default",
  },
  text: {
    flex: 1,
  },
});

const enhance = compose(
  withStyles(styles),
  pure
);

const Switch = (props: Props) => {
  if (props.label) {
    const { classes, label, required, id, disabled, ...rest } = props;

    invariant(classes, "Classes must be injected");

    return (
      <SideGutter>
        <FormControl fullWidth>
          <label
            className={classNames(classes.root, {
              [classes.disabled]: disabled,
            })}
          >
            <Typography
              component="span"
              variant="subheading"
              className={classes.text}
            >
              {label}
              {required && <span>{"\u2009*"}</span>}
            </Typography>
            <Switch id={id} disabled={disabled} {...rest} />
          </label>
        </FormControl>
      </SideGutter>
    );
  }

  const {
    classes,
    id,
    name,
    value,
    checked,
    disabled,
    onChange,
    ...rest
  } = props;

  return (
    <BaseSwitch
      name={name}
      value={value}
      checked={checked}
      disabled={disabled}
      inputProps={{ id, ...rest }}
      onChange={onChange}
    />
  );
};

Switch.defaultProps = defaultProps;

export default enhance(Switch);
