// @flow
import * as React from "react";
import styled from "styled-components";
import FloatingActionButton from "./FloatingActionButton";

type Props = {
  // React.ElementProps<'input'>
  id: string,
  icon: React.Node,
  color?: "default" | "primary" | "secondary",
  size?: "small" | "medium" | "large",
};

const defaultProps = {
  color: "default",
  size: "medium",
};

const InvisibleInput = styled.input`
  position: fixed;
  top: 0;
  left: -1000px;
`;

const FileUploadFloatingActionButton = ({
  id,
  icon,
  color,
  size,
  ...rest
}: Props) => (
  <React.Fragment>
    <FloatingActionButton
      component="span"
      containerComponent="label"
      containerProps={{
        htmlFor: id,
      }}
      icon={icon}
      color={color}
      size={size}
    />
    <InvisibleInput id={id} {...rest} />
  </React.Fragment>
);

FileUploadFloatingActionButton.defaultProps = defaultProps;

export default FileUploadFloatingActionButton;
