import { combineEpics } from "redux-observable";
import { of, from } from "rxjs";
import {
  filter,
  first,
  switchMap,
  switchMapTo,
  map,
  mapTo,
  takeUntil,
  catchError,
} from "rxjs/operators";
import {
  getApplication,
  getApplications,
  createApplication,
  updateApplication,
  approveApplication,
  rejectApplication,
} from "outnabout-api";
import { filterListEvents, VALUE_EVENT } from "outnabout-api/lib/listEvents";
import {
  USER_APPLICATION_SHOWN,
  USER_APPLICATION_HIDDEN,
  USER_APPLICATION_SUBMITTED,
  APPLICATION_LIST_SHOWN,
  APPLICATION_LIST_HIDDEN,
  APPLICATION_RESOLVE,
  applicationLoaded,
  userApplicationSubmitResult,
  applicationListLoaded,
  applicationResolveResult,
} from "../actions";
import { ofType, logError } from "./utils";

const retrieveUserApplicationEpic = (action$, store, { api }) =>
  action$.pipe(
    filter(ofType(USER_APPLICATION_SHOWN)),
    filter(action => !!action.payload.id),
    switchMap(action => {
      const { id } = action.payload;

      return getApplication(api, action.payload.id).pipe(
        takeUntil(
          action$.pipe(
            filter(ofType(USER_APPLICATION_HIDDEN)),
            filter(action => action.payload.id === id)
          )
        )
      );
    }),
    map(applicationLoaded)
  );

const submitUserApplicationEpic = (action$, store, { api }) =>
  action$.pipe(
    filter(ofType(USER_APPLICATION_SUBMITTED)),
    switchMap(action => {
      const { id, values, language, application } = action.payload;
      const update = !!application;
      const result = update
        ? updateApplication(api, id, values)
        : createApplication(api, values, language, id);

      return from(result).pipe(
        switchMapTo(getApplication(api, id)),
        first(),
        map(application => userApplicationSubmitResult(application, update)),
        catchError(logError(error => of(userApplicationSubmitResult(error))))
      );
    })
  );

const retrieveApplicationsEpic = (action$, store, { api }) =>
  action$.pipe(
    filter(ofType(APPLICATION_LIST_SHOWN)),
    switchMap(() =>
      getApplications(api).pipe(
        filterListEvents(VALUE_EVENT),
        first(),
        map(event => applicationListLoaded(event.entities)),
        takeUntil(action$.ofType(APPLICATION_LIST_HIDDEN))
      )
    )
  );

const resolveApplicationEpic = (action$, store, { api }) =>
  action$.pipe(
    filter(ofType(APPLICATION_RESOLVE)),
    switchMap(action => {
      const { application, approved, comment } = action.payload;

      return from(
        approved
          ? approveApplication(api, application.id)
          : rejectApplication(api, application.id, comment)
      ).pipe(
        mapTo(applicationResolveResult(application, approved)),
        catchError(
          logError(error => {
            error.application = application; // eslint-disable-line no-param-reassign

            return of(applicationResolveResult(error));
          })
        )
      );
    })
  );

export default combineEpics(
  retrieveUserApplicationEpic,
  submitUserApplicationEpic,
  retrieveApplicationsEpic,
  resolveApplicationEpic
);
