// @flow
import type { OrderedMap } from "immutable";
import nanoid from "nanoid";
import {
  makeApplication,
  type Application,
  type ApplicationId,
  type ApplicationFields,
} from "../models";
import { normalizeEntities, withErrorVariant } from "./utils";

export const USER_APPLICATION_SHOWN = "USER_APPLICATION_SHOWN";
export const USER_APPLICATION_HIDDEN = "USER_APPLICATION_HIDDEN";
export const USER_APPLICATION_SUBMITTED = "USER_APPLICATION_SUBMITTED";
export const USER_APPLICATION_SUBMIT_RESULT = "USER_APPLICATION_SUBMIT_RESULT";
export const APPLICATION_LIST_SHOWN = "APPLICATION_LIST_SHOWN";
export const APPLICATION_LIST_HIDDEN = "APPLICATION_LIST_HIDDEN";
export const APPLICATION_LIST_LOADED = "APPLICATION_LIST_LOADED";
export const APPLICATION_RESOLVE = "APPLICATION_RESOLVE";
export const APPLICATION_RESOLVE_RESULT = "APPLICATION_RESOLVE_RESULT";
export const APPLICATION_LOADED = "APPLICATION_LOADED";

export type Action =
  | {|
      type: "USER_APPLICATION_SHOWN",
      payload: {|
        id: ApplicationId,
      |},
    |}
  | {|
      type: "USER_APPLICATION_HIDDEN",
      payload: {|
        id: ApplicationId,
      |},
    |}
  | {|
      type: "USER_APPLICATION_SUBMITTED",
      payload: {|
        id: ApplicationId,
        values: ApplicationFields,
        language: string,
        application: ?Application,
      |},
    |}
  | {|
      type: "USER_APPLICATION_SUBMIT_RESULT",
      payload: {|
        application: Application,
        update: boolean,
      |},
    |}
  | {|
      type: "APPLICATION_RESOLVE",
      payload: {|
        application: Application,
        approved: boolean,
        comment: ?string,
      |},
    |}
  | {|
      type: "APPLICATION_RESOLVE_RESULT",
      payload: {|
        application: Application,
        approved: boolean,
      |},
    |}
  | {|
      type: "APPLICATION_LIST_SHOWN",
    |}
  | {|
      type: "APPLICATION_LIST_HIDDEN",
    |}
  | {|
      type: "APPLICATION_LIST_LOADED",
      payload: {|
        applications: OrderedMap<ApplicationId, Application>,
      |},
    |}
  | {|
      type: "APPLICATION_LOADED",
      payload: {|
        application: Application,
      |},
    |};

export const userApplicationShown = (id: ApplicationId): Action => ({
  type: USER_APPLICATION_SHOWN,
  payload: { id },
});

export const userApplicationHidden = (id: ApplicationId): Action => ({
  type: USER_APPLICATION_HIDDEN,
  payload: { id },
});

export const userApplicationSubmitted = (
  values: ApplicationFields,
  language: string,
  application?: ?Application
): Action => ({
  type: USER_APPLICATION_SUBMITTED,
  payload: {
    id: application ? application.id : nanoid(),
    values,
    language,
    application: application ? makeApplication(application) : null,
  },
});

export const userApplicationSubmitResult = withErrorVariant(
  USER_APPLICATION_SUBMIT_RESULT,
  (application: ApplicationFields, update: boolean): Action => ({
    type: USER_APPLICATION_SUBMIT_RESULT,
    payload: {
      application: makeApplication(application),
      update,
    },
  })
);

export const applicationListShown = (): Action => ({
  type: APPLICATION_LIST_SHOWN,
});

export const applicationListHidden = (): Action => ({
  type: APPLICATION_LIST_HIDDEN,
});

export const applicationListLoaded = (
  applications: Array<ApplicationFields>
): Action => ({
  type: APPLICATION_LIST_LOADED,
  payload: {
    applications: normalizeEntities(applications).map(makeApplication),
  },
});

export const applicationResolve = (
  application: Application,
  approved: boolean,
  comment?: ?string
): Action => ({
  type: APPLICATION_RESOLVE,
  payload: {
    application,
    approved,
    comment,
  },
});

export const applicationResolveResult = withErrorVariant(
  APPLICATION_RESOLVE_RESULT,
  (application: Application, approved: boolean): Action => ({
    type: APPLICATION_RESOLVE_RESULT,
    payload: {
      application,
      approved,
    },
  })
);

export const applicationLoaded = withErrorVariant(
  APPLICATION_LOADED,
  (application: ApplicationFields): Action => ({
    type: APPLICATION_LOADED,
    payload: {
      application: makeApplication(application),
    },
  })
);
