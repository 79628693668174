// @flow
import type { Selector } from "reselect";
import type { State } from "../reducers";
import type { User, Member } from "../models";

/**
 * Returns whether authentication status is known
 */
export const makeIsAuthenticationStatusReady = (): Selector<
  State,
  any,
  boolean
> => state => state.auth.ready;

/**
 * Returns currently authenticated user
 */
export const makeGetAuthenticatedUser = (): Selector<
  State,
  any,
  ?User
> => state => state.auth.user;

/**
 * Returns member record for current user (if exists)
 */
export const makeGetAuthenticatedMember = (): Selector<
  State,
  any,
  ?Member
> => state => state.auth.member;
