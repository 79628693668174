// @flow
import type { List } from "immutable";

/**
 * Add an item to immutable list after another item
 */
const insertAfterInList = <T>(list: List<T>, item: T, after: ?T): List<T> => {
  const afterIndex = after ? list.indexOf(after) : null;

  if (afterIndex === -1) {
    return list;
  }

  return list.insert(after ? afterIndex + 1 : 0, item);
};

export default insertAfterInList;
