import i18n from "i18next";
import LngDetector from "i18next-browser-languagedetector";
import translations from "./translations";

i18n.use(LngDetector).init({
  debug: process.env.NODE_ENV !== "production",
  fallbackLng: "ru",
  defaultNS: "translations",
  interpolation: {
    escapeValue: false,
  },
  resources: translations,
});

export default i18n;
