"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require("react");

var React = _interopRequireWildcard(_react);

var _YandexStaticMap = require("./YandexStaticMap");

var _YandexStaticMap2 = _interopRequireDefault(_YandexStaticMap);

var _YandexMap = require("./YandexMap");

var _YandexMap2 = _interopRequireDefault(_YandexMap);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var YandexAdapter = function YandexAdapter(_ref) {
  var _this = this;

  var language = _ref.language;

  _classCallCheck(this, YandexAdapter);

  this.MapComponent = function (props) {
    return props.static ? React.createElement(_YandexStaticMap2.default, _extends({ language: _this.language }, props)) : React.createElement(_YandexMap2.default, _extends({ language: _this.language }, props));
  };

  this.language = language;
};

exports.default = YandexAdapter;