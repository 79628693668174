// @flow
import * as React from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { type ContextRouter, withRouter } from "react-router";
import { I18n } from "react-i18next";
import { createStructuredSelector } from "reselect";
import { Typography } from "material-ui";
import { DateFns, ListItem } from "../components";
import { makeGetInvite } from "../selectors";
import {
  getInviteExpiresAt,
  isInviteAdmin,
  type Invite,
  type InviteId,
} from "../models";
import { formatRoute, INVITE_DETAILS } from "../routes";

type Props = {
  ...$Exact<ContextRouter>,
  style: Object,
  id: InviteId,
  invite: Invite,
};

const makeMapStateToProps = () =>
  createStructuredSelector({
    invite: makeGetInvite(),
  });

const enhance = compose(
  withRouter,
  connect(
    makeMapStateToProps,
    null
  )
);

const InviteListItemContainer = ({ history, invite, style }: Props) => {
  const expiresAt = getInviteExpiresAt(invite);
  const now = Date.now();

  return (
    <DateFns>
      {d => (
        <I18n>
          {t => (
            <ListItem
              style={style}
              button
              onClick={() =>
                history.push(formatRoute(INVITE_DETAILS, { id: invite.id }))
              }
            >
              <Typography variant="body1">
                {isInviteAdmin(invite)
                  ? t("invites.list.admin_invite")
                  : t("invites.list.publisher_invite")}{" "}
                {t("invites.list.created_at", {
                  time: d.formatRelative(invite.createdAt, Date.now()),
                })}
              </Typography>
              <Typography variant="caption">
                {expiresAt > now
                  ? t("invites.list.expires_in", {
                      time: d.formatDistance(expiresAt, Date.now()),
                    })
                  : t("invites.list.expired_at", {
                      time: d.formatDistance(expiresAt, Date.now()),
                    })}
                {}
              </Typography>
            </ListItem>
          )}
        </I18n>
      )}
    </DateFns>
  );
};

export default enhance(InviteListItemContainer);
