// @flow
import * as React from "react";
import { PublicLayout } from "../components";
import { InviteAcceptContainer } from "../containers";
import type { InviteId } from "../models";

type Props = {
  id: InviteId,
};

const InviteScreen = ({ id }: Props) => (
  <PublicLayout>
    <InviteAcceptContainer id={id} />
  </PublicLayout>
);

export default InviteScreen;
