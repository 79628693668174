// @flow
import { compose, evolve, unless, isNil } from "ramda";
import type {
  ApplicationId as ApiApplicationId,
  Application as ApiApplication,
} from "outnabout-api";
import { Record, type RecordOf, type RecordFactory } from "immutable";

export type ApplicationId = ApiApplicationId;

export type ApplicationFields = ApiApplication;

export type Application = RecordOf<ApplicationFields>;

// Very awakward way to make nested records
const makeApplication: RecordFactory<ApplicationFields> = compose(
  Record(
    {
      id: "",
      fullName: "",
      organizationName: "",
      email: "",
      language: "",
      resolution: null,
      createdAt: 0,
      updatedAt: 0,
    },
    "Application"
  ),
  evolve({
    resolution: unless(
      isNil,
      Record({
        approved: false,
        comment: null,
        inviteId: null,
      })
    ),
  })
);

export const isApplicationResolved = (application: Application): boolean =>
  !!application.resolution;

export const isApplicationApproved = (application: Application): boolean =>
  application.getIn(["resolution", "approved"], false);

export default makeApplication;
