// @flow
import {
  makeUser,
  makeMember,
  type User,
  type Member,
  type MemberFields,
} from "../models";

export const AUTHENTICATION_STATUS_CHANGE: "AUTHENTICATION_STATUS_CHANGE" =
  "AUTHENTICATION_STATUS_CHANGE";
export const LOGIN_SUCCESS: "LOGIN_SUCCESS" = "LOGIN_SUCCESS";
export const LOGOUT: "LOGOUT" = "LOGOUT";

export type Action =
  | {|
      type: typeof AUTHENTICATION_STATUS_CHANGE,
      payload: {|
        user: ?User,
        member: ?Member,
      |},
    |}
  | {|
      type: typeof LOGIN_SUCCESS,
      payload: {|
        user: User,
      |},
    |}
  | {|
      type: typeof LOGOUT,
    |};

export const authenticationStatusChange = (
  user: ?User,
  member: ?MemberFields
): Action => ({
  type: AUTHENTICATION_STATUS_CHANGE,
  payload: {
    user: user && makeUser(user),
    member: member && makeMember(member),
  },
});

export const loginSuccess = (user: User): Action => ({
  type: LOGIN_SUCCESS,
  payload: {
    user: makeUser(user),
  },
});

export const logout = (): Action => ({
  type: LOGOUT,
});
