// @flow
import * as React from "react";
import { compose, branch, renderNothing } from "recompose";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { Drawer, DrawerHeader } from "../components";
import MenuContainer from "./MenuContainer";
import { drawerClose, logout } from "../actions";
import { makeIsDrawerOpen, makeGetAuthenticatedMember } from "../selectors";
import type { Member } from "../models";

type Props = {
  drawerOpen: boolean,
  member: Member,
  onDrawerClose: () => void,
  onLogout: () => void,
};

const makeMapStateToProps = () =>
  createStructuredSelector({
    drawerOpen: makeIsDrawerOpen(),
    member: makeGetAuthenticatedMember(),
  });

const enhance = compose(
  connect(
    makeMapStateToProps,
    {
      onDrawerClose: drawerClose,
      onLogout: logout,
    }
  ),
  branch(({ member }) => !member, renderNothing)
);

const DrawerContainer = ({
  drawerOpen,
  member,
  onDrawerClose,
  onLogout,
}: Props) => (
  <Drawer member={member} open={drawerOpen} onClose={onDrawerClose}>
    <DrawerHeader
      showCloseButton
      member={member}
      onClose={onDrawerClose}
      onLogout={onLogout}
    />
    <MenuContainer />
  </Drawer>
);

export default enhance(DrawerContainer);
