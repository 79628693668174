// @flow
import { type OrderedMap } from "immutable";
import {
  makePlace,
  type Place,
  type PlaceId,
  type PlaceFields,
  type PlacePhotoFields,
  type PlaceDetailsFields,
  type PlaceLocationFields,
  type PlaceScheduleFields,
} from "../models";
import {
  withErrorVariant,
  normalizeEntities,
  type WithErrorVariant,
} from "./utils";

export const PLACE_LIST_SHOWN: "PLACE_LIST_SHOWN" = "PLACE_LIST_SHOWN";
export const PLACE_LIST_HIDDEN: "PLACE_LIST_HIDDEN" = "PLACE_LIST_HIDDEN";
export const PLACE_LIST_LOADED: "PLACE_LIST_LOADED" = "PLACE_LIST_LOADED";
export const PLACE_SHOWN: "PLACE_SHOWN" = "PLACE_SHOWN";
export const PLACE_HIDDEN: "PLACE_HIDDEN" = "PLACE_HIDDEN";
export const PLACE_LOADED: "PLACE_LOADED" = "PLACE_LOADED";
export const PLACE_CREATE: "PLACE_CREATE" = "PLACE_CREATE";
export const PLACE_CREATE_RESULT: "PLACE_CREATE_RESULT" = "PLACE_CREATE_RESULT";
export const PLACE_UPDATE: "PLACE_UPDATE" = "PLACE_UPDATE";
export const PLACE_UPDATE_RESULT: "PLACE_UPDATE_RESULT" = "PLACE_UPDATE_RESULT";
export const PLACE_PUBLISH: "PLACE_PUBLISH" = "PLACE_PUBLISH";
export const PLACE_PUBLISH_RESULT: "PLACE_PUBLISH_RESULT" =
  "PLACE_PUBLISH_RESULT";
export const PLACE_UNPUBLISH: "PLACE_UNPUBLISH" = "PLACE_UNPUBLISH";
export const PLACE_UNPUBLISH_RESULT: "PLACE_UNPUBLISH_RESULT" =
  "PLACE_UNPUBLISH_RESULT";

export type Action =
  | {|
      type: typeof PLACE_LIST_SHOWN,
    |}
  | {|
      type: typeof PLACE_LIST_HIDDEN,
    |}
  | {|
      type: typeof PLACE_LIST_LOADED,
      payload: {|
        places: OrderedMap<PlaceId, Place>,
      |},
    |}
  | {|
      type: typeof PLACE_SHOWN,
      payload: {
        id: PlaceId,
      },
    |}
  | {|
      type: typeof PLACE_HIDDEN,
      payload: {
        id: PlaceId,
      },
    |}
  | {|
      type: typeof PLACE_LOADED,
      payload: {|
        place: Place,
      |},
    |}
  | {|
      type: typeof PLACE_UPDATE,
      payload: {|
        placeId: PlaceId,
        updates: $Shape<PlaceFields>,
      |},
    |}
  | WithErrorVariant<{|
      type: typeof PLACE_UPDATE_RESULT,
      payload: {|
        place: Place,
      |},
    |}>
  | {|
      type: typeof PLACE_CREATE,
      payload: $Shape<PlaceFields>,
    |}
  | WithErrorVariant<{|
      type: typeof PLACE_CREATE_RESULT,
      payload: {|
        place: Place,
      |},
    |}>
  | {|
      type: typeof PLACE_PUBLISH,
      payload: {|
        place: Place,
      |},
    |}
  | WithErrorVariant<{|
      type: typeof PLACE_PUBLISH_RESULT,
      payload: {|
        place: Place,
      |},
    |}>
  | {|
      type: typeof PLACE_UNPUBLISH,
      payload: {|
        place: Place,
      |},
    |}
  | WithErrorVariant<{|
      type: typeof PLACE_UNPUBLISH_RESULT,
      payload: {|
        place: Place,
      |},
    |}>;

export const placeListShown = (): Action => ({
  type: PLACE_LIST_SHOWN,
});

export const placeListHidden = (): Action => ({
  type: PLACE_LIST_HIDDEN,
});

export const placeListLoaded = withErrorVariant(
  PLACE_LIST_LOADED,
  (places: Array<PlaceFields>): Action => ({
    type: PLACE_LIST_LOADED,
    payload: {
      places: normalizeEntities(places).map(makePlace),
    },
  })
);

export const placeShown = (id: PlaceId): Action => ({
  type: PLACE_SHOWN,
  payload: {
    id,
  },
});

export const placeHidden = (id: PlaceId): Action => ({
  type: PLACE_HIDDEN,
  payload: {
    id,
  },
});

export const placeLoaded = withErrorVariant(
  PLACE_LOADED,
  (place: PlaceFields): Action => ({
    type: PLACE_LOADED,
    payload: {
      place: makePlace(place),
    },
  })
);

export const placeUpdate = (
  placeId: PlaceId,
  updates: $Shape<PlaceFields>
): Action => ({
  type: PLACE_UPDATE,
  payload: {
    placeId,
    updates,
  },
});

export const placeUpdateResult = withErrorVariant(
  PLACE_UPDATE_RESULT,
  (place: PlaceFields): Action => ({
    type: PLACE_UPDATE_RESULT,
    payload: {
      place: makePlace(place),
    },
  })
);

export const placeCreate = (
  details: PlaceDetailsFields,
  location: PlaceLocationFields,
  photos: PlacePhotoFields[],
  schedule: PlaceScheduleFields
): Action => ({
  type: PLACE_CREATE,
  payload: {
    details,
    location,
    photos,
    schedule,
  },
});

export const placeCreateResult = withErrorVariant(
  PLACE_CREATE_RESULT,
  (place: PlaceFields): Action => ({
    type: PLACE_CREATE_RESULT,
    payload: {
      place: makePlace(place),
    },
  })
);

export const placePublish = (place: Place): Action => ({
  type: PLACE_PUBLISH,
  payload: {
    place,
  },
});

export const placePublishResult = withErrorVariant(
  PLACE_PUBLISH_RESULT,
  (place: Place): Action => ({
    type: PLACE_PUBLISH_RESULT,
    payload: {
      place,
    },
  })
);

export const placeUnpublish = (place: Place): Action => ({
  type: PLACE_UNPUBLISH,
  payload: {
    place,
  },
});

export const placeUnpublishResult = withErrorVariant(
  PLACE_UNPUBLISH_RESULT,
  (place: Place): Action => ({
    type: PLACE_UNPUBLISH_RESULT,
    payload: {
      place,
    },
  })
);
