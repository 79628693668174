// @flow
import { combineEpics } from "redux-observable";
import authEpic from "./authEpic";
import applicationEpic from "./applicationEpic";
import memberEpic from "./memberEpic";
import inviteEpic from "./inviteEpic";
import placeEpic from "./placeEpic";
import placeEditEpic from "./placeEditEpic";

export default combineEpics(
  authEpic,
  applicationEpic,
  memberEpic,
  inviteEpic,
  placeEpic,
  placeEditEpic
);
