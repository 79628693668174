// @flow
import type { List as ImmutableList } from "immutable";
import * as React from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { inviteListShown, inviteListHidden } from "../actions";
import { makeGetInviteListIds, makeIsInviteListLoaded } from "../selectors";
import { VirtualizedList } from "../components";
import { withSideEffects } from "../hocs";
import type { InviteId } from "../models";
import InviteListItemContainer from "./InviteListItemContainer";

type Props = {
  ids: ImmutableList<InviteId>,
  loaded: boolean,
  inviteListShown: () => void,
  inviteListHidden: () => void,
};

const makeMapStateToProps = () =>
  createStructuredSelector({
    ids: makeGetInviteListIds(),
    loaded: makeIsInviteListLoaded(),
  });

const enhance = compose(
  connect(
    makeMapStateToProps,
    { inviteListShown, inviteListHidden }
  ),
  withSideEffects({
    onMount: ({ inviteListShown }) => inviteListShown(),
    onUnmount: ({ inviteListHidden }) => inviteListHidden(),
  })
);

const InviteListContainer = ({ ids, loaded }: Props) => (
  <VirtualizedList
    loading={!loaded}
    items={ids}
    rowRenderer={({ item }) => <InviteListItemContainer id={item} />}
  />
);

export default enhance(InviteListContainer);
