// @flow
import { persistReducer as originalPersistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import createImmutableTransform from "redux-persist-transform-immutable";
import {
  makeApplication,
  makeInvite,
  Member,
  makeNotification,
  Place,
  makeUser,
} from "./models";

export const immutableTransform = createImmutableTransform({
  records: [
    makeApplication,
    makeInvite,
    Member,
    makeNotification,
    Place,
    makeUser,
  ],
});

export const persistReducer = (config: Object = {}) => (reducer: Function) =>
  originalPersistReducer(
    {
      storage,
      transforms: [immutableTransform],
      ...config,
    },
    reducer
  );

export { persistStore } from "redux-persist";
