"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _tasks = require("../tasks");

var suspendMember = function suspendMember(firebase, memberId, isSuspended) {
  return (0, _tasks.runTask)(firebase, "memberSuspend", { memberId: memberId, isSuspended: isSuspended });
};
exports.default = suspendMember;