// @flow
import * as React from "react";
import { compose, branch, renderNothing } from "recompose";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { makeGetPlace } from "../selectors";
import {
  placeShown,
  placeHidden,
  placeUpdate,
  placePublish,
  placeUnpublish,
} from "../actions";
import { withSideEffects } from "../hocs";
import type { Place, PlaceId } from "../models";

type RenderProps = {
  place: Place,
  updatePlace: (updates: Object) => void,
  publishPlace: () => void,
  unpublishPlace: () => void,
};

type Props = {
  id: PlaceId,
  place: Place,
  placeUpdate: typeof placeUpdate,
  placePublish: typeof placePublish,
  placeUnpublish: typeof placeUnpublish,
  render: (props: RenderProps) => React.Node,
};

const makeMapStateToProps = () =>
  createStructuredSelector({
    place: makeGetPlace(),
  });

const enhance = compose(
  connect(
    makeMapStateToProps,
    {
      placeShown,
      placeHidden,
      placeUpdate,
      placePublish,
      placeUnpublish,
    }
  ),
  withSideEffects({
    onMount: ({ id, placeShown }) => placeShown(id),
    onUnmount: ({ id, placeHidden }) => placeHidden(id),
  }),
  branch(({ place }) => !place, renderNothing)
);

class PlaceContainer extends React.Component<Props> {
  handleUpdate = (updates: Object) => {
    this.props.placeUpdate(this.props.id, updates);
  };

  handlePublishPlace = () => {
    this.props.placePublish(this.props.place);
  };

  handleUnpublishPlace = () => {
    this.props.placeUnpublish(this.props.place);
  };

  render() {
    const { render, place } = this.props;

    return render({
      place,
      updatePlace: this.handleUpdate,
      publishPlace: this.handlePublishPlace,
      unpublishPlace: this.handleUnpublishPlace,
    });
  }
}

export default enhance(PlaceContainer);
