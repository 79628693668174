"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _operators = require("rxjs/operators");

var _utils = require("../utils");

var runTask = function runTask(api, taskType, params) {
  var ref = api.database().ref("tasks/" + taskType).push();

  return ref.set({
    params: params
  }).then(function () {
    return (0, _utils.fromValue)(ref).pipe((0, _operators.map)(function (snap) {
      return snap.val();
    }), (0, _operators.filter)(function (task) {
      return task.done;
    }), (0, _operators.first)()).toPromise();
  }).then(function (val) {
    return val.error ? Promise.reject(new Error(val.error.message)) : val.result;
  });
};

exports.default = runTask;