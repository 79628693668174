"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _invariant = require("invariant");

var _invariant2 = _interopRequireDefault(_invariant);

var _ramda = require("ramda");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var applyBoundary = function applyBoundary(criteria) {
  return function (ref) {
    var after = criteria.after,
        sort = criteria.sort,
        reverse = criteria.reverse;


    if (!after) {
      return ref;
    }

    (0, _invariant2.default)(typeof after.id === "string", "Entity ID must be a string");

    if (!sort) {
      return criteria.reverse ? ref.endAt(after.id) : ref.startAt(after.id);
    }

    var boundary = after[sort];

    return reverse ? ref.endAt(boundary, after.id) : ref.startAt(boundary, after.id);
  };
};


var applySort = function applySort(criteria) {
  return function (ref) {
    var sort = criteria.sort;


    if (!sort) {
      return ref;
    }

    return ref.orderByChild(sort);
  };
};

var applyLimit = function applyLimit(criteria) {
  return function (ref) {
    var limit = criteria.limit,
        reverse = criteria.reverse;


    if (!limit) {
      return ref;
    }

    return reverse ? ref.limitToLast(limit) : ref.limitToFirst(limit);
  };
};

var adjustListRef = function adjustListRef(criteria, ref) {
  return (0, _ramda.compose)(applySort(criteria), applyBoundary(criteria), applyLimit(criteria))(ref);
};

exports.default = adjustListRef;