// @flow
import * as React from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { translate, type TranslatorProps } from "react-i18next";
import { createStructuredSelector } from "reselect";
import { Snackbar } from "material-ui";
import { makeGetNotification } from "../selectors";
import { dismissNotification } from "../actions";
import type { Notification } from "../models";

type Props = {
  ...$Exact<TranslatorProps>,
  notification: Notification,
  dismissNotification: () => void,
};

type State = {|
  index: number,
  notification: Notification,
  open: boolean,
|};

const mapStateToProps = () =>
  createStructuredSelector({
    notification: makeGetNotification(),
  });

const enhance = compose(
  translate(),
  connect(
    mapStateToProps,
    { dismissNotification }
  )
);

class NotificationContainer extends React.Component<Props, State> {
  state = {
    index: 0,
    notification: this.props.notification,
    open: true,
  };

  componentWillReceiveProps(nextProps) {
    if (this.state.notification === null) {
      this.setState(state => ({
        index: state.index + 1,
        notification: nextProps.notification,
      }));
    } else if (this.state.notification !== nextProps.notification) {
      this.setState({ open: false });
    }
  }

  handleExit = () => {
    this.setState(state => ({
      index: state.index + 1,
      open: true,
      notification: this.props.notification,
    }));
  };

  handleDismiss = () => {
    this.props.dismissNotification();
  };

  render() {
    const { t } = this.props;
    const { open, notification, index } = this.state;

    if (!notification) {
      return null;
    }

    return (
      <Snackbar
        key={
          // Force rerender even when notification component is the same
          `notification-${index}`
        }
        open={open}
        message={t(`messages:${notification.message}`, notification.args)}
        autoHideDuration={notification.duration}
        onClick={this.handleDismiss}
        onClose={this.handleDismiss}
        onExited={this.handleExit}
      />
    );
  }
}

export default enhance(NotificationContainer);
