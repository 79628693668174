// @flow
import * as React from "react";
import { when, isEmpty, always, pipe, evolve, trim, replace } from "ramda";
import yup from "yup";
import { compose } from "recompose";
import { translate, type TranslatorProps } from "react-i18next";
import { withFormik, type FormikProps } from "formik";
import type { PlaceDetails } from "../models";
import {
  MAX_LENGTH_PLACE_NAME,
  MAX_LENGTH_PHONE_NUMBER,
  MAX_LENGTH_URL,
  MAX_LENGTH_TEXT,
} from "../constants";
import Content from "./Content";
import PhoneNumberField from "./PhoneNumberField";
import UrlField from "./UrlField";
import TextField from "./TextField";

type Props = {|
  ...$Exact<TranslatorProps>,
  ...FormikProps<$Shape<PlaceDetails>>,
  autoFocus?: boolean,
  layout: React.StatelessFunctionalComponent<{
    children: React.Node,
    onSubmit: () => void,
  }>,
  onSubmit: (details: PlaceDetails) => void,
|};

const defaultProps = {
  autoFocus: false,
};

const nullable = when(isEmpty, always(null));

const normalizeValues = evolve({
  title: pipe(
    trim,
    nullable
  ),
  website: pipe(
    trim,
    nullable
  ),
  phone: pipe(
    replace(/[^+0-9]/g, ""),
    nullable
  ),
  description: pipe(
    trim,
    nullable
  ),
});

const enhance = compose(
  translate(),
  withFormik({
    mapPropsToValues: ({ initialValues }) =>
      initialValues
        ? {
            title: initialValues.title,
            phone: initialValues.phone,
            website: initialValues.website,
            description: initialValues.description,
          }
        : {
            title: "",
            phone: "",
            website: "",
            description: "",
          },
    validationSchema: ({ t }) =>
      yup.object().shape({
        title: yup
          .string()
          .trim()
          .required(t("validations:isEmpty"))
          .max(
            MAX_LENGTH_PLACE_NAME,
            t("validations:tooLong", { max: MAX_LENGTH_PLACE_NAME })
          ),
        phone: yup
          .string()
          .trim()
          .max(
            MAX_LENGTH_PHONE_NUMBER,
            t("validations:tooLong", { max: MAX_LENGTH_PHONE_NUMBER })
          ),
        website: yup
          .string()
          .trim()
          .max(
            MAX_LENGTH_URL,
            t("validations:tooLong", { max: MAX_LENGTH_URL })
          ),
        description: yup
          .string()
          .trim()
          .max(
            MAX_LENGTH_TEXT,
            t("validations:tooLong", { max: MAX_LENGTH_TEXT })
          ),
      }),
    handleSubmit: (values, { props }) =>
      props.onSubmit(normalizeValues(values)),
  })
);

const Container = Content.extend`
  flex: 1;
`;

class PlaceDetailsForm extends React.Component<Props> {
  static defaultProps = defaultProps;

  handleBlur = (...args: Array<any>) => {
    this.props.handleBlur(...args);

    // Dispatch intermediate values to interested party
    if (this.props.onChange) {
      this.props.onChange(normalizeValues(this.props.values));
    }
  };

  render() {
    const { handleBlur } = this;
    const {
      t,
      autoFocus,
      layout,
      isValid,
      values,
      touched,
      errors,
      handleChange,
      submitForm,
    } = this.props;

    const children = (
      <Container>
        <TextField
          autoFocus={autoFocus}
          gutterBottom
          fixedLabel
          id="place_title"
          name="title"
          autocomplete={false}
          label={t("fields:places.details.title")}
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.title}
          error={touched.title && errors.title}
        />
        <PhoneNumberField
          gutterBottom
          fixedLabel
          id="place_phone_number"
          name="phone"
          type="tel"
          autocomplete={false}
          label={t("fields:places.details.phone_number")}
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.phone}
          error={touched.phone && errors.phone}
        />
        <UrlField
          gutterBottom
          id="place_website_url"
          name="website"
          autocomplete={false}
          label={t("fields:places.details.website_url")}
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.website}
          error={touched.website && errors.website}
        />
        <TextField
          gutterBottom
          multiline
          fixedLabel
          id="place_description"
          name="description"
          autocomplete={false}
          label={t("fields:places.details.description")}
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.description}
          error={touched.descriptiono && errors.descriptiono}
        />
        {/* Allow form to be submitted with Return key */}
        <input type="submit" style={{ display: "none" }} />
      </Container>
    );

    return layout({ isValid, children, submitForm });
  }
}

export default enhance(PlaceDetailsForm);
