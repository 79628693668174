// @flow
import * as React from "react";
import { translate, type TranslatorProps } from "react-i18next";
import Composer from "react-composer";
import WizardContent from "./WizardContent";

type PageRenderProps = {
  isValid: boolean,
  children: React.Node,
  extra: any,
  onNext: () => void,
};

type Page = (props: {
  render: (props: PageRenderProps) => React.Node,
  onSubmit: (value: any) => void,
}) => React.Node;

type RenderProps = {
  children: React.Node,
  extra: any,
};

type Props = {
  ...$Exact<TranslatorProps>,
  // Invoked when onGoBack is invoked from the first page
  onGoPastBack: () => void,
  pages: Array<Page>,
  onSubmit: (values: Array<any>) => void,
  render: (props: RenderProps) => React.Node,
};

type State = {
  step: number,
  values: Array<any>,
};

const enhance = translate();

class Wizard extends React.Component<Props, State> {
  state = { step: 0, values: [] };

  handleSubmit = (value: any) => {
    const nextStep = this.state.step + 1;
    const nextValues = [...this.state.values];

    nextValues[this.state.step] = value;

    if (nextStep < this.props.pages.length) {
      this.setState({
        values: nextValues,
        step: this.state.step + 1,
      });
    } else if (this.props.onSubmit) {
      this.props.onSubmit(nextValues);
    }
  };

  handleBack = () => {
    if (this.state.step > 0) {
      this.setState(state => ({
        step: Math.max(0, state.step - 1),
      }));
    } else if (this.props.onGoPastBack) {
      this.props.onGoPastBack();
    }
  };

  render() {
    const { pages, render } = this.props;
    const { step } = this.state;

    return (
      <Composer
        components={pages.map(page => ({ render }) =>
          page({
            render,
            onSubmit: this.handleSubmit,
            onGoBack: this.handleBack,
          })
        )}
      >
        {pages => (
          <WizardContent
            render={render}
            pages={pages}
            step={step}
            onBack={this.handleBack}
          />
        )}
      </Composer>
    );
  }
}

export default enhance(Wizard);
