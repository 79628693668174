// @flow
import * as React from "react";
import { compose, pure } from "recompose";
import { Route } from "react-router";
import { I18n } from "react-i18next";
import {
  withStyles,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
} from "material-ui";
import {
  Menu as MenuIcon,
  ArrowBack as ArrowBackIcon,
} from "material-ui-icons";

type Props = {
  title: React.Node,
  parentRoute: string,
  showMenu?: boolean,
  // Can we remove style?
  style?: Object,
  classes: Object,
  onMenuClick?: ?() => void,
};

const defaultProps = {
  style: null,
  showMenu: false,
  onMenuClick: null,
};

const styles = {
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  title: {
    flex: 1,
  },
};

const enhance = compose(
  pure,
  withStyles(styles)
);

const Navbar = ({
  classes,
  style,
  title,
  parentRoute,
  showMenu,
  onMenuClick,
}: Props) => (
  <I18n>
    {t => (
      <Route exact path={parentRoute}>
        {({ match: atRoot, history }) => (
          <AppBar position="static" style={style}>
            <Toolbar>
              {atRoot && showMenu ? (
                <IconButton
                  color="inherit"
                  aria-label={t("general.menu")}
                  className={classes.menuButton}
                  onClick={onMenuClick}
                >
                  <MenuIcon />
                </IconButton>
              ) : null}
              {!atRoot ? (
                <IconButton
                  color="inherit"
                  aria-label={t("general.back")}
                  className={classes.menuButton}
                  onClick={() => history.push(parentRoute)}
                >
                  <ArrowBackIcon />
                </IconButton>
              ) : null}

              <Typography
                variant="title"
                color="inherit"
                className={classes.title}
              >
                {title}
              </Typography>
            </Toolbar>
          </AppBar>
        )}
      </Route>
    )}
  </I18n>
);

Navbar.defaultProps = defaultProps;

export default enhance(Navbar);
