// @flow
import * as React from "react";
import { I18n } from "react-i18next";
import { APPLICATION_LIST } from "../routes";
import { LayoutContainer, ApplicationListContainer } from "../containers";

const ApplicationListScreen = () => (
  <LayoutContainer
    title={<I18n>{t => t("menu.members")}</I18n>}
    parentRoute={APPLICATION_LIST}
  >
    <ApplicationListContainer />
  </LayoutContainer>
);

export default ApplicationListScreen;
