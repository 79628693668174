// @flow
import * as React from "react";
import { omit } from "ramda";
import { compose, withHandlers, mapProps } from "recompose";
import { Route, withRouter, type ContextRouter } from "react-router";
import { MenuItem } from "material-ui";

type Props = {
  ...$Exact<ContextRouter>,
  to: string,
  exact?: boolean,
  strict?: boolean,
  sensitive?: boolean,
  onClick?: ?() => void,
  handleClick: () => void,
};

const defaultProps = {
  exact: false,
  strict: false,
  sensitive: false,
  onClick: null,
};

const enhance = compose(
  withRouter,
  withHandlers({
    handleClick: ({ to, history, onClick }: any) => () => {
      if (onClick) {
        onClick();
      }

      history.push(to);
    },
  }),
  mapProps(omit(["staticContext", "match", "location", "history"]))
);

const MenuItemLink = ({
  to,
  exact,
  strict,
  sensitive,
  handleClick,
  ...rest
}: Props) => (
  <Route path={to} exact={exact} strict={strict} sensitive={sensitive}>
    {({ match }) => (
      <MenuItem {...rest} selected={!!match} onClick={handleClick} />
    )}
  </Route>
);

MenuItemLink.defaultProps = defaultProps;

export default enhance(MenuItemLink);
