// @flow
// TODO: try babel-plugin-flow-react-proptypes
import invariant from "invariant";
import type { List as ImmutableList } from "immutable";
import * as React from "react";
import { compose, branch, renderComponent, type HOC } from "recompose";
import {
  AutoSizer,
  List as BaseVirtualizedList,
  CellMeasurer,
  CellMeasurerCache,
} from "react-virtualized";
import List from "./List";
import ScrollContainer, { type ScrollProps } from "./ScrollContainer";
import LoadingPlaceholder from "./LoadingPlaceholder";
import EmptyListPlaceholder from "./EmptyListPlaceholder";

type Props<T> = {
  ...ScrollProps,
  loading?: boolean,
  items: ImmutableList<T>,
  overscanRowCount?: number,
  rowRenderer: (props: { item: T }) => React.Element<any>,
};

const defaultProps = {
  loading: false,
  overscanRowCount: 20,
};

const enhance: HOC<*, Props<*>> = compose(
  branch(
    ({ loading }) => !!loading,
    renderComponent(() => <LoadingPlaceholder />)
  ),
  branch(
    ({ items }) => !items.size,
    renderComponent(() => <EmptyListPlaceholder />)
  ),
  ScrollContainer.connect
);

class VirtualizedList<T> extends React.Component<Props<T>> {
  static defaultProps = defaultProps;

  cellMeasurerCache = new CellMeasurerCache({
    defaultHeight: 68,
    fixedWidth: true,
  });

  rowRenderer = ({ key, index, parent, style }: Object) => {
    const item = this.props.items.get(index);

    invariant(item, "Invalid index");

    const child = this.props.rowRenderer({ item });

    if (!child) {
      return null;
    }

    return (
      <CellMeasurer
        key={key}
        cache={this.cellMeasurerCache}
        columnIndex={0}
        parent={parent}
        rowIndex={index}
      >
        {React.cloneElement(child, { ...child.props, style })}
      </CellMeasurer>
    );
  };

  /**
   * Render Virtualized List which is connected to parent Scroll Container
   */
  renderConnected() {
    const {
      items,
      overscanRowCount,
      height,
      isScrolling,
      scrollTop,
      onChildScroll,
    } = this.props;

    if (!height) {
      return null;
    }

    return (
      <List>
        <AutoSizer disableHeight>
          {({ width }) => (
            <BaseVirtualizedList
              autoHeight
              height={height}
              width={width}
              isScrolling={isScrolling}
              scrollTop={scrollTop}
              onScroll={onChildScroll}
              rowRenderer={this.rowRenderer}
              deferredMeasurementCache={this.cellMeasurerCache}
              rowHeight={this.cellMeasurerCache.rowHeight}
              rowCount={items.size}
              overscanRowCount={overscanRowCount}
            />
          )}
        </AutoSizer>
      </List>
    );
  }

  /**
   * Render Virtualized list which does not have parent ScrollContainer
   */
  render() {
    // register child will be injected by ScrollContainer.connect if it
    // can find parent ScrollContainer
    if (this.props.registerChild) {
      return this.renderConnected();
    }

    const { items, overscanRowCount } = this.props;

    return (
      <List>
        <AutoSizer>
          {({ width, height }) => (
            <BaseVirtualizedList
              height={height}
              width={width}
              rowRenderer={this.rowRenderer}
              deferredMeasurementCache={this.cellMeasurerCache}
              rowHeight={this.cellMeasurerCache.rowHeight}
              rowCount={items.size}
              overscanRowCount={overscanRowCount}
            />
          )}
        </AutoSizer>
      </List>
    );
  }
}

// $FlowFixMe: Investigate HOC typings with generics
export default enhance(VirtualizedList);
