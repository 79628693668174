// @flow
import invariant from "invariant";
import * as React from "react";
import styled from "styled-components";
import { I18n } from "react-i18next";
import { List } from "immutable";
import { Typography } from "material-ui";
import { FileUpload as FileUploadIcon } from "material-ui-icons";
import type {
  PlacePhotoFields,
  PlaceEditPhoto,
  PlaceEditPhotoId,
} from "../models";
import PlaceGalleryPhoto from "./PlaceGalleryPhoto";
import SortableContainer from "./SortableContainer";
import SortableElement from "./SortableElement";
import MinHeightExpander from "./MinHeightExpander";
import Dropzone from "./Dropzone";
import Button from "./Button";

type RenderProps = {
  uploadingPhotos: List<PlaceEditPhoto>,
  selectedPhotos: List<PlaceEditPhoto>,
  removeSelectedPhotos: () => void,
  resetSelectedPhotos: () => void,
  onSubmit: () => void,
  children: React.Node,
};

type Props = {
  photos: List<PlaceEditPhoto>,
  onUpload: (files: File[]) => void,
  onRemovePhotos: (selectedPhotos: List<PlaceEditPhoto>) => void,
  onMovePhoto: (index: number, newIndex: number) => void,
  onSubmit: (photos: PlacePhotoFields[]) => void,
  render: (props: RenderProps) => React.Node,
};

type State = {
  isSelectedById: {
    [id: PlaceEditPhotoId]: boolean,
  },
  selectedPhotos: List<PlaceEditPhoto>,
};

const Container = styled.div`
  display: flex;
  padding: 2px;
  flex-wrap: wrap;
  align-content: flex-start;
  flex-grow: 1;
  flex-shrink: 0;
`;

const NoPhotosContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

class PlaceGallery extends React.Component<Props, State> {
  state = {
    isSelectedById: {},
    selectedPhotos: List(),
  };

  handleUpload = (files: File[]) => {
    if (this.props.onUpload) {
      this.props.onUpload(files);
    }
  };

  handleRemoveSelectedPhotos = () => {
    this.props.onRemovePhotos(this.state.selectedPhotos);

    this.setState({
      isSelectedById: {},
      selectedPhotos: List(),
    });
  };

  handleResetSelectedPhotos = () => {
    this.setState({
      isSelectedById: {},
      selectedPhotos: List(),
    });
  };

  handleChangeSelected = (photo: PlaceEditPhoto, isSelected: boolean) => {
    this.setState(state => {
      const isSelectedById = {
        ...state.isSelectedById,
        [photo.id]: isSelected,
      };
      const selectedPhotos = this.props.photos.filter(
        photo => isSelectedById[photo.id]
      );

      return {
        isSelectedById,
        selectedPhotos,
      };
    });
  };

  handleMove = ({
    oldIndex,
    newIndex,
  }: {
    oldIndex: number,
    newIndex: number,
  }) => {
    this.props.onMovePhoto(oldIndex, newIndex);
  };

  /**
   * Returns whether the gallery is ready to be submitted
   *
   * Gallery can not be submitted if there are no photos or if photos are still uploading
   */
  getUploadingPhotos() {
    return this.props.photos.filter(photo => !photo.result);
  }

  handleSubmit = () => {
    this.props.onSubmit(
      this.props.photos
        .map(photo => {
          invariant(
            photo.result,
            "Submission should be allowed while photos are still uploading."
          );

          // $FlowFixMe
          return photo.result.toJS();
        })
        .toArray()
    );
  };

  render() {
    const { photos } = this.props;
    const { selectedPhotos, isSelectedById } = this.state;

    return this.props.render({
      uploadingPhotos: this.getUploadingPhotos(),
      selectedPhotos,
      resetSelectedPhotos: this.handleResetSelectedPhotos,
      removeSelectedPhotos: this.handleRemoveSelectedPhotos,
      onSubmit: this.handleSubmit,
      children: photos.size ? (
        <SortableContainer useDragHandle axis="xy" onSortEnd={this.handleMove}>
          <Container data-cy="dnd-container">
            {photos.map((photo, index) => (
              <SortableElement key={photo.id} index={index}>
                <PlaceGalleryPhoto
                  photo={photo}
                  isSelected={isSelectedById[photo.id]}
                  onChangeSelected={this.handleChangeSelected}
                />
              </SortableElement>
            ))}
          </Container>
        </SortableContainer>
      ) : (
        <I18n>
          {t => (
            <MinHeightExpander>
              <NoPhotosContainer>
                <Typography variant="subheading" paragraph>
                  {t("places.photos.legend")}
                </Typography>
                <Dropzone disableClick onUpload={this.handleUpload}>
                  {({ open }) => (
                    <Button
                      raised
                      color="primary"
                      icon={FileUploadIcon}
                      onClick={open}
                    >
                      {t("places.photos.browse_files")}
                    </Button>
                  )}
                </Dropzone>
              </NoPhotosContainer>
            </MinHeightExpander>
          )}
        </I18n>
      ),
    });
  }
}

export default PlaceGallery;
