"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _utils = require("../utils");

var _listEvents = require("../listEvents");

var getApplications = function getApplications(firebase) {
  return (0, _listEvents.getListEventsFromRef)(function (snap) {
    return snap.val();
  }, (0, _utils.adjustListRef)({
    sort: "createdAt"
  }, firebase.database().ref("/applicationsPending")));
};
exports.default = getApplications;