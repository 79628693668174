// @flow
import * as React from "react";
import { compose, branch, renderNothing } from "recompose";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import {
  makeIsAuthenticationStatusReady,
  makeGetAuthenticatedUser,
  makeGetAuthenticatedMember,
} from "../selectors";
import { loginSuccess, logout } from "../actions";
import { withSideEffects } from "../hocs";
import type { User, Member } from "../models";

type RenderProps = {
  user: ?User,
  member: ?Member,
  onLogin: () => void,
  onLogout: () => void,
};

type Props = {
  ...$Exact<RenderProps>,
  // Log out on mount, and don't render with prior credentials.
  logout: boolean,
  children: (props: RenderProps) => React.Node,

  ready: boolean,
};

const defaultProps = {
  logout: false,
};

const makeMapStateToProps = () =>
  createStructuredSelector({
    ready: makeIsAuthenticationStatusReady(),
    user: makeGetAuthenticatedUser(),
    member: makeGetAuthenticatedMember(),
  });

const enhance = compose(
  connect(
    makeMapStateToProps,
    {
      onLogin: loginSuccess,
      onLogout: logout,
    }
  ),
  branch(({ ready }) => !ready, renderNothing),
  withSideEffects({
    onMount: ({ logout, user, onLogout }) => {
      if (logout && user) {
        onLogout();
      }
    },
  })
);

// This component has a high chance of becomming a class
class AuthContainer extends React.Component<Props, { ready: boolean }> {
  static defaultProps = defaultProps;

  state = {
    ready: this.props.logout ? this.props.user === null : true,
  };

  componentWillReceiveProps(nextProps: Props) {
    if (!nextProps.user) {
      this.setState({ ready: true });
    }
  }

  render() {
    if (!this.state.ready) {
      return null;
    }

    return this.props.children({
      user: this.props.user,
      member: this.props.member,
      onLogin: this.props.onLogin,
      onLogout: this.props.onLogout,
    });
  }
}

export default enhance(AuthContainer);
