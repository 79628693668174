// @flow
import * as React from "react";
import styled from "styled-components";
import { Paper, Typography } from "material-ui";
import { red } from "material-ui/colors";

type Props = {
  children: React.Node,
};

const Container = styled(Paper)`
  && {
    display: flex;
    align-items: center;
    min-height: 48px;
    box-sizing: border-box;
    padding: 8px 16px;
    background-color: ${red[500]};
    color: #fff;
  }
`;

const AlertBlock = ({ children, ...rest }: Props) => (
  <Container {...rest}>
    <Typography variant="body2" color="inherit">
      {children}
    </Typography>
  </Container>
);

export default AlertBlock;
