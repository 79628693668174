// @flow
import * as React from "react";
import { range, multiply } from "ramda";
import { Select, MenuItem } from "material-ui";

// Time options are increments of 30 minutes within 24-hour period
const INCREMENT_MINUTES = 30;

type Props = {
  name: string,
  value: number,
  min: null | number,
  max: null | number,
};

const defaultProps = {
  min: null,
  max: null,
};

// Array of time values between 00:00 and 24:00 inclusive as minutes since midnight
const timeOptions: Array<number> = range(
  0,
  1 + (24 * 60) / INCREMENT_MINUTES
).map(multiply(INCREMENT_MINUTES));

// Format label from TimeValue
// TODO: Same function exists in ScheduleBlock
const formatLabel = (minutesSinceMidnight: number) => {
  const schedule = Math.floor(minutesSinceMidnight / 60);
  const minutes = minutesSinceMidnight % 60;

  return [`${schedule}`.padStart(2, "0"), `${minutes}`.padStart(2, "0")].join(
    ":"
  );
};

class ScheduleTimeField extends React.Component<Props, { hasFocus: boolean }> {
  static defaultProps = defaultProps;

  state = { hasFocus: false };

  handleFocus = () => {
    this.setState({ hasFocus: true });
  };

  handleBlur = () => {
    this.setState({ hasFocus: false });
  };

  render() {
    const { hasFocus } = this.state;
    const { name, value, min, max, ...rest } = this.props;

    return (
      <Select
        {...rest}
        inputProps={{ name }}
        value={value}
        onFocus={this.handleFocus}
        onBlur={this.handleBlur}
      >
        {hasFocus ? (
          timeOptions.map(value => {
            const enabled =
              (min === null || value > min) && (max === null || value < max);

            return (
              <MenuItem key={value} disabled={!enabled} value={value}>
                {formatLabel(value)}
              </MenuItem>
            );
          })
        ) : (
          <MenuItem value={value}>{formatLabel(value)}</MenuItem>
        )}
      </Select>
    );
  }
}

export default ScheduleTimeField;
