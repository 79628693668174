// @flow
import * as React from "react";
import { I18n } from "react-i18next";
import { MEMBER_LIST } from "../routes";
import { LayoutContainer, InviteListContainer } from "../containers";

const InviteListScreen = () => (
  <LayoutContainer
    title={<I18n>{t => t("menu.members")}</I18n>}
    parentRoute={MEMBER_LIST}
  >
    <InviteListContainer />
  </LayoutContainer>
);

export default InviteListScreen;
