// @flow
import * as React from "react";
import BaseLayout from "./Layout";
import Content from "./Content";
import Block from "./Block";
import Row from "./Row";

const Layout = (props: React.ElementProps<typeof BaseLayout>) => (
  <BaseLayout {...props} />
);

Layout.Content = Content;
Layout.Block = Block;
Layout.Row = Row;

export default Layout;
