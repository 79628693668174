// @flow
import invariant from "invariant";
import { List } from "immutable";
import { createSelector, type Selector } from "reselect";
import type { State } from "../reducers";
import {
  makePlaceDetails,
  makePlaceEditPhotoFromPlacePhoto,
  makePlaceSchedule,
  type Place,
  type PlaceId,
  type PlaceDetails,
  type PlaceAddress,
  type PlaceCoordinates,
  type PlaceSchedule,
  type PlaceEditPhoto,
  type PlaceEditState,
  type AddressSuggestion,
} from "../models";

export const getPlace: Selector<State, { id: ?PlaceId }, ?Place> = (
  state,
  { id }
) => (id ? state.place.byId.get(id, null) : null);

export const getEditState: Selector<State, { id: ?PlaceId }, *> = (
  state,
  { id }
) => (id ? state.placeEdit.byId.get(id, null) : state.placeEdit.create);

export const makeGetPlaceEditDetails = (): Selector<
  State,
  { id: ?PlaceId },
  PlaceDetails
> =>
  createSelector(
    getPlace,
    createSelector(getEditState, state => (state ? state.details : null)),
    (place, editState) => {
      if (editState) {
        return editState;
      }

      if (place) {
        return place.getDetails();
      }

      return makePlaceDetails();
    }
  );

export const makeGetPlaceEditAddress = (): Selector<
  State,
  { id: ?PlaceId },
  ?PlaceAddress
> =>
  createSelector(
    getPlace,
    createSelector(getEditState, state => (state ? state.address : null)),
    (place, editState) => {
      if (editState) {
        return editState;
      }

      if (place) {
        return place.getAddress();
      }

      return null;
    }
  );

export const makeGetPlaceEditCoordinates = (): Selector<
  State,
  { id: ?PlaceId },
  ?PlaceCoordinates
> =>
  createSelector(
    getPlace,
    createSelector(getEditState, state => (state ? state.coordinates : null)),
    (place, editState) => {
      if (editState) {
        return editState;
      }

      if (place) {
        return place.getCoordinates();
      }

      return null;
    }
  );

export const makeGetPlaceEditAddressSuggestions = (): Selector<
  State,
  { id: ?PlaceId },
  List<AddressSuggestion>
> =>
  createSelector(
    getEditState,
    state => (state ? state.addressSuggestions : List())
  );

export const makeGetPlaceEditAddressSuggestionsLoading = (): Selector<
  State,
  { id: ?PlaceId },
  boolean
> =>
  createSelector(
    getEditState,
    state => (state ? state.addressSuggestionsLoading : false)
  );

export const makeGetPlaceEditGeocodeLoading = (): Selector<
  State,
  { id: ?PlaceId },
  boolean
> =>
  createSelector(getEditState, state => (state ? state.geocodeLoading : false));

export const makeGetPlaceEditPhotos = (): Selector<
  State,
  { id: ?PlaceId },
  List<PlaceEditPhoto>
> =>
  createSelector(
    getPlace,
    createSelector(
      getEditState,
      state => (state ? state.photosChanged : false)
    ),
    createSelector(getEditState, state => (state ? state.photoIds : null)),
    createSelector(getEditState, state => (state ? state.photoById : null)),
    (place, changed, photoIds, photoById) => {
      if (changed && photoIds && photoById) {
        return photoIds.map(id => {
          const photo = photoById.get(id);

          invariant(photo, "Photo referenced by ID must exist");

          return photo;
        });
      }

      if (place) {
        return place.getPhotos().map(makePlaceEditPhotoFromPlacePhoto);
      }

      return List();
    }
  );

export const makeGetPlaceEditSchedule = (): Selector<
  State,
  { id: ?PlaceId },
  PlaceSchedule
> =>
  createSelector(
    getPlace,
    createSelector(getEditState, state => (state ? state.schedule : null)),
    (place, editState) => {
      if (editState) {
        return editState;
      }

      if (place) {
        return place.getSchedule();
      }

      return makePlaceSchedule();
    }
  );

export const makeGetPlaceEditState = (): Selector<
  State,
  { id: ?PlaceId },
  PlaceEditState
> =>
  createSelector(
    makeGetPlaceEditDetails(),
    makeGetPlaceEditAddress(),
    makeGetPlaceEditCoordinates(),
    makeGetPlaceEditAddressSuggestions(),
    makeGetPlaceEditAddressSuggestionsLoading(),
    makeGetPlaceEditGeocodeLoading(),
    makeGetPlaceEditPhotos(),
    makeGetPlaceEditSchedule(),
    (
      details,
      address,
      coordinates,
      addressSuggestions,
      addressSuggestionsLoading,
      geocodeLoading,
      photos,
      schedule
    ) => ({
      details,
      address,
      coordinates,
      addressSuggestions,
      addressSuggestionsLoading,
      geocodeLoading,
      photos,
      schedule,
    })
  );
