// @flow
import type { Reducer } from "redux";
import { Map, List } from "immutable";
import {
  PLACE_LIST_LOADED,
  PLACE_LOADED,
  PLACE_CREATE_RESULT,
  PLACE_UPDATE_RESULT,
  MEMBER_PLACES_LOADED,
  type Action,
} from "../actions";
import type { Place, PlaceId } from "../models";
import { combineReducers } from "./utils";

export type State = {|
  byId: Map<PlaceId, Place>,
  listIds: List<PlaceId>,
  listLoaded: boolean,
|};

const placeReducer: Reducer<State, Action> = combineReducers({
  byId: (state = Map(), action) => {
    switch (action.type) {
      case MEMBER_PLACES_LOADED:
      case PLACE_LIST_LOADED:
        return state.merge(action.payload.places);

      case PLACE_LOADED:
      case PLACE_CREATE_RESULT:
      case PLACE_UPDATE_RESULT: {
        const { place } = action.payload;

        return state.set(place.id, place);
      }

      default:
        return state;
    }
  },
  listIds: (state = List(), action) => {
    switch (action.type) {
      case PLACE_LIST_LOADED:
        return List(action.payload.places.keys());
      default:
        return state;
    }
  },
  listLoaded: (state = false, action) => {
    switch (action.type) {
      case PLACE_LIST_LOADED:
        return true;
      default:
        return state;
    }
  },
});

export default placeReducer;
