// @flow
import * as React from "react";
import styled, { keyframes } from "styled-components";

type Props = {
  color?: ?string,
};

const defaultProps = {
  color: null,
};

const pulse = keyframes`
from {
  opacity: 0.5;
  transform: scale(1);
}

33% {
  opacity: 1;
  transform: scale(1.5);
}

66% {
  opacity: 0.5;
  transform: scale(1);
}

to {
  opacity: 0.5;
  transform: scale(1);
}
`;

const Dot = styled(({ color, ...rest }) => <div {...rest} />)`
  display: block;
  width: 6px;
  height: 6px;
  margin: 6px;
  border-radius: 50%;
  background-color: ${props => (props.color === "accent" ? "#eee" : "#ddd")};
  animation: ${pulse} 2s ${props => props.delay / 3}s linear infinite;
`;

const Container = styled.div`
  display: inline-flex;
  flex-direction: row;
`;

const LoadingIndicator = ({ color }: Props) => (
  <Container>
    <Dot color={color} delay={0} />
    <Dot color={color} delay={1} />
    <Dot color={color} delay={2} />
  </Container>
);

LoadingIndicator.defaultProps = defaultProps;

export default LoadingIndicator;
