"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _googleMapsApiLoader = require("google-maps-api-loader");

var _googleMapsApiLoader2 = _interopRequireDefault(_googleMapsApiLoader);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var GoogleAdapter = function () {
  function GoogleAdapter(_ref) {
    var apiKey = _ref.apiKey,
        language = _ref.language,
        _ref$libraries = _ref.libraries,
        libraries = _ref$libraries === undefined ? [] : _ref$libraries;

    _classCallCheck(this, GoogleAdapter);

    this.api = null;

    this.apiKey = apiKey;
    this.language = language;
    this.libraries = libraries;
  }

  _createClass(GoogleAdapter, [{
    key: "getApi",
    value: function getApi() {
      // In case google maps api is already loaded
      if (window.google) {
        this.api = Promise.resolve(window.google);
      }

      if (!this.api) {
        this.api = (0, _googleMapsApiLoader2.default)({
          apiKey: this.apiKey,
          language: this.language,
          libraries: this.libraries
        });
      }

      return this.api;
    }

    // See https://developers.google.com/maps/documentation/javascript/places-autocomplete

  }, {
    key: "getSuggestions",
    value: function getSuggestions(input) {
      return this.getApi().then(function (api) {
        return new api.maps.places.AutocompleteService();
      }).then(function (autocomplete) {
        return new Promise(function (resolve, reject) {
          autocomplete.getPlacePredictions({ input: input }, function (predictions, status) {
            if (status !== "OK") {
              reject(new Error("Autocomplete was not successful for the following reason: " + status));

              return;
            }

            resolve(predictions);
          });
        });
      }).then(function (results) {
        return results.map(function (_ref2) {
          var description = _ref2.description,
              matched_substrings = _ref2.matched_substrings,
              main_text = _ref2.structured_formatting.main_text;
          return {
            value: description,
            displayName: main_text,
            hl: matched_substrings.map(function (_ref3) {
              var offset = _ref3.offset,
                  length = _ref3.length;
              return [offset, offset + length];
            })
          };
        });
      });
    }
  }, {
    key: "getCoordinates",
    value: function getCoordinates(address) {
      return this.geocode({ address: address }).then(function (result) {
        return result.coordinates;
      });
    }
  }, {
    key: "getAddress",
    value: function getAddress(coordinates) {
      return this.geocode({ coordinates: coordinates }).then(function (result) {
        return result.address;
      });
    }

    // See https://developers.google.com/maps/documentation/javascript/geocoding

  }, {
    key: "geocode",
    value: function geocode(request) {
      var query = request.address ? { address: request.address.value } : {
        location: {
          lat: request.coordinates.latitude,
          lng: request.coordinates.longitude
        }
      };

      return this.getApi().then(function (api) {
        return new api.maps.Geocoder();
      }).then(function (geocoder) {
        return new Promise(function (resolve, reject) {
          geocoder.geocode(query, function (results, status) {
            if (status !== "OK") {
              reject(new Error("Geocode was not successful for the following reason: " + status));

              return;
            }

            resolve(results);
          });
        });
      }).then(function (results) {
        var _results = _slicedToArray(results, 1),
            result = _results[0];

        var formattedAddress = result.formatted_address,
            location = result.geometry.location;

        var _location$toJSON = location.toJSON(),
            latitude = _location$toJSON.lat,
            longitude = _location$toJSON.lng;

        return {
          address: {
            value: formattedAddress,
            displayName: formattedAddress
          },
          coordinates: {
            latitude: latitude,
            longitude: longitude
          }
        };
      });
    }
  }]);

  return GoogleAdapter;
}();

exports.default = GoogleAdapter;