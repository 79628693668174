// @flow
import * as React from "react";
import styled from "styled-components";
import Typography from "material-ui/Typography";

type Props = {
  children: React.Node,
};

const CommentBox = styled.div`
  border: 1px solid #f5f5f5;
  background-color: #fafafa;
  border-radius: 4px;
  padding: 8px;
  margin: 0 -8px;
`;

const Comment = ({ children }: Props) => (
  <CommentBox>
    <Typography>{children}</Typography>
  </CommentBox>
);

export default Comment;
