/**
 * Decorate action creator with error payload handing.
 *
 * If Error is passed in the action creator as its first arugment, then an error
 * action will be created as specified by Flux Standard Action standard.
 *
 * See https://github.com/acdlite/flux-standard-action
 *
 * @param {string} actionType Action type
 * @param {Function} actionCreator Original action creator
 * @returns {Function} Decorated action creator
 */

const withErrorVariant = (actionType, actionCreator) => (...args) => {
  if (args[0] instanceof Error) {
    const [error] = args;

    return {
      type: actionType,
      error: true,
      payload: error,
    };
  }

  return actionCreator(...args);
};

export default withErrorVariant;
