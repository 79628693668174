"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

var _database = require("firebase/database");

var _combineLatest = require("rxjs/observable/combineLatest");

var _operators = require("rxjs/operators");

var _members = require("../members");

var _getInvite = require("./getInvite");

var _getInvite2 = _interopRequireDefault(_getInvite);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var acceptInvite = function acceptInvite(firebase, id, user) {
  if (user.isAnonymous) {
    throw new Error("Invite can not be accepted by anonymous user.");
  }

  return (0, _combineLatest.combineLatest)((0, _getInvite2.default)(firebase, id), (0, _members.getMember)(firebase, user.uid)).pipe((0, _operators.first)(),
  // $FlowFixMe
  (0, _operators.exhaustMap)(function (_ref) {
    var _ref2 = _slicedToArray(_ref, 2),
        invite = _ref2[0],
        member = _ref2[1];

    if (invite === null) {
      throw new Error("Invite not found");
    }

    var updates = {};

    if (member === null) {
      updates["members/" + user.uid] = {
        id: user.uid,
        displayName: user.displayName,
        photoURL: user.photoURL,
        email: user.email,
        admin: !!invite.admin,
        publisher: !!invite.publisher,
        updatedAt: _database.ServerValue.TIMESTAMP,
        createdAt: _database.ServerValue.TIMESTAMP
      };

      updates["inviteClaims/account/" + user.uid] = invite.id;
    } else {
      if (invite.admin) {
        updates["members/" + user.uid + "/admin"] = true;
      }
      if (invite.publisher) {
        updates["members/" + user.uid + "/publisher"] = true;
      }
    }

    if (invite.admin) {
      updates["inviteClaims/admin/" + user.uid] = invite.id;
    }

    if (invite.publisher) {
      updates["inviteClaims/publisher/" + user.uid] = invite.id;
    }

    updates["invites/" + invite.id + "/claimedBy"] = user.uid;

    return firebase.database().ref("/").update(updates);
  })).toPromise();
};

exports.default = acceptInvite;