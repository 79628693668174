"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _database = require("firebase/database");

var _firebaseAutoIds = require("firebase-auto-ids");

var _firebaseAutoIds2 = _interopRequireDefault(_firebaseAutoIds);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var createInvite = function createInvite(firebase) {
  var id = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : (0, _firebaseAutoIds2.default)(Date.now());
  return firebase.database().ref("/invites/" + id).set({
    id: id,
    publisher: true,
    updatedAt: _database.ServerValue.TIMESTAMP,
    createdAt: _database.ServerValue.TIMESTAMP
  });
};

exports.default = createInvite;