import styled from "styled-components";
import { grey } from "material-ui/colors";

const Circle = styled.div`
  width: ${props => props.size || 55}px;
  height: ${props => props.size || 55}px;
  border-radius: 50%;
  background-color: ${grey[300]};
`;

export default Circle;
