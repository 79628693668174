// @flow
import * as React from "react";
import styled from "styled-components";
import { Drawer as BaseDrawer } from "material-ui";

type Props = {
  open: boolean,
  children: React.Node,
};

const Container = styled.div`
  width: 320px;
`;

const Drawer = ({ open, children }: Props) => (
  <BaseDrawer open={open}>
    <Container>{children}</Container>
  </BaseDrawer>
);

export default Drawer;
