import { combineEpics } from "redux-observable";
import { of } from "rxjs";
import {
  filter,
  first,
  map,
  switchMap,
  catchError,
  takeUntil,
  tap,
} from "rxjs/operators";
import { getPlace, getPlaces, createPlace, updatePlace } from "outnabout-api";
import { filterListEvents, VALUE_EVENT } from "outnabout-api/lib/listEvents";
import {
  PLACE_LIST_SHOWN,
  PLACE_LIST_HIDDEN,
  PLACE_SHOWN,
  PLACE_HIDDEN,
  PLACE_CREATE,
  PLACE_UPDATE,
  PLACE_PUBLISH,
  PLACE_UNPUBLISH,
  placeListLoaded,
  placeLoaded,
  placeCreateResult,
  placeUpdateResult,
  placePublishResult,
  placeUnpublishResult,
} from "../actions";
import { formatRoute, PLACE_DETAILS } from "../routes";
import { ofType, logError, createSimpleApiCallEpic } from "./utils";

// TODO: Group actions by place id
const retrievePlaceEpic = (action$, store, { api }) =>
  action$.pipe(
    filter(ofType(PLACE_SHOWN)),
    switchMap(action => {
      const { id } = action.payload;
      const cancelAction$ = action$.pipe(
        filter(ofType(PLACE_HIDDEN)),
        filter(action => action.payload.id === id)
      );

      return getPlace(api, id).pipe(
        catchError(logError(error => of(error))),
        map(placeLoaded),
        takeUntil(cancelAction$)
      );
    })
  );

const retrievePlaceListEpic = (action$, store, { api }) =>
  action$.pipe(
    filter(ofType(PLACE_LIST_SHOWN)),
    switchMap(() =>
      getPlaces(api).pipe(
        filterListEvents(VALUE_EVENT),
        first(),
        map(event => placeListLoaded(event.entities)),
        takeUntil(action$.ofType(PLACE_LIST_HIDDEN))
      )
    )
  );

const createPlaceEpic = (action$, store, { api, history }) =>
  action$.pipe(
    filter(ofType(PLACE_CREATE)),
    switchMap(action => {
      const { details, location, photos, schedule } = action.payload;

      return createPlace(api, details, location, photos, schedule).pipe(
        tap(place =>
          history.push(formatRoute(PLACE_DETAILS, { id: place.id }))
        ),
        catchError(logError(error => of(error))),
        map(placeCreateResult)
      );
    })
  );

const updatePlaceEpic = (action$, store, { api, history }) =>
  action$.pipe(
    filter(ofType(PLACE_UPDATE)),
    switchMap(action => {
      const { placeId, updates } = action.payload;

      return updatePlace(api, placeId, updates).pipe(
        tap(place =>
          history.push(formatRoute(PLACE_DETAILS, { id: place.id }))
        ),
        catchError(logError(error => of(error))),
        map(placeUpdateResult)
      );
    })
  );

export default combineEpics(
  retrievePlaceEpic,
  retrievePlaceListEpic,
  createPlaceEpic,
  updatePlaceEpic,
  createSimpleApiCallEpic(
    PLACE_PUBLISH,
    (action, { api }) =>
      updatePlace(api, action.payload.place.id, { published: true }),
    placePublishResult
  ),
  createSimpleApiCallEpic(
    PLACE_UNPUBLISH,
    (action, { api }) =>
      updatePlace(api, action.payload.place.id, { published: false }),
    placeUnpublishResult
  )
);
