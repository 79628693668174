// @flow
import type { List } from "immutable";

/**
 * Remove an item from immutable list
 */
const removeFromList = <T>(list: List<T>, item: T): List<T> => {
  const index = list.indexOf(item);

  if (index === -1) {
    return list;
  }

  return list.remove(index);
};

export default removeFromList;
