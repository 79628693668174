// @flow
import * as React from "react";
import styled from "styled-components";
import { I18n } from "react-i18next";
import { Typography } from "material-ui";
import { Phone as PhoneIcon, Public as PublicIcon } from "material-ui-icons";
import {
  AttributionBlock,
  Gallery,
  AddressBlock,
  ScheduleBlock,
  PlacePhoto,
  ListItem,
  Button,
} from "../components";
import {
  LayoutContainer,
  CurrentMemberContainer,
  PlaceContainer,
} from "../containers";
import {
  formatRoute,
  PLACE_LIST,
  PLACE_EDIT_DETAILS,
  PLACE_EDIT_ADDRESS,
  PLACE_EDIT_PHOTOS,
  PLACE_EDIT_SCHEDULE,
} from "../routes";
import type { PlaceId } from "../models";

type Props = {
  id: PlaceId,
};

const HorizontalSpace = styled.div`
  padding: 0 16px;
`;

const VerticalSpace = styled.div`
  padding: 12px 0;
`;

const PlaceDetailsScreen = ({ id }: Props) => (
  <I18n>
    {t => (
      <LayoutContainer title={t("menu.places")} parentRoute={PLACE_LIST}>
        <CurrentMemberContainer>
          {({ member }) => (
            <PlaceContainer
              id={id}
              render={({ place, publishPlace, unpublishPlace }) => (
                <React.Fragment>
                  <VerticalSpace>
                    <HorizontalSpace>
                      <Typography gutterBottom variant="title">
                        {place.getTitle()}
                      </Typography>
                      {place.getDescription() ? (
                        <Typography gutterBottom>
                          {place.getDescription()}
                        </Typography>
                      ) : (
                        <Typography>{t("general.no_description")}</Typography>
                      )}
                    </HorizontalSpace>
                  </VerticalSpace>
                  {place.getPhone() && (
                    <ListItem
                      button
                      icon={<PhoneIcon />}
                      label={t("places.details.phone")}
                      href={place.getDialUrl()}
                      secondaryText={place.getPhone()}
                    />
                  )}
                  {place.getWebsite() && (
                    <ListItem
                      button
                      icon={<PublicIcon />}
                      label={t("places.details.website")}
                      href={place.getWebsiteUrl()}
                      secondaryText={place.getWebsite()}
                    />
                  )}
                  {member.isAdmin() ? (
                    <AttributionBlock
                      member={place.getOwner()}
                      createdAt={place.getCreatedAt()}
                      updatedAt={place.getUpdatedAt()}
                    />
                  ) : null}
                  <VerticalSpace>
                    <HorizontalSpace>
                      <Button
                        fullWidth
                        color="primary"
                        link={formatRoute(PLACE_EDIT_DETAILS, {
                          id: place.id,
                        })}
                      >
                        {t("places.actions.update_details")}
                      </Button>
                    </HorizontalSpace>
                  </VerticalSpace>
                  <VerticalSpace>
                    <HorizontalSpace>
                      {place.isPublished() ? (
                        <Button raised fullWidth onClick={unpublishPlace}>
                          {t("places.actions.unpublish")}
                        </Button>
                      ) : (
                        <Button
                          raised
                          fullWidth
                          color="primary"
                          disabled={!place.canPublish()}
                          onClick={publishPlace}
                        >
                          {t("places.actions.publish")}
                        </Button>
                      )}
                    </HorizontalSpace>
                  </VerticalSpace>
                  <VerticalSpace>
                    <HorizontalSpace>
                      <Gallery
                        items={place.getPhotos()}
                        renderItem={photo => <PlacePhoto photo={photo} />}
                      />
                      <Button
                        fullWidth
                        color="primary"
                        link={formatRoute(PLACE_EDIT_PHOTOS, {
                          id: place.id,
                        })}
                      >
                        {t("places.actions.update_photos")}
                      </Button>
                    </HorizontalSpace>
                  </VerticalSpace>
                  <VerticalSpace>
                    <HorizontalSpace>
                      <AddressBlock
                        coordinates={place.getCoordinates()}
                        address={place.getDisplayAddress()}
                      />
                      <Button
                        fullWidth
                        color="primary"
                        link={formatRoute(PLACE_EDIT_ADDRESS, {
                          id: place.id,
                        })}
                      >
                        {t("places.actions.update_location")}
                      </Button>
                    </HorizontalSpace>
                  </VerticalSpace>
                  <VerticalSpace>
                    <ScheduleBlock schedule={place.getSchedule()} />
                    <HorizontalSpace>
                      <Button
                        fullWidth
                        color="primary"
                        link={formatRoute(PLACE_EDIT_SCHEDULE, {
                          id: place.id,
                        })}
                      >
                        {t("places.actions.update_schedule")}
                      </Button>
                    </HorizontalSpace>
                  </VerticalSpace>
                </React.Fragment>
              )}
            />
          )}
        </CurrentMemberContainer>
      </LayoutContainer>
    )}
  </I18n>
);

export default PlaceDetailsScreen;
