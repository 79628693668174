// @flow
import * as React from "react";
import firebaseui from "firebaseui";
import { FirebaseAuth } from "react-firebaseui";
import firebase from "firebase";
import type { App } from "firebase/app";
import FirebaseProvider from "./FirebaseProvider";

type Props = {
  firebase: App,
  onLogin?: () => void,
};

const defaultProps = {
  onLogin: () => {},
};

const enhance = FirebaseProvider.connect;

const AuthContainer = ({ firebase: app, onLogin }: Props) => (
  <FirebaseAuth
    firebaseAuth={app.auth()}
    uiConfig={{
      // Popup signin flow rather than redirect flow.
      signInFlow: "popup",
      // We will display Google and Facebook as auth providers.
      signInOptions: [
        firebase.auth.EmailAuthProvider.PROVIDER_ID,
        firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      ],
      credentialHelper: firebaseui.auth.CredentialHelper.GOOGLE_YOLO,
      callbacks: {
        signInSuccess: onLogin,
      },
    }}
  />
);

AuthContainer.defaultProps = defaultProps;

export default enhance(AuthContainer);
