// @flow
import "normalize.css/normalize.css";
import "typeface-roboto"; // eslint-disable-line import/extensions
import invariant from "invariant";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { ConnectedRouter } from "react-router-redux";
import createBrowserHistory from "history/createBrowserHistory";
import { MuiThemeProvider } from "material-ui/styles";
import { I18nextProvider } from "react-i18next";
import { AppContainer } from "react-hot-loader";
import firebase from "firebase";
import Api from "outnabout-api";
import { GoogleAdapter, YandexAdapter } from "outnabout-map";
import { GoogleGeocoding, YandexGeocoding } from "outnabout-geocode";
import "./index.css";
import configureStore from "./configureStore";
import muiTheme from "./theme";
import i18n from "./i18n";
import { FirebaseProvider, DateFns, Map } from "./components";
import RootScreen from "./screens/RootScreen";
import registerServiceWorker from "./registerServiceWorker";

// if (process.env.NODE_ENV !== "production") {
//   const { whyDidYouUpdate } = require("why-did-you-update"); // eslint-disable-line global-require, import/no-extraneous-dependencies
//   whyDidYouUpdate(React);
// }

const firebaseApp = firebase.initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY || "",
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN || "",
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL || "",
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID || "",
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET || "",
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID || "",
});

const api = Api(firebaseApp);

window.firebase = api;

const history = createBrowserHistory();

let mapsAdapter;

switch (process.env.REACT_APP_MAP_PROVIDER) {
  case "yandex":
    mapsAdapter = new YandexAdapter({ language: i18n.language });

    break;

  default:
    mapsAdapter = new GoogleAdapter({
      language: i18n.language,
      apiKey: process.env.REACT_APP_GOOGLE_API_KEY || "",
      libraries: ["places"],
    });
}

let geocode;

switch (process.env.REACT_APP_GEOCODE_PROVIDER) {
  case "yandex":
    geocode = new YandexGeocoding();

    break;

  default:
    geocode = new GoogleGeocoding({
      language: i18n.language,
      apiKey: process.env.REACT_APP_GOOGLE_API_KEY || "",
      libraries: ["places"],
    });
}

const { store, persistor } = configureStore(undefined, {
  api,
  history,
  geocode,
});

const render = Component => {
  const rootEl = document.getElementById("root");

  invariant(rootEl !== null, "Root element not found");

  ReactDOM.render(
    <Provider store={store}>
      <Map.Provider adapter={mapsAdapter}>
        <PersistGate persistor={persistor}>
          <MuiThemeProvider theme={muiTheme}>
            <I18nextProvider i18n={i18n}>
              <DateFns.Provider language={i18n.language}>
                <FirebaseProvider firebase={firebaseApp}>
                  <ConnectedRouter history={history}>
                    <AppContainer>
                      <Component />
                    </AppContainer>
                  </ConnectedRouter>
                </FirebaseProvider>
              </DateFns.Provider>
            </I18nextProvider>
          </MuiThemeProvider>
        </PersistGate>
      </Map.Provider>
    </Provider>,
    rootEl
  );
};

render(RootScreen);
registerServiceWorker();

// $FlowFixMe
if (module.hot) {
  // $FlowFixMe
  module.hot.accept("./screens/RootScreen", () => {
    render(RootScreen);
  });
}
