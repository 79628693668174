// @flow
import * as React from "react";
import { compose, withStateHandlers } from "recompose";
import { translate, type TranslatorProps } from "react-i18next";
import { withStyles, Typography, IconButton, MenuItem } from "material-ui";
import {
  ChevronLeft as ChevronLeftIcon,
  ArrowDropDown as ArrowDropDownIcon,
} from "material-ui-icons";
import type { Member } from "../models";
import MemberAvatar from "./MemberAvatar";
import IconButtonMenu from "./IconButtonMenu";

type Props = {
  ...$Exact<TranslatorProps>,
  classes: Object,
  showCloseButton: boolean,
  member: Member,
  onClose?: ?() => void,
  onLogout?: ?() => void,
};

const defaultProps = {
  showCloseButton: false,
  onClose: null,
  onLogout: null,
};

const styles = (theme: Object) => {
  const backgroundColor = theme.palette.primary.main;
  const textColor = theme.palette.primary.contrastText;

  return {
    header: {
      padding: 16,
      position: "relative",
      backgroundColor,
      color: textColor,
    },
    menuButton: {
      position: "absolute",
      bottom: 8,
      right: 8,
    },
    closeButton: {
      position: "absolute",
      top: 8,
      right: 8,
    },
    memberAvatar: {
      width: 60,
      height: 60,
      marginBottom: 16,
    },
  };
};

const enhance = compose(
  translate(),
  withStyles(styles),
  withStateHandlers(
    {
      menuOpen: false,
    },
    {
      toggleMenu: ({ menuOpen }) => () => ({ menuOpen: !menuOpen }),
    }
  )
);

const DrawerHeader = ({
  t,
  classes,
  showCloseButton,
  member,
  onClose,
  onLogout,
}: Props) => (
  <div className={classes.header}>
    <IconButtonMenu
      className={classes.menuButton}
      color="inherit"
      icon={<ArrowDropDownIcon />}
    >
      <MenuItem onClick={onLogout}>{t("menu.logout")}</MenuItem>
    </IconButtonMenu>

    {showCloseButton && (
      <IconButton
        className={classes.closeButton}
        color="inherit"
        onClick={onClose}
      >
        <ChevronLeftIcon />
      </IconButton>
    )}
    <MemberAvatar
      className={classes.memberAvatar}
      alt={member.getDisplayName()}
      member={member}
    />
    <Typography color="inherit" variant="body2">
      {member.getDisplayName()}
    </Typography>
    <Typography color="inherit" variant="body1">
      {member.getEmail()}
    </Typography>
  </div>
);

DrawerHeader.defaultProps = defaultProps;

export default enhance(DrawerHeader);
