// @flow
import * as React from "react";
import Typography from "material-ui/Typography";
import { grey } from "material-ui/colors";
import { withStyles } from "material-ui/styles";
import PublicLayout from "./PublicLayout";

type Props = {|
  classes: { [string]: string },
  icon?: React.ComponentType<{ className: string }>,
  subheading?: React.Node,
  caption?: React.Node,
|};

const defaultProps = {
  icon: null,
  subheading: null,
  caption: null,
};

const enhance = withStyles({
  icon: {
    width: 120,
    height: 120,
    color: grey[300],
  },
});

const ErrorScreen = ({ classes, icon, subheading, caption }: Props) => {
  const IconComponent = icon;

  return (
    <PublicLayout>
      {IconComponent && <IconComponent className={classes.icon} />}
      {subheading && (
        <Typography variant="subheading" align="center" gutterBottom>
          {subheading}
        </Typography>
      )}
      {caption && (
        <Typography variant="caption" align="center" gutterBottom>
          {caption}
        </Typography>
      )}
    </PublicLayout>
  );
};

ErrorScreen.defaultProps = defaultProps;

export default enhance(ErrorScreen);
