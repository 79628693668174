"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require("react");

var React = _interopRequireWildcard(_react);

var _url = require("url");

var _url2 = _interopRequireDefault(_url);

var _reactGoogleMaps = require("react-google-maps");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var BASE_SCRIPT_URL = "https://maps.googleapis.com/maps/api/js";

var ScriptLoader = (0, _reactGoogleMaps.withScriptjs)(function (_ref) {
  var children = _ref.children;
  return children;
});

var MapContainer = (0, _reactGoogleMaps.withGoogleMap)(function (_ref2) {
  var children = _ref2.children;
  return children;
});

var MAP_OPTIONS = {
  disableDefaultUI: true,
  zoomControl: true,
  styles: [
  // Hide extneral businesses on the map
  {
    featureType: "poi.business",
    stylers: [{
      visibility: "off"
    }]
  }]
};

var GoogleMap = function (_React$Component) {
  _inherits(GoogleMap, _React$Component);

  function GoogleMap() {
    var _ref3;

    var _temp, _this, _ret;

    _classCallCheck(this, GoogleMap);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref3 = GoogleMap.__proto__ || Object.getPrototypeOf(GoogleMap)).call.apply(_ref3, [this].concat(args))), _this), _this.handleRef = function (map) {
      _this.map = map;
    }, _this.handleClick = function (e) {
      var onClick = _this.props.onClick;


      if (onClick) {
        var _e$latLng$toJSON = e.latLng.toJSON(),
            lat = _e$latLng$toJSON.lat,
            lng = _e$latLng$toJSON.lng;

        onClick([lat, lng]);
      }
    }, _this.handleBoundsChange = function () {
      if (!_this.map) {
        return;
      }

      var onBoundsChange = _this.props.onBoundsChange;


      if (onBoundsChange) {
        var _this$map$getCenter$t = _this.map.getCenter().toJSON(),
            lat = _this$map$getCenter$t.lat,
            lng = _this$map$getCenter$t.lng;

        var zoom = _this.map.getZoom();

        onBoundsChange([lat, lng], zoom);
      }
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  _createClass(GoogleMap, [{
    key: "render",
    value: function render() {
      var _props = this.props,
          language = _props.language,
          apiKey = _props.apiKey,
          libraries = _props.libraries,
          width = _props.width,
          height = _props.height,
          center = _props.center,
          zoom = _props.zoom,
          _static = _props.static,
          marker = _props.marker,
          onBoundsChange = _props.onBoundsChange,
          onClick = _props.onClick,
          rest = _objectWithoutProperties(_props, ["language", "apiKey", "libraries", "width", "height", "center", "zoom", "static", "marker", "onBoundsChange", "onClick"]);

      var apiUrl = _url2.default.format(_extends({}, _url2.default.parse(BASE_SCRIPT_URL), {
        query: {
          language: language,
          libraries: libraries,
          key: apiKey
        }
      }));

      return React.createElement(
        ScriptLoader,
        { googleMapURL: apiUrl, loadingElement: React.createElement("div", null) },
        React.createElement(
          MapContainer,
          {
            containerElement: React.createElement("div", null),
            mapElement: React.createElement("div", _extends({}, rest, {
              style: { width: width + "px", height: height + "px" }
            }))
          },
          React.createElement(
            _reactGoogleMaps.GoogleMap,
            {
              defaultOptions: MAP_OPTIONS,
              center: { lat: center[0], lng: center[1] },
              zoom: zoom,
              onClick: this.handleClick,
              onZoomChanged: this.handleBoundsChange,
              onCenterChanged: this.handleBoundsChange,
              ref: this.handleRef
            },
            marker && React.createElement(_reactGoogleMaps.Marker, {
              "data-cy": "marker",
              position: { lat: marker[0], lng: marker[1] }
            })
          )
        )
      );
    }
  }]);

  return GoogleMap;
}(React.Component);

exports.default = GoogleMap;