import { combineEpics } from "redux-observable";
import { of, merge } from "rxjs";
import {
  tap,
  filter,
  distinctUntilChanged,
  map,
  switchMap,
  takeUntil,
  ignoreElements,
} from "rxjs/operators";
import { getAuthenticatedUser, getMember, logout } from "outnabout-api";
import { ofType } from "./utils";
import { LOGIN_SUCCESS, LOGOUT, authenticationStatusChange } from "../actions";

/**
 * Compare two users for equality.
 */
const usersEqual = (prevUser, nextUser) => {
  if (prevUser === nextUser) {
    return true;
  }

  if (prevUser === null && nextUser !== null) {
    return false;
  }

  if (prevUser !== null && nextUser === null) {
    return false;
  }

  return prevUser.uid === nextUser.uid;
};

const authEpic = (action$, store, { api }) =>
  merge(
    getAuthenticatedUser(api),
    action$.pipe(
      filter(ofType(LOGIN_SUCCESS)),
      map(action => action.payload.user)
    )
  ).pipe(
    distinctUntilChanged(usersEqual),
    switchMap(user => {
      if (user === null) {
        return of(authenticationStatusChange(null, null));
      }

      return getMember(api, user.uid).pipe(
        takeUntil(action$.pipe(filter(ofType(LOGOUT)))),
        map(member => authenticationStatusChange(user, member))
      );
    })
  );

const logoutEpic = (action$, store, { api }) =>
  action$.pipe(
    filter(ofType(LOGOUT)),
    tap(() => logout(api)),
    ignoreElements()
  );

export default combineEpics(authEpic, logoutEpic);
