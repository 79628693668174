"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _database = require("firebase/database");

var updateApplication = function updateApplication(firebase, id, values) {
  return firebase.database().ref("/applications/" + id).update(_extends({}, values, {
    updatedAt: _database.ServerValue.TIMESTAMP
  }));
};

exports.default = updateApplication;