// @flow
import type { RecordOf } from "immutable";
import type { Reducer } from "redux";
import type { Action } from "../actions";
import { combineReducers } from "./utils";
import uiReducer, { type State as UiState } from "./uiReducer";
import notificationReducer, {
  type State as NotificationState,
} from "./notificationReducer";
import authReducer, { type State as AuthState } from "./authReducer";
import applicationReducer, {
  type State as ApplicationState,
} from "./applicationReducer";
import memberReducer, { type State as MemberState } from "./memberReducer";
import signupReducer, { type State as SignupState } from "./signupReducer";
import inviteReducer, { type State as InviteState } from "./inviteReducer";
import placeReducer, { type State as PlaceState } from "./placeReducer";
import placeEditReducer, {
  type State as PlaceEditState,
} from "./placeEditReducer";

export type State = RecordOf<{
  ui: UiState,
  auth: AuthState,
  application: ApplicationState,
  member: MemberState,
  signup: SignupState,
  invite: InviteState,
  notification: NotificationState,
  place: PlaceState,
  placeEdit: PlaceEditState,
}>;

const rootReducer: Reducer<State, Action> = combineReducers({
  ui: uiReducer,
  auth: authReducer,
  application: applicationReducer,
  member: memberReducer,
  signup: signupReducer,
  invite: inviteReducer,
  notification: notificationReducer,
  place: placeReducer,
  placeEdit: placeEditReducer,
});

export default rootReducer;
