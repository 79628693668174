// @flow
import * as React from "react";
import { I18n } from "react-i18next";
import {
  LayoutContainer,
  MemberContainer,
  CurrentMemberContainer,
  PlaceContainer,
} from "../containers";
import {
  AlertBlock,
  LoadingPlaceholder,
  VirtualizedList,
  MemberDetailsHeader,
  PlaceListItem,
  Button,
} from "../components";
import { type MemberId } from "../models";
import { formatRoute, MEMBER_LIST, PLACE_DETAILS } from "../routes";

type Props = {
  id: MemberId,
};

const MemberDetailsScreen = ({ id }: Props) => (
  <I18n>
    {t => (
      <MemberContainer
        id={id}
        render={({
          isMemberLoaded,
          isPlacesLoaded,
          member,
          placeIds,
          suspendMember,
          restoreMember,
          isMemberUpdating,
        }) => (
          <LayoutContainer
            title={<I18n>{t => t("menu.members")}</I18n>}
            parentRoute={MEMBER_LIST}
          >
            {isMemberLoaded ? (
              <React.Fragment>
                {member.canPublish() ? null : (
                  <AlertBlock>
                    {t("members.details.suspended_notice")}
                  </AlertBlock>
                )}
                <MemberDetailsHeader member={member} />
                <CurrentMemberContainer>
                  {({ member: currentMember }) =>
                    currentMember.isAdmin() && !member.isAdmin() ? (
                      <Button
                        raised
                        loading={isMemberUpdating}
                        align="center"
                        onClick={() =>
                          member.canPublish()
                            ? suspendMember()
                            : restoreMember()
                        }
                      >
                        {member.canPublish()
                          ? t("members.details.suspend_action")
                          : t("members.details.restore_action")}
                      </Button>
                    ) : null
                  }
                </CurrentMemberContainer>
              </React.Fragment>
            ) : (
              <LoadingPlaceholder />
            )}
            <VirtualizedList
              loading={!isPlacesLoaded}
              items={placeIds}
              rowRenderer={({ item }) => (
                <PlaceContainer
                  id={item}
                  render={({ place }) => (
                    <PlaceListItem
                      link={formatRoute(PLACE_DETAILS, { id: place.id })}
                      place={place}
                    />
                  )}
                />
              )}
            />
          </LayoutContainer>
        )}
      />
    )}
  </I18n>
);

export default MemberDetailsScreen;
