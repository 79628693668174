// @flow
import { combineEpics } from "redux-observable";
import { of, merge, from } from "rxjs";
import {
  map,
  takeUntil,
  first,
  filter,
  tap,
  switchMap,
  switchMapTo,
  catchError,
} from "rxjs/operators";
import {
  getInvite,
  getInvites,
  acceptInvite,
  createInvite,
  setInviteAdmin,
} from "outnabout-api";
import { CRITERIA_ACTIVE } from "outnabout-api/lib/invites";
import { filterListEvents, VALUE_EVENT } from "outnabout-api/lib/listEvents";
import {
  INVITE_SHOWN,
  INVITE_HIDDEN,
  ACCEPT_INVITE,
  MEMBER_CREATE_BUTTON_PRESSED,
  MEMBER_LIST_SHOWN,
  MEMBER_LIST_HIDDEN,
  INVITE_LIST_SHOWN,
  INVITE_LIST_HIDDEN,
  INVITE_SET_ADMIN,
  inviteLoaded,
  acceptInviteResult,
  inviteListLoaded,
  inviteCreateResult,
  inviteSetAdminResult,
} from "../actions";
import { formatRoute, PLACE_CREATE, INVITE_DETAILS } from "../routes";
import { ofType, logError } from "./utils";

const retrieveInviteEpic = (action$, store, { api }) =>
  action$.pipe(
    filter(ofType(INVITE_SHOWN)),
    switchMap(action => {
      const { id } = action.payload;

      return getInvite(api, id).pipe(
        map(invite => inviteLoaded(id, invite)),
        takeUntil(
          action$.pipe(
            filter(ofType(INVITE_HIDDEN)),
            filter(action => action.payload.id === id)
          )
        )
      );
    })
  );

const acceptInviteEpic = (action$, store, { api, history }) =>
  action$.pipe(
    filter(ofType(ACCEPT_INVITE)),
    switchMap(action => {
      const { invite, user } = action.payload;

      return acceptInvite(api, invite.id, user).catch(logError(of));
    }),
    map(acceptInviteResult),
    tap(() => history.push(PLACE_CREATE))
  );

const createInviteEpic = (action$, store, { api, history }) =>
  action$.pipe(
    filter(ofType(MEMBER_CREATE_BUTTON_PRESSED)),
    switchMap(action => {
      const { id } = action.payload;

      return from(createInvite(api, id)).pipe(
        switchMapTo(getInvite(api, id).pipe(first())),
        map(inviteCreateResult),
        tap(() => history.push(formatRoute(INVITE_DETAILS, { id }))),
        catchError(logError(error => inviteCreateResult(error)))
      );
    })
  );

const fetchInvites = api =>
  getInvites(api, CRITERIA_ACTIVE).pipe(
    filterListEvents(VALUE_EVENT),
    first(),
    map(event => inviteListLoaded(event.entities))
  );

const inviteListEpic = (action$, store, { api }) =>
  // TODO: Switch to counts or use windowToggle
  merge(
    action$.pipe(
      filter(ofType(MEMBER_LIST_SHOWN)),
      switchMap(() =>
        fetchInvites(api).pipe(
          takeUntil(action$.pipe(filter(ofType(MEMBER_LIST_HIDDEN))))
        )
      )
    ),
    action$.pipe(
      filter(ofType(INVITE_LIST_SHOWN)),
      switchMap(() =>
        fetchInvites(api).pipe(
          takeUntil(action$.pipe(filter(ofType(INVITE_LIST_HIDDEN))))
        )
      )
    )
  );

const inviteSetAdminEpic = (action$, store, { api }) =>
  action$.pipe(
    filter(ofType(INVITE_SET_ADMIN)),
    switchMap(action => {
      const { invite, isAdmin } = action.payload;

      return from(setInviteAdmin(api, invite.id, isAdmin)).pipe(
        switchMapTo(getInvite(api, invite.id)),
        first(),
        map(inviteSetAdminResult),
        catchError(logError(error => of(inviteSetAdminResult(error))))
      );
    })
  );

export default combineEpics(
  retrieveInviteEpic,
  acceptInviteEpic,
  inviteListEpic,
  createInviteEpic,
  inviteSetAdminEpic
);
