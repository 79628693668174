"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _firebaseAutoIds = require("firebase-auto-ids");

var _firebaseAutoIds2 = _interopRequireDefault(_firebaseAutoIds);

var _database = require("firebase/database");

var _operators = require("rxjs/operators");

var _members = require("../members");

var _utils = require("../utils");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var createPlace = function createPlace(api, details, location, photos, schedule) {
  var placeId = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : (0, _firebaseAutoIds2.default)(Date.now());

  var ref = api.database().ref("/places/" + placeId);

  return (0, _members.getCurrentMember)(api).pipe((0, _operators.first)(), (0, _operators.switchMap)(function (member) {
    return ref.set({
      id: placeId,
      published: false,
      authorized: true,
      details: details,
      location: location,
      photos: photos,
      schedule: schedule,
      updatedAt: _database.ServerValue.TIMESTAMP,
      createdAt: _database.ServerValue.TIMESTAMP,
      createdBy: (0, _members.denormalizeMember)(member)
    });
  }), (0, _operators.switchMap)(function () {
    return (0, _utils.fromEvent)(ref, "value", function (snap) {
      return snap.val();
    }).pipe((0, _operators.first)());
  }));
};

exports.default = createPlace;