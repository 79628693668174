// @flow
import * as React from "react";
import styled from "styled-components";
import { range } from "ramda";
import { grey } from "material-ui/colors";

type Props = {|
  lines?: number,
  size?: ?number,
|};

const defaultProps = {
  lines: 1,
  size: null,
};

const Line = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  height: 16px;
  margin-top: 4px;
  margin-bottom: 4px;
  background-color: ${grey[300]};
  border-radius: 4px;
`;

const Container = styled.div`
  display: inline-flex;
  width: ${props => (props.size ? `${props.size}px` : "100%")};
  flex: 1;
  flex-direction: column;
  margin-top: -4px;
  margin-bottom: -4px;
`;

const Text = ({ lines, size }: Props) => (
  <Container size={size}>
    {range(0, lines).map(i => <Line key={`line-${i}`} />)}
  </Container>
);

Text.defaultProps = defaultProps;

export default Text;
