"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _firebaseAutoIds = require("firebase-auto-ids");

var _firebaseAutoIds2 = _interopRequireDefault(_firebaseAutoIds);

var _database = require("firebase/database");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var createApplication = function createApplication(firebase, values, language) {
  var id = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : (0, _firebaseAutoIds2.default)(Date.now());
  return firebase.database().ref("/applications/" + id).set(_extends({
    id: id
  }, values, {
    language: language,
    updatedAt: _database.ServerValue.TIMESTAMP,
    createdAt: _database.ServerValue.TIMESTAMP
  }));
};

exports.default = createApplication;