// @flow
import * as React from "react";
import { I18n } from "react-i18next";
import { MobileStepper } from "material-ui";
import {
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
} from "material-ui-icons";
import Button from "./Button";

type Props = {
  steps: number,
  activeStep: number,
  nextDisabled: boolean,
  onBack: () => void,
  onNext: () => void,
};

const defaultProps = {
  nextDisabled: false,
};

class WizardStepper extends React.Component<Props> {
  static defaultProps = defaultProps;

  handleBack = () => {
    this.props.onBack();
  };

  handleNext = () => {
    this.props.onNext();
  };

  render() {
    const { steps, activeStep, nextDisabled } = this.props;

    return (
      <I18n>
        {t => (
          <MobileStepper
            variant="dots"
            position="static"
            steps={steps}
            activeStep={activeStep}
            backButton={
              <Button
                size="small"
                disabled={activeStep === 0}
                onClick={this.handleBack}
                icon={ChevronLeftIcon}
              >
                {t("general.wizard.back")}
              </Button>
            }
            nextButton={
              <Button
                disabled={nextDisabled}
                size="small"
                onClick={this.handleNext}
                rightIcon={ChevronRightIcon}
              >
                {t("general.wizard.next")}
              </Button>
            }
          />
        )}
      </I18n>
    );
  }
}

export default WizardStepper;
