"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getListEventsFromRef = exports.filterListEvents = exports.CHANGED_EVENT = exports.MOVED_EVENT = exports.REMOVED_EVENT = exports.ADDED_EVENT = exports.VALUE_EVENT = undefined;

var _ramda = require("ramda");

var _operators = require("rxjs/operators");

var _merge = require("rxjs/observable/merge");

var _utils = require("./utils");

var VALUE_EVENT = exports.VALUE_EVENT = "VALUE_EVENT";
var ADDED_EVENT = exports.ADDED_EVENT = "ADDED_EVENT";
var REMOVED_EVENT = exports.REMOVED_EVENT = "REMOVED_EVENT";
var MOVED_EVENT = exports.MOVED_EVENT = "MOVED_EVENT";
var CHANGED_EVENT = exports.CHANGED_EVENT = "CHANGED_EVENT";

// Filter list events by type
var filterListEvents = exports.filterListEvents = function filterListEvents(type) {
  return function (event$
  // $FlowFixMe
  ) {
    return event$.pipe((0, _operators.filter)(function (event) {
      return event.type === type;
    }));
  };
};

// Extract ordered values from data snapshot
var getOrderedValues = function getOrderedValues(selector) {
  return function (collection) {
    var result = [];

    collection.forEach(function (item) {
      result.push(selector(item));

      // Return false to keep going until the end of the list
      return false;
    });

    return result;
  };
};

// Create Observable of list events from ref
var getListEventsFromRef = exports.getListEventsFromRef = function getListEventsFromRef(selector, ref) {
  return (0, _merge.merge)((0, _utils.fromEvent)(ref, "value", (0, _ramda.pipe)(getOrderedValues(selector), function (entities) {
    return {
      type: VALUE_EVENT,
      entities: entities
    };
  })).pipe((0, _operators.first)()), (0, _utils.fromEvent)(ref, "child_added", function (snap, after) {
    return {
      type: ADDED_EVENT,
      entity: selector(snap),
      after: after
    };
  }), (0, _utils.fromEvent)(ref, "child_removed", function (snap) {
    return {
      type: REMOVED_EVENT,
      entity: selector(snap)
    };
  }), (0, _utils.fromEvent)(ref, "child_moved", function (snap, after) {
    return {
      type: MOVED_EVENT,
      entity: selector(snap),
      after: after
    };
  }), (0, _utils.fromEvent)(ref, "child_changed", function (snap) {
    return {
      type: CHANGED_EVENT,
      entity: selector(snap)
    };
  }));
};