// @flow
import * as React from "react";
import styled from "styled-components";
import Typography from "material-ui/Typography";
import { grey } from "material-ui/colors";

type Props = {
  figure: React.Node,
  subheading: React.Node,
  caption: React.Node,
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 240px;
  background-color: ${grey[50]};
`;

const FigureContainer = styled.div`
  display: inline-flex;
  margin-bottom: 16px;
`;

const Banner = ({ figure, subheading, caption, ...rest }: Props) => (
  <Container {...rest}>
    {figure && <FigureContainer>{figure}</FigureContainer>}
    {subheading && (
      <Typography
        gutterBottom
        type="subheading"
        align="center"
        style={{ width: "100%" }}
      >
        {subheading}
      </Typography>
    )}
    {caption && (
      <Typography
        gutterBottom
        type="caption"
        align="center"
        style={{ width: "100%" }}
      >
        {caption}
      </Typography>
    )}
  </Container>
);

export default Banner;
