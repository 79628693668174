"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _utils = require("../utils");

var getApplication = function getApplication(firebase, applicationId) {
  return (0, _utils.fromEvent)(firebase.database().ref("/applications/" + applicationId), "value", function (snap) {
    return snap.val();
  });
};

exports.default = getApplication;