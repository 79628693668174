// @flow
import * as React from "react";
import { Place as PlaceIcon } from "material-ui-icons";
import AspectRatioBox from "./AspectRatioBox";
import Map from "./Map";
import ListItem from "./ListItem";

type Props = {
  address: string,
  coordinates: {|
    latitude: number,
    longitude: number,
  |},
};

/**
 * Displays static map and address summary
 */
const AddressBlock = ({ address, coordinates }: Props) => (
  <React.Fragment>
    <AspectRatioBox ratio={16 / 9}>
      <Map
        interactive={false}
        marker={[coordinates.latitude, coordinates.longitude]}
      />
    </AspectRatioBox>
    <ListItem icon={<PlaceIcon />} label={address} />
  </React.Fragment>
);

export default AddressBlock;
