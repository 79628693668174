// @flow
import * as React from "react";
import { translate, type TranslatorProps } from "react-i18next";
import ReportProblemIcon from "material-ui-icons/ReportProblem";
import { ErrorLayout } from "../components";

type Props = {
  ...$Exact<TranslatorProps>,
};

const enhance = translate();

const ErrorScreen = ({ t }: Props) => (
  <ErrorLayout
    icon={ReportProblemIcon}
    subheading={t("errors.generic")}
    caption={t("errors.generic_sub")}
  />
);

export default enhance(ErrorScreen);
