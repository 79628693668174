// @flow
export const BREAKPOINT_SMALL = 600;
export const DEFAULT_ROW_HEIGHT = 68;

export const MAX_LENGTH_FULL_NAME = 100;
export const MAX_LENGTH_ORGANIZATION_NAME = 100;
export const MAX_LENGTH_EMAIL = 255;
export const MAX_LENGTH_PLACE_NAME = 60;
export const MAX_LENGTH_PHONE_NUMBER = 20;
export const MAX_LENGTH_URL = 255;
export const MAX_LENGTH_TEXT = 600;

export const NOTIFICATION_DURATION_SHORT = 2000;
export const NOTIFICATION_DURATION_LONG = 5000;

export const DEFAULT_MAP_CENTER = [55.7468526, 37.6858247];
export const DEFAULT_MAP_ZOOM = 17;
