"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CRITERIA_ACTIVE = exports.CRITERIA_ALL = undefined;

var _listEvents = require("../listEvents");

var _constants = require("../constants");

var CRITERIA_ALL = exports.CRITERIA_ALL = "all";
var CRITERIA_ACTIVE = exports.CRITERIA_ACTIVE = "active";

var getInvites = function getInvites(firebase, criteria) {
  var ref = firebase.database().ref("/invitesPending").orderByChild("createdAt");

  if (criteria === CRITERIA_ACTIVE) {
    ref = ref.startAt(Date.now() - _constants.INVITE_EXPIRATION_DAYS * 24 * 3600 * 1000);
  }

  return (0, _listEvents.getListEventsFromRef)(function (snap) {
    return snap.val();
  }, ref);
};

exports.default = getInvites;