"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateMember = undefined;

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _fromPromise = require("rxjs/observable/fromPromise");

var _database = require("firebase/database");

var updateMember = exports.updateMember = function updateMember(firebase, memberId, updates) {
  return (0, _fromPromise.fromPromise)(firebase.database().ref("/members/" + memberId).update(_extends({}, updates, {
    updatedAt: _database.ServerValue.TIMESTAMP
  })));
};

exports.default = updateMember;