// @flow
import * as React from "react";
import styled from "styled-components";
import { range } from "ramda";
import ListItem from "./ListItem";

type Props = {|
  items: number,
  avatar: boolean,
  lines: number,
|};

const defaultProps = {
  items: 8,
  avatar: true,
  lines: 2,
};

const Wrapper = styled.div`
  flex: 1;
  overflow: hidden;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const List = ({ avatar, items, lines }: Props) => (
  <Wrapper>
    <Container>
      {range(0, items).map(i => (
        <ListItem key={`item-${i}`} avatar={avatar} lines={lines} />
      ))}
    </Container>
  </Wrapper>
);

List.defaultProps = defaultProps;

export default List;
