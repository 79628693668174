// @flow
import * as React from "react";
import { sortableHandle } from "react-sortable-hoc";

const enhance = sortableHandle;

const SortableHandle = ({ children }: { children: React.Node }) =>
  React.Children.only(children);

export default enhance(SortableHandle);
