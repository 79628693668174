// @flow
import * as React from "react";
import styled from "styled-components";
import { branch, compose, renderComponent } from "recompose";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { I18n } from "react-i18next";
import { Typography } from "material-ui";
import { blue } from "material-ui/colors";
import { QRCode } from "react-qr-svg";
import { inviteHidden, inviteSetAdmin, inviteShown } from "../actions";
import { makeGetInvite, makeIsInviteLoaded } from "../selectors";
import {
  getInviteExpiresAt,
  getInviteUrl,
  isInviteAdmin,
  isInviteExpired,
  isInvitePending,
  type Invite,
  type InviteId,
} from "../models";
import {
  ConfirmationDialog,
  Layout,
  LoadingPlaceholder,
  Switch,
} from "../components";
import { withSideEffects } from "../hocs";
import DateFns from "../components/DateFns";

type Props = {
  id: InviteId,
  loaded: boolean,
  invite: Invite,
  inviteSetAdmin: (invite: Invite, isAdmin: boolean) => void,
};

type State = {
  pendingAdmin: boolean,
};

const QRCodeContainer = styled.div`
  margin: 0 24px;
`;

const Link = styled.a`
  display: block;
  overflow: hidden;
  max-width: 100%;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 0.875rem;
  color: ${blue[400]};
`;

const makeMapStateToProps = () =>
  createStructuredSelector({
    loaded: makeIsInviteLoaded(),
    invite: makeGetInvite(),
  });

const enhance = compose(
  connect(
    makeMapStateToProps,
    { inviteShown, inviteHidden, inviteSetAdmin }
  ),
  withSideEffects({
    onMount: ({ id, inviteShown }) => inviteShown(id),
    onUnmount: ({ id, inviteHidden }) => inviteHidden(id),
  }),
  branch(({ loaded }) => !loaded, renderComponent(LoadingPlaceholder))
);

class InviteDetailsContainer extends React.Component<Props, State> {
  state = {
    pendingAdmin: false,
  };

  handleAdminChange = (e: SyntheticEvent<any>, isChecked) => {
    if (isChecked) {
      this.setState({ pendingAdmin: true });
    } else {
      this.props.inviteSetAdmin(this.props.invite, false);
    }
  };

  handleAdminConfirm = () => {
    this.setState({ pendingAdmin: false });

    this.props.inviteSetAdmin(this.props.invite, true);
  };

  handleAdminCancel = () => {
    this.setState({ pendingAdmin: false });
  };

  renderPendingInvite() {
    const { invite } = this.props;

    const inviteUrl = getInviteUrl(
      invite,
      Object.assign(new URL(window.location), { pathname: "" }).toString()
    );

    return (
      <I18n>
        {t => (
          <DateFns>
            {d => (
              <React.Fragment>
                <Layout.Content>
                  <Layout.Block>
                    <Switch
                      data-cy="admin-switch"
                      name="admin"
                      label={t("invites.details.admin")}
                      checked={isInviteAdmin(invite)}
                      onChange={this.handleAdminChange}
                    />
                  </Layout.Block>
                  <Layout.Block>
                    <Typography align="center">
                      {t("invites.details.expires_in", {
                        time: d.formatDistance(
                          getInviteExpiresAt(invite),
                          Date.now()
                        ),
                      })}
                    </Typography>
                  </Layout.Block>
                  <Layout.Block>
                    <QRCodeContainer>
                      <QRCode value={inviteUrl} />
                    </QRCodeContainer>
                  </Layout.Block>
                  <Layout.Block>
                    <Link data-cy="url-contents" href={inviteUrl}>
                      {inviteUrl}
                    </Link>
                  </Layout.Block>
                </Layout.Content>
                <ConfirmationDialog
                  open={this.state.pendingAdmin}
                  title={t("invites.details.admin_confirmation_title")}
                  text={t("invites.details.admin_confirmation_text")}
                  onConfirm={this.handleAdminConfirm}
                  onClose={this.handleAdminCancel}
                />
              </React.Fragment>
            )}
          </DateFns>
        )}
      </I18n>
    );
  }

  render() {
    const { invite } = this.props;

    if (!isInvitePending(invite)) {
      return (
        <I18n>
          {t => <Typography>{t("invites.details.claimed")}</Typography>}
        </I18n>
      );
    }

    if (isInviteExpired(invite)) {
      return (
        <I18n>
          {t => <Typography>{t("invites.details.expired")}</Typography>}
        </I18n>
      );
    }

    return this.renderPendingInvite();
  }
}

export default enhance(InviteDetailsContainer);
