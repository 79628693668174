// @flow
import * as React from "react";
import styled from "styled-components";

type Props = {
  ratio: number,
  children: React.Node,
};

const Wrapper = styled.div`
  position: relative;
  width: 100%;

  &:before {
    content: "";
    display: block;
    padding-top: ${props => 100 / props.ratio}%;
  }
`;

const Container = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

const AspectRatioBox = ({ ratio, children }: Props) => (
  <Wrapper ratio={ratio}>
    <Container>{children}</Container>
  </Wrapper>
);

export default AspectRatioBox;
