// @flow
import * as React from "react";
import BaseDropzone from "react-dropzone";

type RenderProps = {
  open: () => void,
};

type Props = {
  children: (props: RenderProps) => React.Node,
  onUpload: ?(files: File[]) => void,
};

class Dropzone extends React.Component<Props> {
  dropzone: React.ElementRef<typeof BaseDropzone>;

  handleDrop = (files: File[]) => {
    if (this.props.onUpload) {
      this.props.onUpload(files);
    }
  };

  handleOpen = () => {
    if (this.dropzone) {
      this.dropzone.open();
    }
  };

  handleRef = (dropzone: React.ElementRef<typeof BaseDropzone>) => {
    this.dropzone = dropzone;
  };

  render() {
    const { children, onUpload, ...rest } = this.props;

    return (
      <BaseDropzone
        {...rest}
        style={{}}
        onDrop={this.handleDrop}
        ref={this.handleRef}
      >
        {children({ open: this.handleOpen })}
      </BaseDropzone>
    );
  }
}

export default Dropzone;
