// @flow
import { Record, type RecordOf } from "immutable";
import { NOTIFICATION_DURATION_SHORT } from "../constants";

export type NotificationFields = {|
  message: string,
  args: Object,
  duration: number,
|};

export type Notification = RecordOf<NotificationFields>;

const makeNotification = Record(
  {
    message: "",
    args: {},
    duration: NOTIFICATION_DURATION_SHORT,
  },
  "Notification"
);

export default makeNotification;
