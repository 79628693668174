// @flow
import * as React from "react";
import styled from "styled-components";
import { compose } from "recompose";
import { green, red, blueGrey } from "material-ui/colors";
import { translate, type TranslatorProps } from "react-i18next";
import { withStyles } from "material-ui/styles";
import Typography from "material-ui/Typography";
import ScheduleIcon from "material-ui-icons/Schedule";

type Props = {
  ...$Exact<TranslatorProps>,
  classes: Object,
  resolved: boolean,
  approved: boolean,
};

const styles = {
  pending: {
    color: blueGrey["200"],
  },
  approved: {
    color: green["500"],
  },
  rejected: {
    color: red.A400,
  },
};

const StyledScheduleIcon = styled(ScheduleIcon)`
  display: inline-block;
  vertical-align: middle;
  width: 24px;
  height: 24px;
  margin-right: 0.35em;
`;

const Text = styled.span`
  vertical-align: middle;
`;

const enhance = compose(
  withStyles(styles),
  translate()
);

const Status = ({ t, classes, resolved, approved, ...rest }: Props) => {
  if (!resolved) {
    return (
      <Typography {...rest} className={classes.pending}>
        <StyledScheduleIcon />
        <Text>{t("signup.status.pending")}</Text>
      </Typography>
    );
  }

  if (!approved) {
    return (
      <Typography {...rest} className={classes.rejected}>
        <Text>{t("signup.status.rejected")}</Text>
      </Typography>
    );
  }

  return (
    <Typography {...rest} className={classes.approved}>
      <Text>{t("signup.status.approved")}</Text>
    </Typography>
  );
};

export default enhance(Status);
