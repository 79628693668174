import { createMuiTheme } from "material-ui/styles";
import { indigo, pink } from "material-ui/colors";

const muiTheme = createMuiTheme({
  palette: {
    primary: indigo,
    secondary: pink,
  },
});

export default muiTheme;
