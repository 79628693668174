const createReducer = (initialState, handlers) => (
  state = initialState,
  action
) => {
  switch (typeof handlers[action.type]) {
    case "undefined":
      return state;
    case "function":
      return handlers[action.type](state, action);
    default:
      return handlers[action.type];
  }
};

export default createReducer;
