// @flow
import * as React from "react";
import styled, { css } from "styled-components";
import { Route } from "react-router";
import {
  ListItem as BaseListItem,
  ListItemIcon,
  ListItemText,
} from "material-ui";

type Props = {
  ...$Exact<React.ElementProps<typeof BaseListItem>>,
  href?: ?string,
  link?: ?string,
  icon?: ?React.Node,
  avatar?: ?React.Node,
  label?: ?React.Node,
  secondaryText?: ?React.Node,
  children?: ?React.Node,
};

const defaultProps = {
  href: null,
  link: null,
  icon: null,
  avatar: null,
  label: null,
  secondaryText: null,
  children: null,
};

/**
 * Container will used for ListItems which reuqire custom layout
 */
const borderBottomMixin = css`
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);

  &:last-child {
    border-bottom: none;
  }
`;

const Container = styled(({ borderBottom, ...rest }) => <div {...rest} />)`
  padding: 8px 16px;
  box-sizing: border-box;

  ${props => (props.borderBottom ? borderBottomMixin : "")};
`;

const StyledListItem = styled(({ borderBottom, ...rest }) => (
  <BaseListItem {...rest} />
))`
  ${props => (props.borderBottom ? borderBottomMixin : "")};
`;

/**
 * When `children` is provided, custom layout will be used.
 *
 * Custom layout may be useful for lists such as Application List.
 * Otherwise material-ui's list will be used.
 */
const ListItem = (props: Props) => {
  // Shortcut to make list item a link for internal pages
  if (props.link) {
    const { link, ...rest } = props;

    return (
      <Route>
        {({ history }) =>
          ListItem({
            onClick: () => history.push(link),
            ...rest,
          })
        }
      </Route>
    );
  }

  // Shortcut to make list item a link for external pages
  if (props.href) {
    const { href, ...rest } = props;

    return ListItem({
      ...rest,
      component: props => (
        // eslint-disable-next-line jsx-a11y/anchor-has-content
        <a href={href} {...props} />
      ),
    });
  }

  const { link, icon, avatar, label, secondaryText, children, ...rest } = props;

  // Custom container for list items that require custom layout
  if (children) {
    return <Container {...rest}>{children}</Container>;
  }

  // Material-UI's list item with convenient props
  return (
    <StyledListItem button {...rest}>
      {avatar || null}
      {icon && <ListItemIcon>{icon}</ListItemIcon>}
      <ListItemText primary={label} secondary={secondaryText} />
    </StyledListItem>
  );
};

ListItem.defaultProps = defaultProps;

export default ListItem;
