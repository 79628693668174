"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _auth = require("./auth");

Object.defineProperty(exports, "getAuthenticatedUser", {
  enumerable: true,
  get: function get() {
    return _auth.getAuthenticatedUser;
  }
});
Object.defineProperty(exports, "logout", {
  enumerable: true,
  get: function get() {
    return _auth.logout;
  }
});

var _members = require("./members");

Object.defineProperty(exports, "getMember", {
  enumerable: true,
  get: function get() {
    return _members.getMember;
  }
});
Object.defineProperty(exports, "getMembers", {
  enumerable: true,
  get: function get() {
    return _members.getMembers;
  }
});
Object.defineProperty(exports, "getCurrentMember", {
  enumerable: true,
  get: function get() {
    return _members.getCurrentMember;
  }
});
Object.defineProperty(exports, "getMemberPlaces", {
  enumerable: true,
  get: function get() {
    return _members.getMemberPlaces;
  }
});
Object.defineProperty(exports, "updateMember", {
  enumerable: true,
  get: function get() {
    return _members.updateMember;
  }
});
Object.defineProperty(exports, "suspendMember", {
  enumerable: true,
  get: function get() {
    return _members.suspendMember;
  }
});

var _applications = require("./applications");

Object.defineProperty(exports, "getApplication", {
  enumerable: true,
  get: function get() {
    return _applications.getApplication;
  }
});
Object.defineProperty(exports, "approveApplication", {
  enumerable: true,
  get: function get() {
    return _applications.approveApplication;
  }
});
Object.defineProperty(exports, "rejectApplication", {
  enumerable: true,
  get: function get() {
    return _applications.rejectApplication;
  }
});
Object.defineProperty(exports, "getApplications", {
  enumerable: true,
  get: function get() {
    return _applications.getApplications;
  }
});
Object.defineProperty(exports, "createApplication", {
  enumerable: true,
  get: function get() {
    return _applications.createApplication;
  }
});
Object.defineProperty(exports, "updateApplication", {
  enumerable: true,
  get: function get() {
    return _applications.updateApplication;
  }
});

var _invites = require("./invites");

Object.defineProperty(exports, "getInvite", {
  enumerable: true,
  get: function get() {
    return _invites.getInvite;
  }
});
Object.defineProperty(exports, "acceptInvite", {
  enumerable: true,
  get: function get() {
    return _invites.acceptInvite;
  }
});
Object.defineProperty(exports, "getInvites", {
  enumerable: true,
  get: function get() {
    return _invites.getInvites;
  }
});
Object.defineProperty(exports, "createInvite", {
  enumerable: true,
  get: function get() {
    return _invites.createInvite;
  }
});
Object.defineProperty(exports, "setInviteAdmin", {
  enumerable: true,
  get: function get() {
    return _invites.setInviteAdmin;
  }
});

var _places = require("./places");

Object.defineProperty(exports, "getPlace", {
  enumerable: true,
  get: function get() {
    return _places.getPlace;
  }
});
Object.defineProperty(exports, "getPlaces", {
  enumerable: true,
  get: function get() {
    return _places.getPlaces;
  }
});
Object.defineProperty(exports, "uploadPlacePhoto", {
  enumerable: true,
  get: function get() {
    return _places.uploadPlacePhoto;
  }
});
Object.defineProperty(exports, "updatePlace", {
  enumerable: true,
  get: function get() {
    return _places.updatePlace;
  }
});
Object.defineProperty(exports, "createPlace", {
  enumerable: true,
  get: function get() {
    return _places.createPlace;
  }
});

exports.default = function (firebase) {
  return firebase;
};