// @flow
import invariant from "invariant";
import * as React from "react";
import { compose, branch, renderComponent } from "recompose";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { translate, type TranslatorProps } from "react-i18next";
import { Typography } from "material-ui";
import { red, grey } from "material-ui/colors";
import {
  Schedule as ScheduleIcon,
  ThumbUp as ThumbUpIcon,
} from "material-ui-icons";
import {
  Layout,
  Button,
  LoadingPlaceholder,
  ApplicationForm,
} from "../components";
import {
  userApplicationSubmitted,
  userApplicationShown,
  userApplicationHidden,
} from "../actions";
import {
  makeGetUserApplicationId,
  makeIsUserApplicationLoaded,
  makeGetUserApplication,
  makeIsUserApplicationSubmitting,
} from "../selectors";
import { withSideEffects } from "../hocs";
import {
  isApplicationResolved,
  isApplicationApproved,
  type Application,
  type ApplicationFields,
} from "../models";

type Props = {
  ...$Exact<TranslatorProps>,
  loaded: boolean,
  application: ?Application,
  submitting: boolean,
  userApplicationSubmitted: (
    values: ApplicationFields,
    langauge: string,
    existingApplication?: ?Application
  ) => void,
};

type State = {
  showForm: boolean,
};

const mapStateToProps = () =>
  createStructuredSelector({
    id: makeGetUserApplicationId(),
    application: makeGetUserApplication(),
    loaded: makeIsUserApplicationLoaded(),
    submitting: makeIsUserApplicationSubmitting(),
  });

const enhance = compose(
  translate(),
  connect(
    mapStateToProps,
    {
      userApplicationSubmitted,
      userApplicationShown,
      userApplicationHidden,
    }
  ),
  withSideEffects({
    onMount: props => props.id && props.userApplicationShown(props.id),
    onUpdate: (props, prevProps) => {
      if (props.id === prevProps.id) {
        return;
      }

      if (props.id) {
        props.userApplicationShown(props.id);
      } else {
        props.userApplicationHidden(prevProps.id);
      }
    },
    onUnmount: props => props.id && props.userApplicationHidden(props.id),
  }),
  branch(
    ({ id, loaded }) => (id === null ? false : !loaded),
    // $FlowFixMe:
    renderComponent(LoadingPlaceholder)
  )
);

class SignupContainer extends React.Component<Props, State> {
  state = {
    showForm: false,
  };

  handleFormSubmit = (values: ApplicationFields) => {
    const { i18n, application, userApplicationSubmitted } = this.props;
    const resolved = application ? isApplicationResolved(application) : false;

    this.setState({ showForm: false });

    userApplicationSubmitted(
      values,
      i18n.language,
      resolved ? null : application
    );
  };

  renderForm() {
    const { t, application, submitting } = this.props;
    const resolved = application ? isApplicationResolved(application) : false;

    return (
      <ApplicationForm
        application={resolved ? null : application}
        submitLabel={
          application && !resolved
            ? t("applications.signup.update_action")
            : t("applications.signup.create_action")
        }
        onSubmit={this.handleFormSubmit}
        submitting={submitting}
      />
    );
  }

  renderPendingApplication() {
    const { t } = this.props;

    return (
      <Layout.Content style={{ alignItems: "center" }}>
        <ScheduleIcon style={{ width: 60, height: 60, marginBottom: 16 }} />
        <Typography gutterBottom variant="subheading" align="center">
          {t("applications.signup.pending_subheading")}
        </Typography>
        <Typography gutterBottom align="center">
          {t("applications.signup.pending_text")}
        </Typography>
        <Button raised onClick={() => this.setState({ showForm: true })}>
          {t("applications.signup.review_update_action")}
        </Button>
      </Layout.Content>
    );
  }

  renderApprovedApplication() {
    const { t, application } = this.props;

    invariant(application, "Application must be defined");

    return (
      <Layout.Content style={{ alignItems: "center" }}>
        <ThumbUpIcon style={{ width: 60, height: 60, marginBottom: 16 }} />
        <Typography variant="subheading" gutterBottom align="center">
          {t("applications.signup.approved_subheading")}
        </Typography>
        <Typography gutterBottom align="center">
          {t("applications.signup.approved_text", {
            email: application.email,
          })}
        </Typography>
      </Layout.Content>
    );
  }

  renderRejectedApplication() {
    const { t, application } = this.props;

    invariant(application, "Application must be defined");

    return (
      <React.Fragment>
        <Layout.Content style={{ alignItems: "center" }}>
          <Layout.Block>
            <Typography
              variant="subheading"
              gutterBottom
              align="center"
              style={{ color: red[300] }}
            >
              {t("applications.signup.rejected_subheading")}
            </Typography>
            <Typography gutterBottom align="center">
              {t("applications.signup.rejected_text")}
            </Typography>
          </Layout.Block>
        </Layout.Content>
        <Typography
          style={{
            margin: 8,
            padding: 8,
            backgroundColor: grey[50],
            color: grey[600],
          }}
        >
          {application.getIn(["resolution", "comment"])}
        </Typography>
        <Layout.Content style={{ alignItems: "center" }}>
          <Layout.Block>
            <Button
              raised
              color="primary"
              onClick={() => this.setState({ showForm: true })}
            >
              {t("applications.signup.review_create_action")}
            </Button>
          </Layout.Block>
        </Layout.Content>
      </React.Fragment>
    );
  }

  render() {
    const { showForm } = this.state;
    const { application, submitting } = this.props;

    if (!application || showForm || submitting) {
      return this.renderForm();
    }

    if (!isApplicationResolved(application)) {
      return this.renderPendingApplication();
    }

    if (isApplicationApproved(application)) {
      return this.renderApprovedApplication();
    }

    return this.renderRejectedApplication();
  }
}

export default enhance(SignupContainer);
