// @flow
import * as React from "react";
import { translate, type TranslatorProps } from "react-i18next";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "material-ui";
import Button from "./Button";
import type { Application } from "../models";

type Props = {
  ...$Exact<TranslatorProps>,
  open: boolean,
  application: ?Application,
  onClose: () => void,
  onConfirm: () => void,
};

const enhance = translate();

const ApplicationApproveDialog = ({
  t,
  open,
  application,
  onClose,
  onConfirm,
}: Props) => (
  <Dialog open={!!open} onClose={onClose}>
    <DialogTitle>{t("applications.list.approve_dialog_title")}</DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        {application &&
          t("applications.list.approve_dialog_content", {
            fullName: application.fullName,
          })}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose} color="primary">
        {t("applications.list.approve_dialog_cancel_action")}
      </Button>
      <Button onClick={onConfirm} color="primary">
        {t("applications.list.approve_dialog_confirm_action")}
      </Button>
    </DialogActions>
  </Dialog>
);

export default enhance(ApplicationApproveDialog);
