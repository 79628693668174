// @flow
// TODO: Improve flow types
import { Map, List } from "immutable";
import type { Reducer } from "react-redux";
import {
  INVITE_LIST_SHOWN,
  INVITE_LIST_HIDDEN,
  INVITE_LIST_LOADED,
  INVITE_LIST_INVITE_ADDED,
  INVITE_LIST_INVITE_MOVED,
  INVITE_LIST_INVITE_REMOVED,
  INVITE_LIST_INVITE_CHANGED,
  INVITE_SHOWN,
  INVITE_HIDDEN,
  INVITE_LOADED,
  INVITE_CREATE_RESULT,
  ACCEPT_INVITE,
  ACCEPT_INVITE_RESULT,
  type Action,
} from "../actions";
import type { InviteId, Invite } from "../models";
import {
  combineReducers,
  insertAfterInList,
  moveAfterInList,
  removeFromList,
} from "./utils";

export type State = {|
  byId: Map<InviteId, Invite>,
  loadedById: Map<InviteId, boolean>,
  loading: Map<InviteId, boolean>,
  listIds: List<InviteId>,
  listLoaded: boolean,
  listLoading: boolean,
  accepting: boolean,
|};

const invitesReducer: Reducer<State, Action> = combineReducers({
  byId: (state = Map(), action) => {
    switch (action.type) {
      case INVITE_LOADED:
      case INVITE_CREATE_RESULT:
      case INVITE_LIST_INVITE_ADDED:
      case INVITE_LIST_INVITE_CHANGED: {
        if (action.error) {
          return state;
        }

        const { id, invite } = action.payload;

        return state.set(id, invite);
      }

      case INVITE_LIST_LOADED: {
        const { invites } = action.payload;

        return state.merge(invites);
      }

      default:
        return state;
    }
  },
  loadedById: (state = Map(), action) => {
    switch (action.type) {
      case INVITE_LOADED:
      case INVITE_CREATE_RESULT: {
        if (action.error) {
          return state;
        }

        const { id } = action.payload;

        return state.set(id, true);
      }

      default:
        return state;
    }
  },
  loading: (state = Map(), action) => {
    switch (action.type) {
      case INVITE_SHOWN: {
        const { id } = action.payload;

        return state.set(id, true);
      }

      case INVITE_HIDDEN: {
        const { id } = action.payload;

        return state.set(id, false);
      }

      case INVITE_LOADED: {
        const { id } = action.payload;

        return state.set(id, false);
      }

      default:
        return state;
    }
  },
  listIds: (state = List(), action) => {
    switch (action.type) {
      case INVITE_LIST_LOADED: {
        if (action.error) {
          return state;
        }

        const { ids } = action.payload;

        return ids;
      }

      case INVITE_LIST_INVITE_ADDED: {
        const { invite, after } = action.payload;

        return insertAfterInList(state, invite.id, after);
      }

      case INVITE_LIST_INVITE_MOVED: {
        const { invite, after } = action.payload;

        return moveAfterInList(state, invite.id, after);
      }

      case INVITE_LIST_INVITE_REMOVED: {
        const { invite } = action.payload;

        return removeFromList(state, invite.id);
      }

      default:
        return state;
    }
  },
  listLoaded: (state = false, action) => {
    switch (action.type) {
      case INVITE_LIST_LOADED:
        return true;

      default:
        return state;
    }
  },
  listLoading: (state = false, action) => {
    switch (action.type) {
      case INVITE_LIST_SHOWN:
        return true;

      case INVITE_LIST_LOADED:
      case INVITE_LIST_HIDDEN:
        return false;

      default:
        return state;
    }
  },
  accepting: (state = false, action) => {
    switch (action.type) {
      case ACCEPT_INVITE:
        return true;
      case ACCEPT_INVITE_RESULT:
        return false;
      default:
        return state;
    }
  },
});

export default invitesReducer;
