// @flow
import * as React from "react";
import { compose } from "recompose";
import { withRouter, type ContextRouter } from "react-router";
import { translate, type TranslatorProps } from "react-i18next";
import {
  PlaceGallery,
  PlacePhotosToolbar,
  FormActionsToolbar,
} from "../components";
import {
  LayoutContainer,
  PlaceContainer,
  PlaceEditContainer,
} from "../containers";
import { formatRoute, PLACE_DETAILS } from "../routes";
import type { PlaceId } from "../models";

type Props = {
  ...$Exact<ContextRouter>,
  ...$Exact<TranslatorProps>,
  id: PlaceId,
};

const enhance = compose(
  withRouter,
  translate()
);

class PlaceEditPhotosScreen extends React.Component<Props> {
  goBack = () => {
    const { id, history } = this.props;

    history.push(formatRoute(PLACE_DETAILS, { id }));
  };

  render() {
    const { t, id } = this.props;

    return (
      <PlaceContainer
        id={id}
        render={() => (
          <PlaceEditContainer
            id={id}
            render={({
              photos,
              photoMove,
              removePhotos,
              uploadPhotos,
              updatePlace,
            }) => (
              <PlaceGallery
                photos={photos}
                onRemovePhotos={removePhotos}
                onMovePhoto={photoMove}
                onUpload={uploadPhotos}
                onSubmit={photos => updatePlace({ photos })}
                render={({
                  uploadingPhotos,
                  selectedPhotos,
                  resetSelectedPhotos,
                  removeSelectedPhotos,
                  onSubmit,
                  children,
                }) => {
                  const hasSelectedPhotos = selectedPhotos.size > 0;

                  return (
                    <LayoutContainer
                      toolbar={
                        <PlacePhotosToolbar
                          title={
                            hasSelectedPhotos
                              ? t("general.photos_selected", {
                                  count: selectedPhotos.size,
                                })
                              : t("places.title.photos")
                          }
                          showUpload={!hasSelectedPhotos}
                          showRemove={hasSelectedPhotos}
                          onGoBack={
                            hasSelectedPhotos
                              ? resetSelectedPhotos
                              : this.goBack
                          }
                          onUpload={uploadPhotos}
                          onRemoveSelected={removeSelectedPhotos}
                        />
                      }
                      footer={
                        <FormActionsToolbar
                          canSubmit={
                            photos.size > 0 && uploadingPhotos.size === 0
                          }
                          onCancel={this.goBack}
                          onSubmit={onSubmit}
                        />
                      }
                    >
                      {children}
                    </LayoutContainer>
                  );
                }}
              />
            )}
          />
        )}
      />
    );
  }
}

export default enhance(PlaceEditPhotosScreen);
