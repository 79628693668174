// @flow
import * as React from "react";
import { compose, branch, renderNothing, renderComponent } from "recompose";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { Redirect } from "react-router";
import {
  makeIsAuthenticationStatusReady,
  makeGetAuthenticatedMember,
} from "../selectors";
import type { Member } from "../models";
import { ROOT } from "../routes";

type Props = {
  allow: (member: ?Member) => boolean,
  children: React.Node,
  ready: boolean,
  member: ?Member,
};

const makeMapStateToProps = () =>
  createStructuredSelector({
    ready: makeIsAuthenticationStatusReady(),
    member: makeGetAuthenticatedMember(),
  });

const enhance = compose(
  connect(
    makeMapStateToProps,
    null
  ),
  branch(({ ready }) => !ready, renderNothing),
  branch(
    props => !props.allow(props.member),
    renderComponent(() => <Redirect to={ROOT} />)
  )
);

/**
 * Container which only renders children when the authenticated user meets role criteria.
 */
const AccessControlContainer = ({ children }: Props) =>
  React.Children.only(children);

export default enhance(AccessControlContainer);
