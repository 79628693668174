// @flow
import * as React from "react";
import { pure } from "recompose";
import styled from "styled-components";
import { Typography, Checkbox } from "material-ui";
import ScheduleTimeField from "./ScheduleTimeField";

type Props = {
  name: string,
  value: null | {| open: number, close: number |},
  label: React.Node,
  onChange: (e: SyntheticEvent<any> & { target: HTMLInputElement }) => void,
};

const Container = styled.div`
  padding: 8px 16px;
  display: flex;
  align-items: center;
`;

const CheckboxContainer = styled.div`
  margin-left: -12px;
`;

const LabelContainer = styled.div`
  flex-shrink: 1;
  overflow: hidden;
  flex-grow: 1;
  padding: 0 16px;
`;

const ControlContainer = styled.div`
  padding-left: 16px;
`;

const enhance = pure;

const ScheduleRow = ({ name, value, label, onChange }: Props) => (
  <Container>
    <CheckboxContainer>
      <Checkbox
        id={`schedule-${name}`}
        name={name}
        checked={!!value}
        onChange={onChange}
      />
    </CheckboxContainer>
    <LabelContainer>
      <Typography
        variant="subheading"
        component={props => (
          // eslint-disable-next-line jsx-a11y/label-has-for
          <label {...props} htmlFor={`schedule-${name}`} />
        )}
      >
        {label}
      </Typography>
    </LabelContainer>
    {value ? (
      <React.Fragment>
        <ControlContainer>
          <ScheduleTimeField
            name={`${name}.open`}
            value={value.open}
            max={value.close}
            onChange={onChange}
          />
        </ControlContainer>
        <ControlContainer>
          <ScheduleTimeField
            name={`${name}.close`}
            value={value.close}
            min={value.open}
            onChange={onChange}
          />
        </ControlContainer>
      </React.Fragment>
    ) : null}
  </Container>
);

export default enhance(ScheduleRow);
