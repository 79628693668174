// @flow
import * as React from "react";
import { I18n } from "react-i18next";
import { MEMBER_LIST } from "../routes";
import { LayoutContainer, InviteDetailsContainer } from "../containers";
import type { InviteId } from "../models";

type Props = {
  id: InviteId,
};

const InviteDetailsScreen = ({ id }: Props) => (
  <LayoutContainer
    title={<I18n>{t => t("menu.members")}</I18n>}
    parentRoute={MEMBER_LIST}
  >
    <InviteDetailsContainer id={id} />
  </LayoutContainer>
);

export default InviteDetailsScreen;
