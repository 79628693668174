"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _utils = require("../utils");

var _auth = require("../auth");

var _tasks = require("../tasks");

var resolveApplication = function resolveApplication(firebase, id, resolution) {
  return (0, _utils.firstToPromise)((0, _auth.getAuthenticatedUser)(firebase)).then(function (user) {
    return user ? (0, _tasks.runTask)(firebase, "resolveApplication", {
      operatorId: user.uid,
      applicationId: id,
      approved: resolution.approved,
      comment: resolution.comment || null
    }) : Promise.reject(new Error("This action requires authentication"));
  });
};

exports.default = resolveApplication;