// @flow
import * as React from "react";
import styled from "styled-components";
import { withGeoPosition } from "react-fns";
import { AutoSizer } from "react-virtualized";
import createReactContext, { type Context } from "create-react-context";
import MapComponent, { type Adapter } from "outnabout-map";
import { DEFAULT_MAP_CENTER, DEFAULT_MAP_ZOOM } from "../constants";

type LatLng = [number, number];

type Props = {
  interactive: boolean,
  coords: { latitude: number, longitude: number },
  marker?: ?LatLng,
  onClick: (latLng: LatLng) => void,
};

const defaultProps = {
  interactive: true,
  marker: null,
};

const MapContext: Context<Adapter> = createReactContext(null);

const MapContainer = styled.div`
  flex: 1;
`;

const defaultCenter = DEFAULT_MAP_CENTER;
const defaultZoom = DEFAULT_MAP_ZOOM;

const enhance = withGeoPosition;

class Map extends React.Component<
  Props,
  { moved: boolean, center: LatLng, zoom: number }
> {
  static defaultProps = defaultProps;
  static Provider = ({ adapter, ...rest }: { adapter: Adapter }) => (
    <MapContext.Provider {...rest} value={adapter} />
  );

  constructor(props) {
    super();

    this.state = {
      moved: false,
      center: defaultCenter,
      zoom: defaultZoom,
    };

    if (props.marker) {
      this.state.center = props.marker;
    } else if (props.coords) {
      const { latitude, longitude } = props.coords;

      this.state.center = [latitude, longitude];
    }
  }

  componentWillReceiveProps(nextProps: Props) {
    const { coords, marker } = nextProps;

    if (marker) {
      if (this.props.marker !== marker) {
        this.setState({ center: marker });
      }
    } else if (coords && this.props.coords !== coords && !this.state.moved) {
      this.setState({ center: [coords.latitude, coords.longitude] });
    }
  }

  handleBoundsChange = (center: LatLng, zoom: number) => {
    if (this.props.interactive) {
      this.setState({ center, zoom });
    }
  };

  render() {
    const { zoom, center } = this.state;
    const { interactive, marker, onClick } = this.props;

    return (
      <MapContainer>
        <AutoSizer>
          {({ width, height }) => (
            <MapContext.Consumer>
              {adapter => {
                if (!adapter) {
                  throw new Error(
                    "Maps Adapter should be provided via context"
                  );
                }

                return (
                  <MapComponent
                    data-cy={interactive ? "map" : "static-map"}
                    adapter={adapter}
                    static={!interactive}
                    width={width}
                    height={height}
                    center={center}
                    zoom={zoom}
                    marker={marker}
                    onClick={onClick}
                    onBoundsChange={this.handleBoundsChange}
                  />
                );
              }}
            </MapContext.Consumer>
          )}
        </AutoSizer>
      </MapContainer>
    );
  }
}

export default enhance(Map);
