// @flow
import * as React from "react";
import { translate, type TranslatorProps } from "react-i18next";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "material-ui";
import Button from "./Button";

type Props = {
  ...$Exact<TranslatorProps>,
  open: boolean,
  title: React.Node,
  text: React.Node,
  onClose: () => void,
  onConfirm: () => void,
};

const enhance = translate();

const ConfirmationDialog = ({
  t,
  open,
  title,
  text,
  onClose,
  onConfirm,
}: Props) => (
  <Dialog open={!!open} onClose={onClose}>
    <DialogTitle>{title}</DialogTitle>
    <DialogContent>
      <DialogContentText>{text}</DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose} color="primary">
        {t("general.confirmation_cancel")}
      </Button>
      <Button onClick={onConfirm} color="primary">
        {t("general.confirmation_confirm")}
      </Button>
    </DialogActions>
  </Dialog>
);

export default enhance(ConfirmationDialog);
