// @flow
import { compose, tap } from "ramda";

const logError = <T: Function>(cb: T): T =>
  compose(
    cb,
    tap(error => {
      if (process.env.NODE_ENV === "development") {
        console.error(error); // eslint-disable-line no-console
      }
    })
  );

export default logError;
