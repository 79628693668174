// @flow
import styled from "styled-components";

/**
 * Provides 16 px padding for text and forms.
 * This should replace Layout.Content everywhere.
 */
const Content = styled.div`
  padding: 16px;
`;

export default Content;
