// @flow
import * as React from "react";
import FileReaderDataUrl from "./FileReaderDataUrl";

type Props = {
  file: File,
};

const FilePhoto = ({ file, ...rest }: Props) => (
  <FileReaderDataUrl file={file}>
    {({ dataUrl }) => (
      // eslint-disable-next-line jsx-a11y/alt-text
      <img {...rest} src={dataUrl} />
    )}
  </FileReaderDataUrl>
);

export default FilePhoto;
