// @flow
import * as React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { drawerOpen, logout } from "../actions";
import { makeIsDrawerOpen, makeGetAuthenticatedMember } from "../selectors";
import { Layout, Navbar } from "../components";
import type { Member } from "../models";

type Props = {
  ...$Exact<React.ElementProps<typeof Layout>>,
  title?: ?React.Node,
  parentRoute?: ?string,
  toolbar?: ?React.Node,
  children: React.Node,
  // Injected props
  isDrawerOpen: boolean,
  member: ?Member,
  drawerOpen: () => void,
  drawerClose: () => void,
  logout: () => void,
};

const defaultProps = {
  title: null,
  parentRoute: null,
  toolbar: null,
};

const makeMapStateToProps = () =>
  createStructuredSelector({
    isDrawerOpen: makeIsDrawerOpen(),
    member: makeGetAuthenticatedMember(),
  });

const enhance = connect(
  makeMapStateToProps,
  {
    drawerOpen,
    logout,
  }
);

const LayoutContainer = ({
  toolbar,
  title,
  parentRoute,
  member,
  children,
  drawerOpen,
  logout,
  ...rest
}: Props) => {
  if (!member) {
    // LayoutContainer is only supposed to be shown to members
    return null;
  }

  return (
    <Layout
      {...rest}
      header={
        toolbar || (
          <Navbar
            title={title}
            parentRoute={parentRoute}
            showMenu={member.isAdmin()}
            onMenuClick={drawerOpen}
          />
        )
      }
      onDrawerOpen={drawerOpen}
    >
      {children}
    </Layout>
  );
};

LayoutContainer.defaultProps = defaultProps;

export default enhance(LayoutContainer);
