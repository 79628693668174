// @flow
import invariant from "invariant";
import * as React from "react";
import { IconButton, Menu } from "material-ui";

type Props = {
  icon: React.Node,
  children: React.Node,
};

type State = {
  open: boolean,
  anchorEl: ?Element,
};

class IconButtonMenu extends React.PureComponent<Props, State> {
  state = {
    open: false,
    anchorEl: null,
  };

  handleClick = (e: SyntheticEvent<*>) => {
    invariant(
      e.currentTarget instanceof HTMLElement,
      "Event target must be instance of HTMLElement"
    );

    this.setState({
      anchorEl: e.currentTarget,
      open: true,
    });
  };

  handleClose = () => {
    this.setState({
      open: false,
    });
  };

  render() {
    const { icon, children, ...rest } = this.props;
    const { anchorEl, open } = this.state;

    const menuItems = React.Children.map(children, el => {
      const onClick = (...args) => {
        if (el.props.onClick) {
          el.props.onClick(...args);
        }

        this.handleClose();
      };

      return React.cloneElement(el, { ...el.props, onClick });
    });

    return (
      <React.Fragment>
        <IconButton {...rest} onClick={this.handleClick}>
          {icon}
        </IconButton>
        <Menu anchorEl={anchorEl} open={open} onClose={this.handleClose}>
          {menuItems}
        </Menu>
      </React.Fragment>
    );
  }
}

export default IconButtonMenu;
