// @flow
import styled from "styled-components";

// Layout.Content must contain one or more Layout.Block elements
const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 16px;
  min-width: 0;
  box-sizing: border-box;
`;

Content.displayName = "Content";

export default Content;
