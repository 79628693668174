import React from "react";
import styled from "styled-components";
import { grey } from "material-ui/colors";
import Circle from "./Circle";
import Text from "./Text";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 240px;
  background-color: ${grey[50]};
`;

const Avatar = styled.div`
  margin: 8px 0;
`;

const Content = styled.div`
  margin: 8px 0;
`;

const Banner = () => (
  <Container>
    <Avatar>
      <Circle size={72} />
    </Avatar>
    <Content>
      <Text lines={2} size={144} />
    </Content>
  </Container>
);

export default Banner;
