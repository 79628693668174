// @flow
import * as React from "react";
import { sortableContainer } from "react-sortable-hoc";

const enhance = sortableContainer;

const SortableContainer = ({ children }: { children: React.Node }) =>
  React.Children.only(children);

export default enhance(SortableContainer);
