// @flow
import * as React from "react";
import styled from "styled-components";
import type { Place } from "../models";
import AspectRatioBox from "./AspectRatioBox";

type Props = {
  place: Place,
  children: React.Node,
};

const Background = styled.img`
  display: block;
  position: absolute;
  object-fit: cover;
  width: 100%;
  height: 100%;
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-photo: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.6),
    rgba(0, 0, 0, 0.3) 30%,
    rgba(0, 0, 0, 0)
  );
`;

const PlaceDetailsHeader = ({ place, children }: Props) => {
  const photo = place.getLeadingPhoto();

  if (!photo) {
    return null;
  }

  return (
    <AspectRatioBox ratio={3 / 2}>
      <Background
        srcSet={`
          ${photo.w300} 300w,
          ${photo.w780} 780w,
          ${photo.w1280} 1280w
        `}
        src={photo.w1280}
      />
      <Overlay />
      {children}
    </AspectRatioBox>
  );
};

export default PlaceDetailsHeader;
