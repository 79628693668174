// @flow
import { Record, List, type RecordOf, type RecordFactory } from "immutable";
import { makeMember, type Member, type MemberFields } from "./MemberModel";

export type PlaceId = string;

/**
 * Place details for storing textual data
 */
export type PlaceDetailsFields = {
  title: string,
  phone: string,
  website: string,
  description: string,
};

export type PlaceDetails = RecordOf<PlaceDetailsFields>;

export const makePlaceDetails: RecordFactory<PlaceDetailsFields> = Record({
  title: "",
  phone: "",
  website: "",
  description: "",
});

/**
 * Place address for stroing textual info about place location
 */
export type PlaceAddressFields = {
  value: string,
  displayName: string,
};

export type PlaceAddress = RecordOf<PlaceAddressFields>;

export const makePlaceAddress: RecordFactory<PlaceAddressFields> = Record({
  value: "",
  displayName: "",
});

/**
 * Place coordinates for storing geographical position of the place
 */
export type PlaceCoordinatesFields = {
  latitude: number,
  longitude: number,
};

export type PlaceCoordinates = RecordOf<PlaceCoordinatesFields>;

export const makePlaceCoordinates: RecordFactory<
  PlaceCoordinatesFields
> = Record({
  latitude: 0,
  longitude: 0,
});

/**
 * Place location info including address and coordinates
 */

export type PlaceLocationFields = {
  address: PlaceAddressFields,
  coordinates: PlaceCoordinatesFields,
};

export class PlaceLocation extends Record(
  ({
    address: makePlaceAddress(),
    coordinates: makePlaceCoordinates(),
  }: {
    address: PlaceAddress,
    coordinates: PlaceCoordinates,
  })
) {
  constructor(values: $Shape<PlaceLocationFields> = {}) {
    super({
      address: makePlaceAddress(values.address),
      coordinates: makePlaceCoordinates(values.coordinates),
    });
  }

  getAddress(): PlaceAddress {
    return this.get("address");
  }

  getCoordinates(): PlaceCoordinates {
    return this.get("coordinates");
  }
}

export const makePlaceLocation = (
  values: $Shape<PlaceLocationFields> = {}
): PlaceLocation => new PlaceLocation(values);

/**
 * Place photo object
 */

export type PlacePhotoFields = {
  location: string,
  w300: string,
  w780: string,
  w1280: string,
};

export type PlacePhoto = RecordOf<PlacePhotoFields>;

export const makePlacePhoto: RecordFactory<PlacePhotoFields> = Record({
  location: "",
  w300: "",
  w780: "",
  w1280: "",
});

/**
 * A schedule entry for specific day
 */
export type PlaceDayScheduleFields = {
  open: number,
  close: number,
};

export type PlaceDaySchedule = RecordOf<PlaceDayScheduleFields>;

export const makePlaceDaySchedule: RecordFactory<
  PlaceDayScheduleFields
> = Record({
  open: 0,
  close: 0,
});

/**
 * A weekly schedule
 */
export type PlaceScheduleFields = {
  mon: ?PlaceDayScheduleFields,
  tue: ?PlaceDayScheduleFields,
  wed: ?PlaceDayScheduleFields,
  thu: ?PlaceDayScheduleFields,
  fri: ?PlaceDayScheduleFields,
  sat: ?PlaceDayScheduleFields,
  sun: ?PlaceDayScheduleFields,
};

// Maybe this should have been a map
export class PlaceSchedule extends Record(
  ({
    mon: null,
    tue: null,
    wed: null,
    thu: null,
    fri: null,
    sat: null,
    sun: null,
  }: {
    mon: ?PlaceDaySchedule,
    tue: ?PlaceDaySchedule,
    wed: ?PlaceDaySchedule,
    thu: ?PlaceDaySchedule,
    fri: ?PlaceDaySchedule,
    sat: ?PlaceDaySchedule,
    sun: ?PlaceDaySchedule,
  })
) {
  constructor(values: $Shape<PlaceScheduleFields> = {}) {
    super({
      mon: values.mon && makePlaceDaySchedule(values.mon),
      tue: values.tue && makePlaceDaySchedule(values.tue),
      wed: values.wed && makePlaceDaySchedule(values.wed),
      thu: values.thu && makePlaceDaySchedule(values.thu),
      fri: values.fri && makePlaceDaySchedule(values.fri),
      sat: values.sat && makePlaceDaySchedule(values.sat),
      sun: values.sun && makePlaceDaySchedule(values.sun),
    });
  }
}

export const makePlaceSchedule = (
  values: $Shape<PlaceScheduleFields> = {}
): PlaceSchedule => new PlaceSchedule(values);

/**
 * A place entry
 */
export type PlaceFields = {
  id: PlaceId,
  published: boolean,
  authorized: boolean,
  details: PlaceDetailsFields,
  location: PlaceLocationFields,
  photos: PlacePhotoFields[],
  schedule: PlaceScheduleFields,
  createdBy: MemberFields,
  createdAt: number,
  updatedAt: number,
};

export class Place extends Record(
  ({
    id: "",
    published: false,
    authorized: false,
    details: makePlaceDetails(),
    location: makePlaceLocation(),
    photos: List(),
    schedule: makePlaceSchedule(),
    createdBy: makeMember(),
    createdAt: 0,
    updatedAt: 0,
  }: {
    id: PlaceId,
    published: boolean,
    authorized: boolean,
    details: PlaceDetails,
    location: PlaceLocation,
    photos: List<PlacePhoto>,
    schedule: PlaceSchedule,
    createdBy: Member,
    createdAt: number,
    updatedAt: number,
  }),
  "Place"
) {
  constructor(values: $Shape<PlaceFields> = {}) {
    const { details, location, photos, schedule, createdBy, ...rest } = values;

    super({
      ...rest,
      details: makePlaceDetails(details),
      location: makePlaceLocation(location),
      photos: List(photos).map(makePlacePhoto),
      schedule: makePlaceSchedule(schedule),
      createdBy: makeMember(createdBy),
    });
  }

  getId() {
    return this.get("id");
  }

  getDetails() {
    return this.get("details");
  }

  getTitle() {
    return this.get("details").get("title");
  }

  getPhone() {
    return this.get("details").get("phone");
  }

  getWebsite() {
    return this.get("details").get("website");
  }

  getDescription() {
    return this.get("details").get("description");
  }

  getAddress() {
    return this.get("location").get("address");
  }

  getDisplayAddress() {
    return this.get("location")
      .get("address")
      .get("displayName");
  }

  getAddressValue() {
    return this.get("location")
      .get("address")
      .get("value");
  }

  getCoordinates() {
    return this.get("location").get("coordinates");
  }

  getPhotos() {
    return this.get("photos");
  }

  getLeadingPhoto() {
    return this.get("photos").first();
  }

  getSchedule() {
    return this.get("schedule");
  }

  getDialUrl(): ?string {
    const phone = this.getPhone();

    if (!phone) {
      return null;
    }

    return `tel://${encodeURIComponent(phone)}`;
  }

  getMapsUrl(): string {
    const address = this.getAddressValue();

    return `https://www.google.com/maps?q=${encodeURIComponent(address)}`;
  }

  getDirectionsUrl(): string {
    const address = this.getAddressValue();

    return `https://www.google.com/maps?saddr=My+Location&daddr=${encodeURIComponent(
      address
    )}`;
  }

  getWebsiteUrl(): ?string {
    const website = this.get("details").get("website", null);

    if (!website) {
      return null;
    }

    if (/^\w+:\/\//.test(website)) {
      return website;
    }

    return `http://${website}`;
  }

  getOwner() {
    return this.get("createdBy");
  }

  getCreatedAt() {
    return this.get("createdAt");
  }

  getUpdatedAt() {
    return this.get("updatedAt");
  }

  isPublished() {
    return this.get("published") && this.get("authorized");
  }

  canPublish() {
    return this.get("authorized");
  }
}

export const makePlace = (values: PlaceFields): Place => new Place(values);
