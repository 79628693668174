import { combineEpics } from "redux-observable";
import {
  groupBy,
  switchMap,
  mergeMap,
  filter,
  catchError,
  map,
} from "rxjs/operators";
import { of, empty, from, merge } from "rxjs";
import { uploadPlacePhoto } from "outnabout-api";
import {
  PLACE_UPLOAD_PHOTOS,
  PLACE_CHANGE_ADDRESS,
  PLACE_CHANGE_COORDINATES,
  PLACE_ADDRESS_SUGGESTION_SELECTED,
  placeUploadProgress,
  placeUploadComplete,
  placeAddressSuggestionsLoaded,
  placeGeocodeResult,
} from "../actions";
import { makeAddress } from "../models";
import { ofType, logError } from "./utils";

const uploadIndividualFile = (api, placeId, photoId, file) =>
  uploadPlacePhoto(api, file).pipe(
    switchMap(snapshot => {
      switch (snapshot.state) {
        case "uploading": {
          const { bytesTransferred, totalBytes } = snapshot;

          return of(
            placeUploadProgress(placeId, photoId, {
              bytesTransferred,
              totalBytes,
            })
          );
        }

        case "complete": {
          const { result } = snapshot;

          return of(placeUploadComplete(placeId, photoId, result));
        }

        default:
          return empty();
      }
    })
  );

const uploadEpic = (action$, store, { api }) =>
  action$.pipe(
    filter(ofType(PLACE_UPLOAD_PHOTOS)),
    switchMap(action => {
      const { placeId, newPhotos } = action.payload;

      return from(newPhotos.values()).pipe(
        mergeMap(photo =>
          uploadIndividualFile(api, placeId, photo.id, photo.file).pipe(
            catchError(
              logError(error => {
                Object.assign(error, { placeId, photoId: photo.id });

                return of(placeUploadComplete(error));
              })
            )
          )
        )
      );
    })
  );

const suggestionsEpic = (action$, store, { geocode }) =>
  action$.pipe(
    filter(ofType(PLACE_CHANGE_ADDRESS)),
    groupBy(action => action.payload.placeId),
    mergeMap(action$ =>
      action$.pipe(
        switchMap(action => {
          const { placeId, value: address } = action.payload;

          return from(geocode.getSuggestions(address.displayName)).pipe(
            map(results => placeAddressSuggestionsLoaded(placeId, results)),
            catchError(
              logError(error => {
                Object.assign(error, { placeId });

                return of(placeAddressSuggestionsLoaded(error));
              })
            )
          );
        })
      )
    )
  );

const geocodingEpic = (action$, store, { geocode }) =>
  merge(
    action$.ofType(PLACE_ADDRESS_SUGGESTION_SELECTED),
    action$.ofType(PLACE_CHANGE_COORDINATES)
  ).pipe(
    groupBy(action => action.payload.placeId),
    mergeMap(action$ =>
      action$.pipe(
        switchMap(action => {
          const { placeId } = action.payload;

          if (action.type === PLACE_ADDRESS_SUGGESTION_SELECTED) {
            const { suggestion } = action.payload;
            const requestAddress = makeAddress({
              value: suggestion.value,
              displayName: suggestion.displayName,
            });

            return from(geocode.getCoordinates(requestAddress)).pipe(
              map(coordinates =>
                placeGeocodeResult(
                  action.payload.placeId,
                  { address: requestAddress },
                  null,
                  coordinates
                )
              ),
              catchError(
                logError(error => {
                  Object.assign(error, { placeId });

                  return of(placeGeocodeResult(error));
                })
              )
            );
          }

          const { value: requestCoordinates } = action.payload;

          return from(geocode.getAddress(requestCoordinates)).pipe(
            map(address =>
              placeGeocodeResult(
                placeId,
                { coordinates: requestCoordinates },
                address,
                null
              )
            ),
            catchError(
              logError(error => {
                Object.assign(error, { placeId });

                return of(placeGeocodeResult(error));
              })
            )
          );
        })
      )
    )
  );

export default combineEpics(uploadEpic, suggestionsEpic, geocodingEpic);
