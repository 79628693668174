// @flow
import { Record, type RecordOf, type RecordFactory } from "immutable";
import type {
  Invite as BaseInvite,
  InviteId as BaseInviteId,
} from "outnabout-api";
import { formatRoute, INVITE_ACCEPT } from "../routes";

const EXPIRATION_INTERVAL = 7 * 24 * 3600 * 1000;

export type InviteFields = BaseInvite;
export type InviteId = BaseInviteId;

export type Invite = RecordOf<InviteFields>;

export const makeInvite: RecordFactory<InviteFields> = Record(
  {
    id: "",
    admin: false,
    publisher: false,
    claimedBy: null,
    createdAt: 0,
    updatedAt: 0,
  },
  "Invite"
);

export const isInvitePending = (invite: Invite): boolean => !invite.claimedBy;

export const isInviteAdmin = (invite: Invite): boolean => !!invite.admin;

export const getInviteExpiresAt = (invite: Invite): number =>
  invite.createdAt + EXPIRATION_INTERVAL;

export const isInviteExpired = (invite: Invite): boolean =>
  getInviteExpiresAt(invite) <= Date.now();

export const getInviteUrl = (invite: Invite, baseUrl: string): string => {
  const url = new URL(baseUrl);

  url.pathname = formatRoute(INVITE_ACCEPT, { id: invite.id });

  return url.toString();
};

export default makeInvite;
