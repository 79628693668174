// @flow
import * as React from "react";
import styled from "styled-components";
import type { List } from "immutable";
import AspectRatioBox from "./AspectRatioBox";

type Props<T> = {
  items: List<T>,
  renderItem: (item: T) => React.Node,
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const PrimaryPhoto = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const ExtraPhotos = styled.div`
  display: flex;
  flex-wrap: nowrap;
  margin: 2px -2px;
  overflow-x: auto;
`;

const SecondaryPhoto = styled.div`
  width: 15%;
  flex-shrink: 0;
  padding: 2px;
  box-sizing: border-box;

  & img {
    width: 100%;
    height: 100%;

    object-fit: cover;
  }
`;

class Gallery<T> extends React.Component<Props<T>, { index: number }> {
  state = { index: 0 };

  render() {
    const { index } = this.state;
    const { items, renderItem } = this.props;
    let primaryPhoto = items.get(index);

    if (!primaryPhoto) {
      primaryPhoto = items.first();
    }

    if (!primaryPhoto) {
      return null;
    }

    return (
      <Container>
        <PrimaryPhoto>
          <AspectRatioBox ratio={16 / 9}>
            {renderItem(primaryPhoto)}
          </AspectRatioBox>
        </PrimaryPhoto>
        <ExtraPhotos data-cy="photos">
          {items.map((item, key) => (
            <SecondaryPhoto
              key={
                // eslint-disable-next-line react/no-array-index-key
                key
              }
              onClick={() => this.setState({ index: key })}
            >
              <AspectRatioBox ratio={1}>{renderItem(item)}</AspectRatioBox>
            </SecondaryPhoto>
          ))}
        </ExtraPhotos>
      </Container>
    );
  }
}

export default Gallery;
