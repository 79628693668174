// @flow
import type { List as ImmutableList } from "immutable";
import * as React from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { withRouter, type ContextRouter } from "react-router";
import { translate, type TranslatorProps } from "react-i18next";
import { VirtualizedList, Button } from "../components";
import { memberListShown, memberListHidden } from "../actions";
import {
  makeGetMemberListIds,
  makeIsMemberListLoaded,
  makeGetPendingInviteCount,
} from "../selectors";
import { withSideEffects } from "../hocs";
import type { MemberId } from "../models";
import { INVITE_LIST } from "../routes";
import MemberListItemContainer from "./MemberListItemContainer";

type Props = {
  ...$Exact<ContextRouter>,
  ...$Exact<TranslatorProps>,
  ids: ImmutableList<MemberId>,
  loaded: boolean,
  pendingInviteCount: number,
  memberListShown: () => void,
  memberListHidden: () => void,
};

const makeMapStateToProps = () =>
  createStructuredSelector({
    ids: makeGetMemberListIds(),
    loaded: makeIsMemberListLoaded(),
    pendingInviteCount: makeGetPendingInviteCount(),
  });

const enhance = compose(
  withRouter,
  translate(),
  connect(
    makeMapStateToProps,
    { memberListShown, memberListHidden }
  ),
  withSideEffects({
    onMount: ({ memberListShown }) => memberListShown(),
    onUnmount: ({ memberListHidden }) => memberListHidden(),
  })
);

const MemberListContainer = ({
  t,
  history,
  ids,
  loaded,
  pendingInviteCount,
}: Props) => (
  <React.Fragment>
    {pendingInviteCount > 0 && (
      <Button
        fullWidth
        color="primary"
        size="small"
        onClick={() => history.push(INVITE_LIST)}
      >
        {t("members.list.pending_invites", { count: pendingInviteCount })}
      </Button>
    )}
    <VirtualizedList
      loading={!loaded}
      items={ids}
      rowRenderer={({ item }) => <MemberListItemContainer id={item} />}
    />
  </React.Fragment>
);

export default enhance(MemberListContainer);
