"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _Rx = require("rxjs/Rx");

var fromEvent = function fromEvent(ref, eventType, selector) {
  return _Rx.Observable.create(function (observer) {
    var callback = function callback() {
      return observer.next(selector.apply(undefined, arguments));
    };

    ref.on(eventType, callback, observer.error.bind(observer));

    return function () {
      return ref.off(eventType, callback);
    };
  });
};
exports.default = fromEvent;