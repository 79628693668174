// @flow
import * as React from "react";
import { compose } from "recompose";
import { withRouter, type ContextRouter } from "react-router";
import { translate, type TranslatorProps } from "react-i18next";
import {
  PlaceDetailsForm,
  Toolbar,
  Form,
  FormActionsToolbar,
} from "../components";
import {
  LayoutContainer,
  PlaceContainer,
  PlaceEditContainer,
} from "../containers";
import { formatRoute, PLACE_DETAILS } from "../routes";
import type { PlaceId } from "../models";

type Props = {
  ...$Exact<ContextRouter>,
  ...$Exact<TranslatorProps>,
  id: PlaceId,
};

const enhance = compose(
  withRouter,
  translate()
);

class PlaceEditDetailsScreen extends React.Component<Props> {
  goBack = () => {
    const { id, history } = this.props;

    history.push(formatRoute(PLACE_DETAILS, { id }));
  };

  render() {
    const { t, id } = this.props;

    return (
      <PlaceContainer
        id={id}
        render={() => (
          <PlaceEditContainer
            id={id}
            render={({ details, updatePlace }) => (
              <PlaceDetailsForm
                initialValues={details}
                onSubmit={details => updatePlace({ details })}
                layout={({ children, submitForm }) => (
                  <Form preventDefault onSubmit={submitForm}>
                    <LayoutContainer
                      toolbar={
                        <Toolbar
                          onGoBack={this.goBack}
                          title={t("places.title.details")}
                        />
                      }
                      footer={
                        <FormActionsToolbar
                          onCancel={this.goBack}
                          onSubmit={submitForm}
                        />
                      }
                    >
                      {children}
                    </LayoutContainer>
                  </Form>
                )}
              />
            )}
          />
        )}
      />
    );
  }
}

export default enhance(PlaceEditDetailsScreen);
