import { combineEpics } from "redux-observable";
import { of, from, merge } from "rxjs";
import {
  filter,
  first,
  map,
  switchMap,
  takeUntil,
  groupBy,
  mergeMap,
  catchError,
} from "rxjs/operators";
import {
  getMembers,
  getMember,
  getMemberPlaces,
  updateMember,
  suspendMember,
} from "outnabout-api";
import { filterListEvents, VALUE_EVENT } from "outnabout-api/lib/listEvents";
import {
  MEMBER_SHOWN,
  MEMBER_HIDDEN,
  MEMBER_LIST_SHOWN,
  MEMBER_LIST_HIDDEN,
  MEMBER_UPDATE,
  MEMBER_SUSPEND,
  MEMBER_RESTORE,
  memberLoaded,
  memberPlacesLoaded,
  memberListLoaded,
  memberUpdateResult,
  memberSuspendResult,
  memberRestoreResult,
} from "../actions";
import { ofType, logError, createSimpleApiCallEpic } from "./utils";

const retrieveMemberListEpic = (action$, store, { api }) =>
  action$.pipe(
    filter(ofType(MEMBER_LIST_SHOWN)),
    switchMap(() =>
      getMembers(api).pipe(
        filterListEvents(VALUE_EVENT),
        first(),
        map(event => memberListLoaded(event.entities)),
        takeUntil(action$.ofType(MEMBER_LIST_HIDDEN))
      )
    )
  );

const memberDetailsEpic = (action$, store, { api }) =>
  action$.pipe(
    filter(ofType(MEMBER_SHOWN, MEMBER_HIDDEN)),
    groupBy(action => action.payload.id),
    mergeMap(action$ =>
      action$.pipe(
        filter(ofType(MEMBER_SHOWN)),
        switchMap(action => {
          const { id } = action.payload;

          return merge(
            getMember(api, id).pipe(
              catchError(logError(error => of(error))),
              map(memberLoaded)
            ),
            getMemberPlaces(api, id).pipe(
              filterListEvents(VALUE_EVENT),
              first(),
              map(event => memberPlacesLoaded(id, event.entities)),
              catchError(logError(error => of(memberPlacesLoaded(error))))
            )
          ).pipe(takeUntil(action$.pipe(filter(ofType(MEMBER_HIDDEN)))));
        })
      )
    )
  );

const updateMemberEpic = (action$, store, { api }) =>
  action$.pipe(
    filter(ofType(MEMBER_UPDATE)),
    mergeMap(action => {
      const { member, update } = action.payload;

      return updateMember(api, member.id, update).pipe(
        mergeMap(() => getMember(api, member.id).pipe(first())),
        catchError(logError(error => of(error))),
        map(memberUpdateResult)
      );
    })
  );

export default combineEpics(
  retrieveMemberListEpic,
  memberDetailsEpic,
  updateMemberEpic,
  createSimpleApiCallEpic(
    MEMBER_SUSPEND,
    (action, { api }) =>
      from(suspendMember(api, action.payload.member.id, true)).pipe(
        switchMap(() => getMember(api, action.payload.member.id)),
        first()
      ),
    memberSuspendResult
  ),
  createSimpleApiCallEpic(
    MEMBER_RESTORE,
    (action, { api }) =>
      from(suspendMember(api, action.payload.member.id, false)).pipe(
        switchMap(() => getMember(api, action.payload.member.id)),
        first()
      ),
    memberRestoreResult
  )
);
