// @flow
import * as React from "react";
import { Add as AddIcon } from "material-ui-icons";
import { Link } from "react-router-dom";
import { I18n } from "react-i18next";
import { FloatingActionButton } from "../components";
import { LayoutContainer, PlaceListContainer } from "../containers";
import { PLACE_LIST, PLACE_CREATE } from "../routes";

const PlaceListScreen = () => (
  <I18n>
    {t => (
      <LayoutContainer title={t("menu.places")} parentRoute={PLACE_LIST}>
        <PlaceListContainer />
        <FloatingActionButton
          containerComponent={props => <Link {...props} to={PLACE_CREATE} />}
          icon={<AddIcon />}
          aria-label={t("places.actions.create")}
          color="secondary"
        />
      </LayoutContainer>
    )}
  </I18n>
);

export default PlaceListScreen;
