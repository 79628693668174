"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});


var logout = function logout(firebase) {
  return firebase.auth().signOut();
};
exports.default = logout;