// @flow
import * as React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { translate, type TranslatorProps } from "react-i18next";
import { compose, branch, renderComponent, withHandlers } from "recompose";
import { Typography } from "material-ui";
import {
  makeGetInvite,
  makeIsInviteLoaded,
  makeGetAuthenticatedUser,
  makeIsInviteAccepting,
} from "../selectors";
import { inviteShown, inviteHidden, acceptInvite } from "../actions";
import { Button, LoadingPlaceholder, LoginForm } from "../components";
import { withSideEffects } from "../hocs";
import {
  isInvitePending,
  isInviteExpired,
  isInviteAdmin,
  type Invite,
  type InviteId,
  type User,
} from "../models";
import { NotFoundError } from "../errors";
import AuthContainer from "./AuthContainer";

type Props = {
  ...$Exact<TranslatorProps>,
  id: InviteId,
  invite: Invite,
  loaded: boolean,
  user: User,
  isAccepting: boolean,
  acceptInvite: (invite: Invite) => void,
  handleAcceptInvite: () => void,
};

const makeMapStateToProps = createStructuredSelector({
  invite: makeGetInvite(),
  loaded: makeIsInviteLoaded(),
  user: makeGetAuthenticatedUser(),
  isAccepting: makeIsInviteAccepting(),
});

const enhance = compose(
  translate(),
  connect(
    makeMapStateToProps,
    { inviteShown, inviteHidden, acceptInvite }
  ),
  withSideEffects({
    onMount: ({ inviteShown, id }) => inviteShown(id),
    onUnmount: ({ inviteHidden, id }) => inviteHidden(id),
  }),
  branch(({ loaded }) => !loaded, renderComponent(LoadingPlaceholder)),
  branch(
    ({ invite }) => !invite,
    renderComponent(() => {
      throw new NotFoundError();
    })
  ),
  withHandlers({
    handleAcceptInvite: ({ acceptInvite, user, invite }) => () =>
      acceptInvite(user, invite),
  })
);

class InviteAcceptContainer extends React.Component<Props> {
  renderPendingInvite() {
    const { t, invite, isAccepting, handleAcceptInvite } = this.props;

    return (
      <React.Fragment>
        <Typography gutterBottom align="center" variant="subheading">
          {isInviteAdmin(invite)
            ? t("invites.accept.legend_admin")
            : t("invites.accept.legend_publisher")}
        </Typography>
        <AuthContainer>
          {({ user, login }) =>
            user ? (
              <Button
                color="primary"
                loading={isAccepting}
                onClick={handleAcceptInvite}
              >
                {t("invites.accept.accept_action")}
              </Button>
            ) : (
              <LoginForm onLogin={login} />
            )
          }
        </AuthContainer>
      </React.Fragment>
    );
  }

  renderClaimedInvite() {
    const { t } = this.props;

    return <Typography>{t("invites.accept.claimed")}</Typography>;
  }

  renderExpiredInvite() {
    const { t } = this.props;

    return <Typography>{t("invites.accept.expired")}</Typography>;
  }

  render() {
    const { invite, isAccepting } = this.props;

    if (!isInvitePending(invite) && !isAccepting) {
      return this.renderClaimedInvite();
    }

    if (isInviteExpired(invite)) {
      return this.renderExpiredInvite();
    }

    return this.renderPendingInvite();
  }
}

export default enhance(InviteAcceptContainer);
