// @flow
import * as React from "react";
import styled from "styled-components";
import { pure } from "recompose";
import BaseTextField from "material-ui/TextField";

type Props = {
  ...$Exact<React.ElementProps<typeof BaseTextField>>,
  autocomplete?: boolean,
  autoFocus?: boolean,
  multiline?: boolean,
  required?: boolean,
  gutterBottom?: boolean,
  fixedLabel?: boolean,
  label?: React.Node,
  placeholder?: string,
  error?: string,
};

const defaultProps = {
  autocomplete: false,
  multiline: false,
  autoFocus: false,
  required: false,
  gutterBottom: false,
  fixedLabel: false,
  label: null,
  placeholder: null,
  error: null,
};

const Container = styled.div`
  min-height: 68px;
  margin-bottom: ${props => (props.gutterBottom ? "16px" : "0")};
`;

const TextField = ({
  autoFocus,
  multiline,
  required,
  gutterBottom,
  autocomplete,
  fixedLabel,
  label,
  placeholder,
  error,
  ...rest
}: Props) => (
  <Container gutterBottom={gutterBottom}>
    <BaseTextField
      {...rest}
      fullWidth
      autoFocus={autoFocus}
      multiline={multiline}
      rows={1}
      rowsMax={multiline ? 60 : 1}
      required={required}
      InputProps={{
        ...rest.InputProps,
        ...(autocomplete ? {} : { autoComplete: "off" }),
        inputProps: {
          "aria-label": label,
        },
      }}
      InputLabelProps={fixedLabel ? { shrink: true } : null}
      label={label}
      placeholder={placeholder}
      error={error !== null}
      helperText={error}
    />
  </Container>
);

TextField.defaultProps = defaultProps;

export default pure(TextField);
