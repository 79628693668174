"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require("react");

var React = _interopRequireWildcard(_react);

var _yandexMapReact = require("yandex-map-react");

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var YandexMap = function (_React$Component) {
  _inherits(YandexMap, _React$Component);

  function YandexMap() {
    var _ref;

    var _temp, _this, _ret;

    _classCallCheck(this, YandexMap);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = YandexMap.__proto__ || Object.getPrototypeOf(YandexMap)).call.apply(_ref, [this].concat(args))), _this), _this.handleClick = function (e) {
      var onClick = _this.props.onClick;


      if (onClick) {
        onClick(e.get("coords"));
      }
    }, _this.handleBoundsChange = function (e) {
      var onBoundsChange = _this.props.onBoundsChange;


      if (onBoundsChange) {
        onBoundsChange(e.get("newCenter"), e.get("newZoom"));
      }
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  _createClass(YandexMap, [{
    key: "render",
    value: function render() {
      var _props = this.props,
          language = _props.language,
          width = _props.width,
          height = _props.height,
          center = _props.center,
          zoom = _props.zoom,
          marker = _props.marker,
          onClick = _props.onClick;


      return React.createElement(
        _yandexMapReact.Map,
        {
          width: width,
          height: height,
          loadOptions: { lang: language },
          center: center,
          zoom: zoom,
          onClick: this.handleClick,
          onBoundschange: this.handleBoundsChange
        },
        marker && React.createElement(_yandexMapReact.Marker, {
          lat: marker[0],
          lon: marker[1],
          options: {
            preset: "islands#blueDotIcon"
          }
        })
      );
    }
  }]);

  return YandexMap;
}(React.Component);

exports.default = YandexMap;