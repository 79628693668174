// @flow
import { createSelector, type Selector } from "reselect";
import type { State } from "../reducers";
import type { Notification } from "../models";

const getNotificationSlice = state => state.notification;

export const makeGetNotification = (): Selector<State, any, ?Notification> =>
  createSelector(
    getNotificationSlice,
    notification => notification.first() || null
  );
