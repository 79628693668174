// @flow
import * as React from "react";
import { compose, pure } from "recompose";
import { Route } from "react-router";
import { Button as BaseButton, withStyles } from "material-ui";
import classnames from "classnames";
import LoadingWrapper from "./LoadingWrapper";

type Props = {
  classes: Object,
  align?: ?("start" | "center" | "end"),
  fullWidth?: boolean,
  raised?: boolean,
  loading?: boolean,
  disabled?: boolean,
  color?: "default" | "primary" | "secondary",
  size?: "small" | "medium" | "large",
  icon?: React.ComponentType<any>,
  rightIcon?: React.ComponentType<any>,
  children: React.Node,
  link?: ?string,
};

const defaultProps = {
  link: null,
  style: null,
  align: null,
  fullWidth: false,
  raised: false,
  loading: false,
  disabled: false,
  color: "default",
  size: "medium",
  icon: null,
  rightIcon: null,
  children: null,
};

const styles = theme => ({
  fullWidth: {
    width: "100%",
  },
  smallIcon: {
    width: 18,
    height: 18,
    margin: `-${theme.spacing.unit / 2}px 0`,
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
  rightIcon: {
    marginLeft: theme.spacing.unit,
  },
  label: {
    display: "flex",
    alignItems: "center",
  },
  start: {
    alignSelf: "flex-start",
  },
  end: {
    alignSelf: "flex-end",
  },
  center: {
    alignSelf: "center",
  },
});

const enhance = compose(
  pure,
  withStyles(styles)
);

const Button = (props: Props) => {
  if (props.link) {
    const { link, ...rest } = props;

    return (
      <Route>
        {({ history }) =>
          Button({
            onClick: () => history.push(link),
            ...rest,
          })
        }
      </Route>
    );
  }

  const {
    classes,
    align,
    fullWidth,
    raised,
    loading,
    disabled,
    color,
    size,
    icon,
    rightIcon,
    children,
    ...rest
  } = props;

  return (
    <BaseButton
      {...rest}
      variant={raised ? "raised" : "flat"}
      disabled={disabled}
      color={color}
      size={size}
      className={classnames({
        [classes.fullWidth]: fullWidth,
        [classes.start]: align === "start",
        [classes.center]: align === "center",
        [classes.end]: align === "end",
      })}
    >
      <LoadingWrapper color={raised ? "accent" : null} loading={loading}>
        <span className={classes.label}>
          {icon
            ? React.createElement(icon, {
                className: classes.leftIcon,
              })
            : null}
          {children}
          {rightIcon
            ? React.createElement(rightIcon, {
                className: classes.rightIcon,
              })
            : null}
        </span>
      </LoadingWrapper>
    </BaseButton>
  );
};

Button.defaultProps = defaultProps;

export default enhance(Button);
