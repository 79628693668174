// @flow
import * as React from "react";
import yup from "yup";
import { when, isEmpty, always } from "ramda";
import { compose } from "recompose";
import { translate, type TranslatorProps } from "react-i18next";
import { withFormik, type FormikProps } from "formik";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from "material-ui";
import { MAX_LENGTH_FULL_NAME } from "../constants";
import type { Application } from "../models";
import { withSideEffects } from "../hocs";
import Button from "./Button";

type Props = {
  ...$Exact<TranslatorProps>,
  ...FormikProps<{ comment: string }>,
  open: boolean,
  application: ?Application,
  onClose: () => void,
  onConfirm: (comment: string) => void,
};

const getSchema = ({ t }: TranslatorProps) =>
  yup.object().shape({
    comment: yup
      .string()
      .trim()
      .nullable()
      .transform(when(isEmpty, always(null)))
      .max(
        MAX_LENGTH_FULL_NAME,
        t("validations:tooLong", { max: MAX_LENGTH_FULL_NAME })
      ),
  });

const enhance = compose(
  translate(),
  withFormik({
    mapPropsToValues: () => ({ comment: "" }),
    validationSchema: props => getSchema(props),
    handleSubmit: (values, { props }) =>
      props.onConfirm(getSchema(props).cast(values)),
  }),
  // Reset form when dialog is reopened
  withSideEffects({
    onUpdate: ({ open, resetForm }, prevProps) =>
      open && !prevProps.open && resetForm(),
  })
);

const ApplicationRejectDialog = ({
  t,
  open,
  onClose,
  values,
  errors,
  handleChange,
  handleBlur,
  handleSubmit,
}: Props) => (
  <Dialog fullWidth open={!!open} onClose={onClose}>
    <form onSubmit={handleSubmit}>
      <DialogTitle>{t("applications.list.reject_dialog_title")}</DialogTitle>
      <DialogContent>
        <TextField
          fullWidth
          autoFocus
          multiline
          id="resolution-comment"
          name="comment"
          rows={2}
          rowsMax={Infinity}
          placeholder={t("applications.list.reject_dialog_comment_placeholder")}
          value={values.comment}
          error={!!errors.comment}
          helperText={errors.comment || null}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          {t("applications.list.reject_dialog_cancel_action")}
        </Button>
        <Button type="submit" color="primary">
          {t("applications.list.reject_dialog_confirm_action")}
        </Button>
      </DialogActions>
    </form>
  </Dialog>
);

export default enhance(ApplicationRejectDialog);
