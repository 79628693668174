// @flow
import * as React from "react";
import styled from "styled-components";
import { I18n } from "react-i18next";
import { Typography } from "material-ui";
import type { PlaceSchedule } from "../models";
import ListItem from "./ListItem";

type Props = {
  schedule: PlaceSchedule,
};

const Day = styled(ListItem)`
  display: flex;
`;

const Label = styled(Typography)`
  flex: 1;
`;

const Value = styled(Typography)``;

const dayLabels = {
  mon: "general.days.monday",
  tue: "general.days.tuesday",
  wed: "general.days.wednesday",
  thu: "general.days.thursday",
  fri: "general.days.friday",
  sat: "general.days.saturday",
  sun: "general.days.sunday",
};

// TODO: Same function exists in ScheduleTimeField
const formatTime = (minutesSinceMidnight: number) => {
  const schedule = Math.floor(minutesSinceMidnight / 60);
  const minutes = minutesSinceMidnight % 60;

  return [`${schedule}`.padStart(2, "0"), `${minutes}`.padStart(2, "0")].join(
    ":"
  );
};

const ScheduleBlock = ({ schedule }: Props) => (
  <I18n>
    {t => (
      <div data-cy="schedule">
        {["mon", "tue", "wed", "thu", "fri", "sat", "sun"].map(day => {
          const label = t(dayLabels[day]);
          const daySchedule = schedule.get(day, null);

          return (
            <Day key={day}>
              <Label variant="body1">{label}</Label>
              {daySchedule ? (
                <Value>
                  {`${formatTime(daySchedule.open)} — ${formatTime(
                    daySchedule.close
                  )}`}
                </Value>
              ) : (
                <Value>{t("general.schedule.closed")}</Value>
              )}
            </Day>
          );
        })}
      </div>
    )}
  </I18n>
);

export default ScheduleBlock;
