// @flow
import { List, Map, Set } from "immutable";
import {
  makePlaceDetails,
  makePlaceAddress,
  makePlaceCoordinates,
  makePlacePhoto,
  makePlaceSchedule,
  makeAddressSuggestion,
  makePlaceEditPhotoFromFile,
  makePlaceEditPhotoUploadProgress,
  type PlaceId,
  type PlaceDetails,
  type PlaceDetailsFields,
  type PlaceAddress,
  type PlaceAddressFields,
  type PlaceCoordinates,
  type PlaceCoordinatesFields,
  type PlacePhoto,
  type PlacePhotoFields,
  type PlaceSchedule,
  type PlaceScheduleFields,
  type PlaceEditPhotoId,
  type PlaceEditPhoto,
  type AddressSuggestion,
  type PlaceEditPhotoUploadProgress,
} from "../models";
import { withErrorVariant, type WithErrorVariant } from "./utils";

export const PLACE_CHANGE_DETAILS: "PLACE_CHANGE_DETAILS" =
  "PLACE_CHANGE_DETAILS";
export const PLACE_CHANGE_ADDRESS: "PLACE_CHANGE_ADDRESS" =
  "PLACE_CHANGE_ADDRESS";
export const PLACE_CHANGE_COORDINATES: "PLACE_CHANGE_COORDINATES" =
  "PLACE_CHANGE_COORDINATES";
export const PLACE_GEOCODE_RESULT: "PLACE_GEOCODE_RESULT" =
  "PLACE_GEOCODE_RESULT";
export const PLACE_ADDRESS_SUGGESTIONS_LOADED: "PLACE_ADDRESS_SUGGESTIONS_LOADED" =
  "PLACE_ADDRESS_SUGGESTIONS_LOADED";
export const PLACE_ADDRESS_SUGGESTION_SELECTED: "PLACE_ADDRESS_SUGGESTION_SELECTED" =
  "PLACE_ADDRESS_SUGGESTION_SELECTED";
export const PLACE_PHOTO_MOVE: "PLACE_PHOTO_MOVE" = "PLACE_PHOTO_MOVE";
export const PLACE_REMOVE_PHOTOS: "PLACE_REMOVE_PHOTOS" = "PLACE_REMOVE_PHOTOS";
export const PLACE_UPLOAD_PHOTOS: "PLACE_UPLOAD_PHOTOS" = "PLACE_UPLOAD_PHOTOS";
export const PLACE_UPLOAD_PROGRESS: "PLACE_UPLOAD_PROGRESS" =
  "PLACE_UPLOAD_PROGRESS";
export const PLACE_UPLOAD_COMPLETE: "PLACE_UPLOAD_COMPLETE" =
  "PLACE_UPLOAD_COMPLETE";
export const PLACE_CHANGE_SCHEDULE: "PLACE_CHANGE_SCHEDULE" =
  "PLACE_CHANGE_SCHEDULE";

interface UploadCompleteError extends Error {
  placeId: PlaceId;
  photoId: PlaceEditPhotoId;
}

export type Action =
  | {|
      type: typeof PLACE_CHANGE_DETAILS,
      payload: {|
        placeId: ?PlaceId,
        value: PlaceDetails,
      |},
    |}
  | {|
      type: typeof PLACE_CHANGE_ADDRESS,
      payload: {|
        placeId: ?PlaceId,
        value: PlaceAddress,
      |},
    |}
  | {|
      type: typeof PLACE_CHANGE_COORDINATES,
      payload: {|
        placeId: ?PlaceId,
        value: PlaceCoordinates,
      |},
    |}
  | {|
      type: typeof PLACE_GEOCODE_RESULT,
      payload: {|
        placeId: ?PlaceId,
        request:
          | {|
              address: PlaceAddress,
            |}
          | {|
              coordinates: PlaceCoordinates,
            |},
        address: PlaceAddress,
        coordinates: PlaceCoordinates,
      |},
    |}
  | {|
      type: typeof PLACE_ADDRESS_SUGGESTIONS_LOADED,
      payload: {|
        placeId: ?PlaceId,
        suggestions: List<AddressSuggestion>,
      |},
    |}
  | {|
      type: typeof PLACE_ADDRESS_SUGGESTION_SELECTED,
      payload: {|
        placeId: ?PlaceId,
        suggestion: AddressSuggestion,
        address: PlaceAddress,
      |},
    |}
  | {|
      type: typeof PLACE_PHOTO_MOVE,
      payload: {|
        placeId: ?PlaceId,
        prevIndex: number,
        newIndex: number,
        photos: List<PlaceEditPhoto>,
      |},
    |}
  | {|
      type: typeof PLACE_REMOVE_PHOTOS,
      payload: {|
        placeId: ?PlaceId,
        selectedPhotos: Set<PlaceEditPhoto>,
        photos: List<PlaceEditPhoto>,
      |},
    |}
  | {|
      type: typeof PLACE_UPLOAD_PHOTOS,
      payload: {|
        placeId: ?PlaceId,
        newPhotos: Map<PlaceEditPhotoId, PlaceEditPhoto>,
        photos: List<PlaceEditPhoto>,
      |},
    |}
  | {|
      type: typeof PLACE_UPLOAD_PROGRESS,
      payload: {|
        placeId: ?PlaceId,
        photoId: PlaceEditPhotoId,
        progress: PlaceEditPhotoUploadProgress,
      |},
    |}
  | WithErrorVariant<
      {|
        type: typeof PLACE_UPLOAD_COMPLETE,
        payload: {|
          placeId: ?PlaceId,
          photoId: PlaceEditPhotoId,
          result: PlacePhoto,
        |},
      |},
      UploadCompleteError
    >
  | {|
      type: typeof PLACE_CHANGE_SCHEDULE,
      payload: {|
        placeId: ?PlaceId,
        value: PlaceSchedule,
      |},
    |};

export const placeChangeDetails = (
  placeId: ?PlaceId,
  value: PlaceDetailsFields
): Action => ({
  type: PLACE_CHANGE_DETAILS,
  payload: {
    placeId,
    value: makePlaceDetails(value),
  },
});

export const placeChangeAddress = (
  placeId: ?PlaceId,
  value: PlaceAddressFields
): Action => ({
  type: PLACE_CHANGE_ADDRESS,
  payload: {
    placeId,
    value: makePlaceAddress(value),
  },
});

export const placeChangeCoordinates = (
  placeId: ?PlaceId,
  value: PlaceCoordinatesFields
): Action => ({
  type: PLACE_CHANGE_COORDINATES,
  payload: {
    placeId,
    value: makePlaceCoordinates(value),
  },
});

export const placeGeocodeResult = withErrorVariant(
  PLACE_GEOCODE_RESULT,
  (
    placeId: ?PlaceId,
    request: {| address: PlaceAddress |} | {| coordinates: PlaceCoordinates |},
    address: PlaceAddressFields,
    coordinates: PlaceCoordinatesFields
  ): Action => ({
    type: PLACE_GEOCODE_RESULT,
    payload: {
      placeId,
      request,
      address: makePlaceAddress(address),
      coordinates: makePlaceCoordinates(coordinates),
    },
  })
);

export const placeAddressSuggestionsLoaded = withErrorVariant(
  PLACE_ADDRESS_SUGGESTIONS_LOADED,
  (
    placeId: ?PlaceId,
    suggestions: Array<$Shape<AddressSuggestion>>
  ): Action => ({
    type: PLACE_ADDRESS_SUGGESTIONS_LOADED,
    payload: {
      placeId,
      suggestions: List(suggestions).map(makeAddressSuggestion),
    },
  })
);

export const placeAddressSuggestionSelected = (
  placeId: ?PlaceId,
  suggestion: AddressSuggestion
): Action => ({
  type: PLACE_ADDRESS_SUGGESTION_SELECTED,
  payload: {
    placeId,
    suggestion: makeAddressSuggestion(suggestion),
    address: makePlaceAddress({
      value: suggestion.value,
      displayName: suggestion.displayName,
    }),
  },
});

export const placePhotoMove = (
  placeId: ?PlaceId,
  prevIndex: number,
  newIndex: number,
  photos: List<PlaceEditPhoto>
): Action => ({
  type: PLACE_PHOTO_MOVE,
  payload: {
    placeId,
    prevIndex,
    newIndex,
    photos,
  },
});

export const placeRemovePhotos = (
  placeId: ?PlaceId,
  selectedPhotos: List<PlaceEditPhoto>,
  photos: List<PlaceEditPhoto>
): Action => ({
  type: PLACE_REMOVE_PHOTOS,
  payload: {
    placeId,
    selectedPhotos: Set(selectedPhotos),
    photos,
  },
});

export const placeUploadPhotos = (
  placeId: ?PlaceId,
  files: Array<File>,
  photos: List<PlaceEditPhoto>
): Action => ({
  type: PLACE_UPLOAD_PHOTOS,
  payload: {
    placeId,
    newPhotos: files
      .map(makePlaceEditPhotoFromFile)
      .reduce((map, photo) => map.set(photo.id, photo), Map()),
    photos,
  },
});

export const placeUploadProgress = (
  placeId: ?PlaceId,
  photoId: PlaceEditPhotoId,
  progress: $Shape<PlaceEditPhotoUploadProgress>
): Action => ({
  type: PLACE_UPLOAD_PROGRESS,
  payload: {
    placeId,
    photoId,
    progress: makePlaceEditPhotoUploadProgress(progress),
  },
});

export const placeUploadComplete = withErrorVariant(
  PLACE_UPLOAD_COMPLETE,
  (
    placeId: ?PlaceId,
    photoId: PlaceEditPhotoId,
    result: PlacePhotoFields
  ): Action => ({
    type: PLACE_UPLOAD_COMPLETE,
    payload: {
      placeId,
      photoId,
      result: makePlacePhoto(result),
    },
  })
);

export const placeChangeSchedule = (
  placeId: ?PlaceId,
  value: PlaceScheduleFields
): Action => ({
  type: PLACE_CHANGE_SCHEDULE,
  payload: {
    placeId,
    value: makePlaceSchedule(value),
  },
});
