// @flow
import * as React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { PlaceListItem } from "../components";
import { makeGetPlace } from "../selectors";
import { formatRoute, PLACE_DETAILS } from "../routes";
import type { Place, PlaceId } from "../models";

type Props = {
  id: PlaceId,
  place: Place,
};

const makeMapStateToProps = () =>
  createStructuredSelector({
    place: makeGetPlace(),
  });

const enhance = connect(
  makeMapStateToProps,
  {}
);

const PlaceListItemContainer = ({ id, place, ...rest }: Props) => (
  <PlaceListItem
    {...rest}
    place={place}
    link={formatRoute(PLACE_DETAILS, { id: place.getId() })}
  />
);

export default enhance(PlaceListItemContainer);
