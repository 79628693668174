// @flow
import { Record } from "immutable";
import type {
  Member as BaseMember,
  MemberId as BaseMemberId,
} from "outnabout-api";

export type MemberId = BaseMemberId;

export type MemberFields = BaseMember;

export class Member extends Record(
  ({
    id: "",
    admin: false,
    publisher: false,
    displayName: null,
    photoURL: null,
    email: null,
    createdAt: 0,
    updatedAt: 0,
  }: MemberFields),
  "Member"
) {
  getId(): MemberId {
    return this.get("id");
  }

  getDisplayName(): ?string {
    return this.get("displayName");
  }

  getPhotoUrl(): ?string {
    return this.get("photoURL");
  }

  getEmail(): ?string {
    return this.get("email");
  }

  getCreatedAt(): number {
    return this.get("createdAt");
  }

  isAdmin(): boolean {
    return this.get("admin", false);
  }

  isPublisher(): boolean {
    return this.get("publisher", false);
  }

  canPublish(): boolean {
    return this.get("admin", false) || this.get("publisher", false);
  }
}

export const makeMember = (values: $Shape<MemberFields> = {}): Member =>
  new Member(values);
