// @flow
import type { Reducer } from "redux";
import { Map, List } from "immutable";
import {
  MEMBER_LOADED,
  MEMBER_LIST_LOADED,
  MEMBER_PLACES_LOADED,
  MEMBER_SUSPEND,
  MEMBER_RESTORE,
  MEMBER_SUSPEND_RESULT,
  MEMBER_RESTORE_RESULT,
  type Action,
} from "../actions";
import type { MemberId, Member, PlaceId } from "../models";
import { combineReducers } from "./utils";

export type State = {|
  byId: Map<MemberId, Member>,
  listIds: List<MemberId>,
  listLoaded: boolean,
  memberPlaceIds: Map<MemberId, List<PlaceId>>,
  isUpdatingById: Map<MemberId, boolean>,
|};

const memberReducer: Reducer<State, Action> = combineReducers({
  byId: (state = Map(), action) => {
    switch (action.type) {
      case MEMBER_LOADED: {
        const { member } = action.payload;

        return state.set(member.id, member);
      }

      case MEMBER_LIST_LOADED:
        return state.merge(action.payload.members);

      default:
        return state;
    }
  },
  listIds: (state = List(), action) => {
    switch (action.type) {
      case MEMBER_LIST_LOADED:
        return List(action.payload.members.keys());
      default:
        return state;
    }
  },
  listLoaded: (state = false, action) => {
    switch (action.type) {
      case MEMBER_LIST_LOADED:
        return true;
      default:
        return state;
    }
  },
  memberPlaceIds: (state = Map(), action) => {
    switch (action.type) {
      case MEMBER_PLACES_LOADED: {
        const { memberId, places } = action.payload;

        return state.set(memberId, List(places.keys()));
      }

      default:
        return state;
    }
  },
  isUpdatingById: (state = Map(), action) => {
    switch (action.type) {
      case MEMBER_SUSPEND:
      case MEMBER_RESTORE: {
        const { member } = action.payload;

        return state.set(member.id, true);
      }
      case MEMBER_SUSPEND_RESULT:
      case MEMBER_RESTORE_RESULT: {
        const { member } = action.payload;

        return state.set(member.id, false);
      }
      default:
        return state;
    }
  },
});

export default memberReducer;
