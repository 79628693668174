// @flow
import styled from "styled-components";
import { BREAKPOINT_SMALL } from "../constants";

/**
 * Expands to reasonable height on desktop. On mobile expands to fill the viewport.
 */
const MinHeightExpander = styled.div`
  display: flex;
  flex: 1;

  @media (min-width: ${BREAKPOINT_SMALL}px) {
    min-height: 600px;
  }
`;

export default MinHeightExpander;
