// @flow
import * as React from "react";
import styled from "styled-components";
import { Typography } from "material-ui";
import type { Member } from "../models";
import MemberAvatar from "./MemberAvatar";

type Props = {
  member: Member,
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 24px;
  padding-bottom: 16px;
`;

const AvatarContainer = styled.div`
  margin-bottom: 8px;
`;

const MemberDetailsHeader = ({ member }: Props) => (
  <Container>
    <AvatarContainer>
      <MemberAvatar size="large" member={member} />
    </AvatarContainer>
    <Typography gutterBottom variant="subheading" align="center">
      {member.getDisplayName()}
    </Typography>
    <Typography variant="caption" align="center">
      {member.getEmail()}
    </Typography>
  </Container>
);

export default MemberDetailsHeader;
