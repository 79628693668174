// @flow
import * as React from "react";
import yup from "yup";
import { compose } from "recompose";
import { translate, type TranslatorProps } from "react-i18next";
import { withFormik, type FormikProps } from "formik";
import Typography from "material-ui/Typography";
import type { ApplicationFields } from "../models";
import {
  MAX_LENGTH_FULL_NAME,
  MAX_LENGTH_ORGANIZATION_NAME,
  MAX_LENGTH_EMAIL,
} from "../constants";
import Layout from "./Layout";
import Button from "./Button";
import TextField from "./TextField";

type Props = {|
  ...$Exact<TranslatorProps>,
  ...FormikProps<ApplicationFields>,
  header?: React.Node,
  submitLabel?: React.Node,
  submitting?: boolean,
  onSubmit?: {},
|};

const defaultProps = {
  header: null,
  submitLabel: null,
  submitting: false,
  onSubmit: () => {},
};

const enhance = compose(
  translate(),
  withFormik({
    mapPropsToValues: ({ application }) =>
      application
        ? {
            fullName: application.fullName,
            organizationName: application.organizationName,
            email: application.email,
          }
        : { fullName: "", organizationName: "", email: "" },
    validationSchema: ({ t }) =>
      yup.object().shape({
        fullName: yup
          .string()
          .trim()
          .required(t("validations:isEmpty"))
          .max(
            MAX_LENGTH_FULL_NAME,
            t("validations:tooLong", { max: MAX_LENGTH_FULL_NAME })
          ),
        organizationName: yup
          .string()
          .trim()
          .required(t("validations:isEmpty"))
          .max(
            MAX_LENGTH_ORGANIZATION_NAME,
            t("validations:tooLong", { max: MAX_LENGTH_ORGANIZATION_NAME })
          ),
        email: yup
          .string()
          .trim()
          .required(t("validations:isEmpty"))
          .max(
            MAX_LENGTH_EMAIL,
            t("validations:tooLong", { max: MAX_LENGTH_EMAIL })
          )
          .email(t("validations:invalidEmail")),
      }),
    handleSubmit: (values, { props }) => props.onSubmit(values),
  })
);

const ApplicationForm = ({
  submitting,
  t,
  values,
  touched,
  errors,
  handleChange,
  handleBlur,
  handleSubmit,
  submitLabel,
}: Props) => (
  <Layout.Content>
    <form onSubmit={handleSubmit}>
      <Layout.Block>
        <Typography>{t("signup.form_header")}</Typography>
      </Layout.Block>
      <Layout.Block>
        <TextField
          autoFocus
          gutterBottom
          id="application_full_name"
          name="fullName"
          autocomplete={false}
          label={t("fields:applications.full_name")}
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.fullName}
          error={touched.fullName && errors.fullName}
          disabled={submitting}
        />
        <TextField
          gutterBottom
          id="application_organization_name"
          name="organizationName"
          autocomplete={false}
          label={t("fields:applications.organization_name")}
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.organizationName}
          error={touched.organizationName && errors.organizationName}
          disabled={submitting}
        />
        <TextField
          gutterBottom
          type="email"
          id="application_email"
          name="email"
          autocomplete={false}
          label={t("fields:applications.email")}
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.email}
          error={touched.email && errors.email}
          disabled={submitting}
        />
      </Layout.Block>
      <Layout.Block>
        <Typography>{t("signup.form_footer")}</Typography>
      </Layout.Block>
      <Layout.Block>
        <Button raised color="primary" loading={submitting} type="submit">
          {submitLabel}
        </Button>
      </Layout.Block>
    </form>
  </Layout.Content>
);

ApplicationForm.defaultProps = defaultProps;

export default enhance(ApplicationForm);
