// @flow
import * as React from "react";
import type { List } from "immutable";
import type { HOC } from "recompose";
import { connect } from "react-redux";
import { makeGetPlaceEditState } from "../selectors";
import {
  placeChangeDetails,
  placeChangeAddress,
  placeChangeCoordinates,
  placeAddressSuggestionSelected,
  placePhotoMove,
  placeRemovePhotos,
  placeUploadPhotos,
  placeChangeSchedule,
  placeUpdate,
  placeCreate,
} from "../actions";
import type {
  PlaceId,
  PlaceDetails,
  PlaceDetailsFields,
  PlaceLocationFields,
  PlaceAddress,
  PlaceAddressFields,
  PlaceCoordinates,
  PlaceCoordinatesFields,
  AddressSuggestion,
  PlacePhotoFields,
  PlaceEditPhoto,
  PlaceSchedule,
  PlaceScheduleFields,
} from "../models";

type RenderProps = {
  details: PlaceDetails,
  address: ?PlaceAddress,
  coordinates: ?PlaceCoordinates,
  addressSuggestions: List<AddressSuggestion>,
  addressSuggestionsLoading: boolean,
  geocodeLoading: boolean,
  photos: List<PlaceEditPhoto>,
  schedule: PlaceSchedule,
  changeDetails: (value: PlaceDetailsFields) => void,
  changeAddress: (value: PlaceAddressFields) => void,
  changeCoordinates: (value: PlaceCoordinatesFields) => void,
  selectAddressSuggestion: (suggestion: AddressSuggestion) => void,
  photoMove: (index: number, newIndex: number) => void,
  removePhotos: (photos: List<PlaceEditPhoto>) => void,
  uploadPhotos: (files: Array<File>) => void,
  changeSchedule: (value: PlaceScheduleFields) => void,
  createPlace: (
    details: PlaceDetailsFields,
    location: PlaceLocationFields,
    photos: PlacePhotoFields[],
    schedule: PlaceScheduleFields
  ) => void,
  updatePlace: (updates: {
    details?: PlaceDetailsFields,
    location?: PlaceLocationFields,
    photos?: PlacePhotoFields[],
    schedule?: PlaceScheduleFields,
  }) => void,
};

type Props = {
  id?: ?PlaceId,
  render: (props: RenderProps) => React.Node,
};

const enhance: HOC<*, Props> = connect(
  makeGetPlaceEditState,
  {
    placeChangeDetails,
    placeChangeAddress,
    placeChangeCoordinates,
    placeAddressSuggestionSelected,
    placePhotoMove,
    placeRemovePhotos,
    placeUploadPhotos,
    placeChangeSchedule,
    placeCreate,
    placeUpdate,
  }
);

type HOCBase_<A, B, C: HOC<A, B>> = A; // eslint-disable-line no-unused-vars
type HOCBase<C> = HOCBase_<*, *, C>;

class PlaceEditContainer extends React.Component<HOCBase<typeof enhance>> {
  handleChangeDetails = (value: PlaceDetailsFields) =>
    this.props.placeChangeDetails(this.props.id, value);

  handleChangeAddress = (value: PlaceAddressFields) =>
    this.props.placeChangeAddress(this.props.id, value);

  handleChangeCoordinates = (value: PlaceCoordinatesFields) =>
    this.props.placeChangeCoordinates(this.props.id, value);

  handleSelectAddressSuggestion = (suggestion: AddressSuggestion) =>
    this.props.placeAddressSuggestionSelected(this.props.id, suggestion);

  handlePhotoMove = (index: number, newIndex: number) =>
    this.props.placePhotoMove(
      this.props.id,
      index,
      newIndex,
      this.props.photos
    );

  handleRemovePhotos = (selectedPhotos: List<PlaceEditPhoto>) =>
    this.props.placeRemovePhotos(
      this.props.id,
      selectedPhotos,
      this.props.photos
    );

  handleUploadPhotos = (files: Array<File>) =>
    this.props.placeUploadPhotos(this.props.id, files, this.props.photos);

  handleChangeSchedule = (value: PlaceScheduleFields) =>
    this.props.placeChangeSchedule(this.props.id, value);

  handleUpdatePlace = updates => this.props.placeUpdate(this.props.id, updates);

  handleCreatePlace = (details, location, photos, schedule) =>
    this.props.placeCreate(details, location, photos, schedule);

  render() {
    const {
      render,
      details,
      address,
      coordinates,
      addressSuggestions,
      addressSuggestionsLoading,
      geocodeLoading,
      photos,
      schedule,
    } = this.props;

    return render({
      details,
      address,
      coordinates,
      addressSuggestions,
      addressSuggestionsLoading,
      geocodeLoading,
      photos,
      schedule,
      changeDetails: this.handleChangeDetails,
      changeAddress: this.handleChangeAddress,
      changeCoordinates: this.handleChangeCoordinates,
      selectAddressSuggestion: this.handleSelectAddressSuggestion,
      photoMove: this.handlePhotoMove,
      removePhotos: this.handleRemovePhotos,
      uploadPhotos: this.handleUploadPhotos,
      changeSchedule: this.handleChangeSchedule,
      updatePlace: this.handleUpdatePlace,
      createPlace: this.handleCreatePlace,
    });
  }
}

export default enhance(PlaceEditContainer);
