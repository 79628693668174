/* eslint-disable react/no-multi-comp */
// @flow
import invariant from "invariant";
import * as React from "react";
import createReactContext, { type Context } from "create-react-context";
import {
  format,
  formatDistance,
  formatDistanceStrict,
  formatRelative,
} from "date-fns";
import ru from "date-fns/esm/locale/ru";
import en from "date-fns/esm/locale/en-US";

type Locale = Object;

type ProviderProps = {
  language: string,
  children: React.Node,
};

type RenderProps = {
  format: typeof format,
  formatDistance: typeof formatDistance,
  formatDistanceStrict: typeof formatDistanceStrict,
  formatRelative: typeof formatRelative,
};

const DateFnsContext: Context<RenderProps> = createReactContext({
  format,
  formatDistance,
  formatDistanceStrict,
  formatRelative,
});

const fetchLocale = (language: string): Promise<{ default: Locale }> =>
  Promise.resolve({ default: language === "ru" ? ru : en });

// // Broken...
// const fetchLocale = (language: string): Promise<{ default: Locale }> => {
//   switch (language) {
//     case "ru":
//       return import("date-fns/esm/locale/ru");
//     case "en-US":
//       return import("date-fns/esm/locale/en-US");
//     case "en-GB":
//       return import("date-fns/esm/locale/en-GB");
//     default:
//       return import("date-fns/esm/locale/en-US");
//   }
// };

class DateFnsProvider extends React.Component<
  ProviderProps,
  {
    ready: boolean,
    locale: ?Locale,
  }
> {
  static defaultProps = {
    language: "en",
  };

  constructor(props: ProviderProps) {
    super(props);

    if (props.language === "en") {
      this.state = {
        ready: true,
        locale: null,
      };
    } else {
      this.state = {
        ready: false,
        locale: null,
      };
    }
  }

  componentDidMount() {
    const { language } = this.props;

    invariant(language, "Language must be set");

    if (language === "en") {
      return;
    }

    fetchLocale(language).then(module => {
      this.setState({
        ready: true,
        locale: module.default,
      });
    });
  }

  injectLocale = (options: ?Object) => ({
    ...options,
    locale: this.state.locale,
  });

  localizedFormat: typeof format = (date, formatString, options) =>
    format(date, formatString, this.injectLocale(options));

  localizedFormatDistance: typeof formatDistance = (date, baseDate, options) =>
    formatDistance(date, baseDate, this.injectLocale(options));

  localizedFormatDistanceStrict: typeof formatDistanceStrict = (
    date,
    baseDate,
    options
  ) => formatDistanceStrict(date, baseDate, this.injectLocale(options));

  localizedFormatRelative: typeof formatRelative = (date, baseDate, options) =>
    formatRelative(date, baseDate, this.injectLocale(options));

  dateFns = {
    format: this.localizedFormat,
    formatDistance: this.localizedFormatDistance,
    formatDistanceStrict: this.localizedFormatDistanceStrict,
    formatRelative: this.localizedFormatRelative,
  };

  render() {
    if (!this.state.ready) {
      return false;
    }

    return (
      <DateFnsContext.Provider value={this.dateFns}>
        {this.props.children}
      </DateFnsContext.Provider>
    );
  }
}

class DateFns extends React.Component<{
  children: (props: RenderProps) => React.Node,
}> {
  static Provider = DateFnsProvider;

  render() {
    return (
      <DateFnsContext.Consumer>{this.props.children}</DateFnsContext.Consumer>
    );
  }
}

export default DateFns;
