"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});


/**
 * This function is meant to be called from other API methods.
 *
 * It creates a member representation that can be embedded into other entities.
 */
var denormalizeMember = function denormalizeMember(member) {
  return {
    id: member.id,
    displayName: member.displayName || null,
    email: member.email || null,
    photoURL: member.photoURL || null
  };
};
exports.default = denormalizeMember;