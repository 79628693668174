// @flow
import styled from "styled-components";

// Layout.Block must be a direct descendant of Layout.Content
const Block = styled.div`
  flex-shrink: 0;
  margin-bottom: 24px;
`;

Block.displayName = "Block";

export default Block;
