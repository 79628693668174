// @flow
import * as React from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import type { List } from "immutable";
import {
  memberShown,
  memberHidden,
  memberUpdate,
  memberSuspend,
  memberRestore,
} from "../actions";
import {
  makeGetMember,
  makeGetMemberPlaceIds,
  makeIsMemberLoaded,
  makeIsMemberPlacesLoaded,
  makeIsMemberUpdating,
} from "../selectors";
import { withSideEffects } from "../hocs";
import type { Member, MemberId, MemberFields, PlaceId } from "../models";

type RenderProps = {
  member: Member,
  placeIds: List<PlaceId>,
  isMemberLoaded: boolean,
  isPlacesLoaded: boolean,
  isMemberUpdating: boolean,
  updateMember: (update: $Shape<MemberFields>) => void,
  suspendMember: () => void,
  restoreMember: () => void,
};

type Props = {
  ...$Exact<RenderProps>,
  id: MemberId,
  render: (props: RenderProps) => React.Node,
  memberUpdate: typeof memberUpdate,
  memberSuspend: typeof memberSuspend,
  memberRestore: typeof memberRestore,
};

const makeMapStateToProps = () =>
  createStructuredSelector({
    member: makeGetMember(),
    placeIds: makeGetMemberPlaceIds(),
    isMemberLoaded: makeIsMemberLoaded(),
    isPlacesLoaded: makeIsMemberPlacesLoaded(),
    isMemberUpdating: makeIsMemberUpdating(),
  });

const enhance = compose(
  connect(
    makeMapStateToProps,
    {
      memberShown,
      memberHidden,
      memberUpdate,
      memberSuspend,
      memberRestore,
    }
  ),
  withSideEffects({
    onMount: ({ id, memberShown }) => memberShown(id),
    onUnmount: ({ id, memberHidden }) => memberHidden(id),
  })
);

class MemberContainer extends React.Component<Props> {
  handleUpdateMember = (update: $Shape<MemberFields>) => {
    const { member, memberUpdate } = this.props;

    memberUpdate(member, update);
  };

  handleSuspendMember = () => {
    const { member, memberSuspend } = this.props;

    memberSuspend(member);
  };

  handleRestoreMember = () => {
    const { member, memberRestore } = this.props;

    memberRestore(member);
  };

  render() {
    const {
      render,
      member,
      placeIds,
      isMemberLoaded,
      isPlacesLoaded,
      isMemberUpdating,
    } = this.props;

    return render({
      member,
      placeIds,
      isMemberLoaded,
      isPlacesLoaded,
      isMemberUpdating,
      updateMember: this.handleUpdateMember,
      suspendMember: this.handleSuspendMember,
      restoreMember: this.handleRestoreMember,
    });
  }
}

export default enhance(MemberContainer);
