// @flow
import * as React from "react";
import styled from "styled-components";
import { translate, type TranslatorProps } from "react-i18next";
import { Typography } from "material-ui";
import { grey } from "material-ui/colors";

type Props = {
  ...$Exact<TranslatorProps>,
};

const Container = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${grey[50]};
`;

const enhance = translate();

const EmptyListPlaceholder = ({ t }: Props) => (
  <Container>
    <Typography variant="caption">{t("general.empty_list")}</Typography>
  </Container>
);

export default enhance(EmptyListPlaceholder);
