// @flow
import * as React from "react";
import { Switch, Route, Redirect } from "react-router";
import { PublicLayout } from "../components";
import { SignupContainer } from "../containers";
import { ROOT, APPLICATION_CREATE, APPLICATION_UPDATE } from "../routes";

const SignupScreen = () => (
  <PublicLayout>
    <Switch>
      <Route exact path={APPLICATION_CREATE} component={SignupContainer} />
      <Route
        exact
        path={APPLICATION_UPDATE}
        render={() => <SignupContainer update />}
      />
      <Redirect to={ROOT} />
    </Switch>
  </PublicLayout>
);

export default SignupScreen;
