// @flow
import * as React from "react";
import styled from "styled-components";
import { compose, withHandlers } from "recompose";
import { I18n } from "react-i18next";
import { IconButton, Typography } from "material-ui";
import {
  DeleteForever as DeleteForeverIcon,
  ThumbUp as ThumbUpIcon,
} from "material-ui-icons";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { Button, DateFns, ListItem } from "../components";
import { makeGetApplication, makeIsSubmittingResolution } from "../selectors";
import type { Application, ApplicationId } from "../models";

type Props = {
  style: Object,
  id: ApplicationId,
  application: Application,
  submitting: boolean,
  onApproveClick: (application: Application) => void,
  onRejectClick: (application: Application) => void,
  handleApproveClick: (application: Application) => void,
  handleRejectClick: (application: Application) => void,
};

const Details = styled.div`
  marginbottom: 8px;
`;

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const makeMapStateToProps = () =>
  createStructuredSelector({
    application: makeGetApplication(),
    submitting: makeIsSubmittingResolution(),
  });

const enhance = compose(
  connect(
    makeMapStateToProps,
    null
  ),
  withHandlers({
    handleApproveClick: ({ onApproveClick, application }) => () =>
      onApproveClick(application),
    handleRejectClick: ({ onRejectClick, application }) => () =>
      onRejectClick(application),
  })
);

const ApplicationListItemContainer = ({
  application,
  submitting,
  handleApproveClick,
  handleRejectClick,
  style,
}: Props) => (
  <I18n>
    {t => (
      <DateFns>
        {d => (
          <ListItem borderBottom style={style}>
            <Details>
              <Typography>
                {application.fullName}
                <Typography variant="caption" style={{ float: "right" }}>
                  &nbsp;
                  {d.formatRelative(application.createdAt, Date.now())}
                </Typography>
              </Typography>
              <Typography variant="caption">{application.email}</Typography>
              <Typography variant="caption">
                {application.organizationName}
              </Typography>
            </Details>
            <Actions>
              {submitting ? null : (
                <IconButton
                  aria-label={t("applications.list.reject_action")}
                  onClick={handleRejectClick}
                >
                  <DeleteForeverIcon />
                </IconButton>
              )}
              <Button
                raised
                color="primary"
                size="small"
                icon={ThumbUpIcon}
                onClick={handleApproveClick}
                loading={submitting}
              >
                {t("applications.list.approve_action")}
              </Button>
            </Actions>
          </ListItem>
        )}
      </DateFns>
    )}
  </I18n>
);

export default enhance(ApplicationListItemContainer);
