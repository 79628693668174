// @flow
import * as React from "react";
import { translate, type TranslatorProps } from "react-i18next";
import ReportProblemIcon from "material-ui-icons/ReportProblem";
import { ErrorLayout } from "../components";

type Props = {
  ...$Exact<TranslatorProps>,
};

const enhance = translate();

const NotFoundScreen = ({ t }: Props) => (
  <ErrorLayout icon={ReportProblemIcon} subheading={t("errors.not_found")} />
);

export default enhance(NotFoundScreen);
