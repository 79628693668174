// @flow
import * as React from "react";
import { compose } from "recompose";
import { withRouter, type ContextRouter } from "react-router";
import { translate, type TranslatorProps } from "react-i18next";
import { Typography, Tabs, Tab } from "material-ui";
import {
  Place as PlaceIcon,
  Directions as DirectionsIcon,
  Public as PublicIcon,
  Phone as PhoneIcon,
  Edit as EditIcon,
  PhotoCamera as PhotoCameraIcon,
  Schedule as ScheduleIcon,
} from "material-ui-icons";
import {
  PlaceDetailsHeader,
  Toolbar,
  List,
  ListItem,
  Content,
} from "../components";
import {
  formatRoute,
  PLACE_LIST,
  PLACE_EDIT_DETAILS,
  PLACE_EDIT_ADDRESS,
  PLACE_EDIT_PHOTOS,
  PLACE_EDIT_SCHEDULE,
} from "../routes";
import type { PlaceId } from "../models";
import PlaceContainer from "./PlaceContainer";

type Props = {
  ...$Exact<ContextRouter>,
  ...$Exact<TranslatorProps>,
  id: PlaceId,
};

const enhance = compose(
  translate(),
  withRouter
);

const PlaceDetailsContaienr = ({ t, history, id }: Props) => (
  <PlaceContainer
    id={id}
    render={({ place }) => (
      <React.Fragment>
        <PlaceDetailsHeader place={place}>
          <Toolbar
            inverse
            onGoBack={() => history.push(PLACE_LIST)}
            title={place.getTitle()}
          />
        </PlaceDetailsHeader>
        <Tabs fullWidth value={false}>
          <Tab
            component="a"
            icon={<PlaceIcon />}
            label={t("places.preview.map")}
            href={place.getMapsUrl()}
            target="_blank"
          />
          <Tab
            component="a"
            icon={<DirectionsIcon />}
            label={t("places.preview.directions")}
            href={place.getDirectionsUrl()}
            target="_blank"
          />
          <Tab
            component="a"
            icon={<PublicIcon />}
            label={t("places.preview.website")}
            href={place.getWebsiteUrl()}
            target="_blank"
          />
          <Tab
            component="a"
            icon={<PhoneIcon />}
            label={t("places.preview.call")}
            href={place.getDialUrl()}
            target="_blank"
          />
        </Tabs>
        <Content>
          <Typography>{place.getDescription()}</Typography>
        </Content>
        <List>
          <ListItem
            button
            icon={<EditIcon />}
            label={t("places.preview.edit_details")}
            link={formatRoute(PLACE_EDIT_DETAILS, { id: place.getId() })}
          />
          <ListItem
            button
            icon={<PlaceIcon />}
            label={t("places.preview.edit_address")}
            link={formatRoute(PLACE_EDIT_ADDRESS, { id: place.getId() })}
          />
          <ListItem
            button
            icon={<PhotoCameraIcon />}
            label={t("places.preview.edit_photos")}
            link={formatRoute(PLACE_EDIT_PHOTOS, { id: place.getId() })}
          />
          <ListItem
            button
            icon={<ScheduleIcon />}
            label={t("places.preview.edit_schedule")}
            link={formatRoute(PLACE_EDIT_SCHEDULE, { id: place.getId() })}
          />
        </List>
      </React.Fragment>
    )}
  />
);

export default enhance(PlaceDetailsContaienr);
