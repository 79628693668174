// @flow
import { OrderedMap } from "immutable";

/**
 * Converts an array of entities into an OrderedMap indexed by id
 */
const normalizeEntities = <A, B: { id: A }>(entities: B[]): OrderedMap<A, B> =>
  entities.reduce(
    (result, entity) => result.set(entity.id, entity),
    OrderedMap()
  );

export default normalizeEntities;
