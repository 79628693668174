// @flow
import * as React from "react";
import { compose, pure } from "recompose";
import { translate, type TranslatorProps } from "react-i18next";
import Toolbar from "./Toolbar";
import Button from "./Button";

type Props = {
  ...$Exact<TranslatorProps>,
  canSubmit: boolean,
  onSubmit: (e: SyntheticEvent<HTMLButtonElement>) => void,
  onCancel: (e: SyntheticEvent<HTMLButtonElement>) => void,
};

const defaultProps = {
  canSubmit: true,
};

const enhance = compose(
  pure,
  translate()
);

const FormActionsToolbar = ({ t, onSubmit, onCancel, canSubmit }: Props) => (
  <Toolbar
    actions={[
      <Button color="inherit" onClick={onCancel}>
        {t("general.cancel")}
      </Button>,
      <Button
        type="submit"
        color="primary"
        onClick={onSubmit}
        disabled={!canSubmit}
      >
        {t("general.save")}
      </Button>,
    ]}
  />
);

FormActionsToolbar.defaultProps = defaultProps;

export default enhance(FormActionsToolbar);
