"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _Map = require("./Map");

Object.defineProperty(exports, "default", {
  enumerable: true,
  get: function get() {
    return _interopRequireDefault(_Map).default;
  }
});

var _Yandex = require("./Yandex");

Object.defineProperty(exports, "YandexAdapter", {
  enumerable: true,
  get: function get() {
    return _interopRequireDefault(_Yandex).default;
  }
});

var _Google = require("./Google");

Object.defineProperty(exports, "GoogleAdapter", {
  enumerable: true,
  get: function get() {
    return _interopRequireDefault(_Google).default;
  }
});

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }