// @flow
import * as React from "react";
import { translate, type TranslatorProps } from "react-i18next";
import Typography from "material-ui/Typography";
import Layout from "../Layout";
import Button from "../Button";
import type { Application } from "../../models";
import Status from "./Status";
import Comment from "./Comment";

type Props = {
  ...$Exact<TranslatorProps>,
  application: Application,
  showUpdateButton: boolean,
  updateButtonLabel: React.Node,
  onUpdateButtonClick: () => void,
};

const enhance = translate();

const ApplicationDetails = ({
  t,
  application,
  showUpdateButton,
  updateButtonLabel,
  onUpdateButtonClick,
}: Props) => {
  let legend;

  if (application.resolution) {
    if (application.resolution.approved) {
      legend = t("signup.legend.approved");
    } else {
      legend = t("signup.legend.rejected");
    }
  } else {
    legend = t("signup.legend.pending");
  }

  return (
    <Layout.Content>
      <Layout.Block>
        <Status
          type="body2"
          align="center"
          resolved={application.resolution}
          approved={application.resolution && application.resolution.approved}
        />
      </Layout.Block>
      <Layout.Block>
        <Typography>{legend}</Typography>
      </Layout.Block>
      <Layout.Block>
        <Typography gutterBottom type="subheading">
          {t("signup.details_label")}
        </Typography>
        <Layout.Row>
          <Typography gutterBottom type="caption">
            {t("fields:applications.full_name")}
          </Typography>
          <Typography type="body1">{application.fullName}</Typography>
        </Layout.Row>
        <Layout.Row>
          <Typography gutterBottom type="caption">
            {t("fields:applications.organization_name")}
          </Typography>
          <Typography type="body1">{application.organizationName}</Typography>
        </Layout.Row>
        <Layout.Row>
          <Typography gutterBottom type="caption">
            {t("fields:applications.email")}
          </Typography>
          <Typography type="body1">{application.email}</Typography>
        </Layout.Row>
      </Layout.Block>
      {application.resolution && application.resolution.comment ? (
        <Layout.Block>
          <Typography gutterBottom type="subheading">
            {t("signup.comment_label")}
          </Typography>
          <Comment>
            {application.resolution && application.resolution.comment}
          </Comment>
        </Layout.Block>
      ) : null}
      {showUpdateButton && (
        <Layout.Block>
          <Button raised onClick={onUpdateButtonClick}>
            {updateButtonLabel}
          </Button>
        </Layout.Block>
      )}
    </Layout.Content>
  );
};

export default enhance(ApplicationDetails);
