// @flow
import * as React from "react";
import styled from "styled-components";
import Button from "material-ui/Button";
import {
  compose,
  defaultProps as withDefaultProps,
  withPropsOnChange,
  pure,
} from "recompose";
import { withStaticHandlers } from "../hocs/index";

type Props = {
  style: Object,
  innerRef: React.Ref<"div">,
  icon: React.Node,
  color: "default" | "primary" | "secondary",
  size: "small" | "medium" | "large",
  containerComponent: React.ElementType,
  containerProps: Object,
};

const defaultProps = {
  color: "default",
  size: "medium",
  component: "button",
  containerComponent: "div",
  containerProps: {},
};

const Container = styled.div`
  position: fixed;
  bottom: 24px;
  right: 24px;
`;

const enhance = compose(
  withDefaultProps(defaultProps),
  // Ignore changes to onClick when the function was created in the render method
  withStaticHandlers(["onClick"]),
  withPropsOnChange(["containerComponent"], ({ containerComponent }) => ({
    containerComponent: Container.withComponent(containerComponent),
  })),
  pure
);

const FloatingActionButton = ({
  style,
  innerRef,
  icon,
  color,
  size,
  containerComponent: ContainerComponent,
  containerProps,
  ...rest
}: Props) => (
  <ContainerComponent style={style} innerRef={innerRef} {...containerProps}>
    <Button {...rest} variant="fab" color={color} size={size}>
      {icon}
    </Button>
  </ContainerComponent>
);

export default enhance(FloatingActionButton);
