// @flow
import * as React from "react";
import { I18n } from "react-i18next";
import type { Member } from "../models";
import ListItem from "./ListItem";
import MemberAvatar from "./MemberAvatar";
import DateFns from "./DateFns";
import { formatRoute, MEMBER_DETAILS } from "../routes";

type Props = {
  ...$Exact<React.ElementProps<typeof ListItem>>,
  author: Member,
  createdAt: number,
  updatedAt: number,
};

const AttributionBlock = ({ member, updatedAt, createdAt, ...rest }: Props) => (
  <DateFns>
    {d => (
      <I18n>
        {t => (
          <ListItem
            {...rest}
            button
            link={formatRoute(MEMBER_DETAILS, { id: member.id })}
            avatar={<MemberAvatar member={member} />}
            label={member.getDisplayName()}
            secondaryText={
              updatedAt > createdAt
                ? t("general.updated_at", {
                    time: d.formatRelative(updatedAt, Date.now()),
                  })
                : t("general.created_at", {
                    time: d.formatRelative(createdAt, Date.now()),
                  })
            }
          />
        )}
      </I18n>
    )}
  </DateFns>
);

export default AttributionBlock;
