// @flow
import * as React from "react";
import { setDisplayName, wrapDisplayName } from "recompose";

type InProps = {
  in: boolean,
  onEntered?: () => void,
  onExited?: () => void,
};

type AnimatedRef = (node: HTMLElement) => void;

const withAnimationEndCallbacks = <T: InProps>(
  BaseComponent: React.ComponentType<{
    ...$Exact<T>,
    animatedRef: AnimatedRef,
  }>
) => {
  const factory = React.createFactory(BaseComponent);

  class WithAnimationEndCallback extends React.Component<T> {
    node: HTMLElement | null = null;
    animatedRef: AnimatedRef;

    handleAnimationEnd = (event: AnimationEvent) => {
      if (event.target !== this.node) {
        return;
      }

      if (this.props.in) {
        if (this.props.onEntered) {
          this.props.onEntered();
        }

        return;
      }

      if (this.props.onExited) {
        this.props.onExited();
      }
    };

    animatedRef = (node: HTMLElement) => {
      if (this.node !== null) {
        this.node.removeEventListener("animationend", this.handleAnimationEnd);
      }

      this.node = node;

      if (node === null) {
        return;
      }

      node.addEventListener("animationend", this.handleAnimationEnd, false);
    };

    render() {
      return factory({
        ...this.props,
        animatedRef: this.animatedRef,
      });
    }
  }

  if (process.env.NODE_ENV !== "production") {
    return setDisplayName(
      wrapDisplayName(BaseComponent, "withAnimationEndCallback")
    )(WithAnimationEndCallback);
  }

  return WithAnimationEndCallback;
};

export default withAnimationEndCallbacks;
