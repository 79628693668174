// @flow
import * as React from "react";
import { compose, pure } from "recompose";
import { translate, type TranslatorProps } from "react-i18next";
import { IconButton } from "material-ui";
import {
  Delete as DeleteIcon,
  FileUpload as FileUploadIcon,
} from "material-ui-icons";
import Toolbar from "./Toolbar";
import Dropzone from "./Dropzone";

type Props = {
  ...$Exact<TranslatorProps>,
  title: React.Node,
  showUpload?: boolean,
  showRemove?: boolean,
  onUpload?: ?(files: Array<File>) => void,
  onRemoveSelected?: ?(e: SyntheticEvent<HTMLButtonElement>) => void,
  onGoBack?: ?() => void,
};

const defaultProps = {
  showUpload: false,
  showRemove: false,
  onUpload: null,
  onRemoveSelected: null,
  onGoBack: null,
};

const enhance = compose(
  pure,
  translate()
);

const PlacePhotosToolbar = ({
  t,
  title,
  showUpload,
  showRemove,
  onUpload,
  onRemoveSelected,
  onGoBack,
}: Props) => (
  <Toolbar
    title={title}
    actions={[
      showUpload ? (
        <Dropzone disableClick multiple accept="image/*" onUpload={onUpload}>
          {({ open }) => (
            <IconButton
              component="span"
              aria-label={t("places.photos.upload")}
              onClick={open}
            >
              <FileUploadIcon />
            </IconButton>
          )}
        </Dropzone>
      ) : null,
      showRemove ? (
        <IconButton
          component="span"
          aria-label={t("places.photos.remove_selected")}
          onClick={onRemoveSelected}
        >
          <DeleteIcon />
        </IconButton>
      ) : null,
    ]}
    onGoBack={onGoBack}
  />
);

PlacePhotosToolbar.defaultProps = defaultProps;

export default enhance(PlacePhotosToolbar);
