// @flow
import * as React from "react";
import { compose } from "recompose";
import { withRouter, type ContextRouter } from "react-router";
import { translate, type TranslatorProps } from "react-i18next";
import {
  Wizard,
  Form,
  Toolbar,
  PlaceDetailsForm,
  PlaceGallery,
  PlacePhotosToolbar,
  ScheduleForm,
  AddressForm,
} from "../components";
import { LayoutContainer, PlaceEditContainer } from "../containers";
import { PLACE_LIST } from "../routes";

type Props = {
  ...$Exact<ContextRouter>,
  ...$Exact<TranslatorProps>,
};

const enhance = compose(
  withRouter,
  translate()
);

class PlaceCreateScreen extends React.Component<Props> {
  goBack = () => {
    const { history } = this.props;

    history.push(PLACE_LIST);
  };

  detailsPage = ({ render, onSubmit, onGoBack }) => {
    const { t } = this.props;

    return (
      <PlaceDetailsForm
        autoFocus
        onSubmit={onSubmit}
        layout={({ children, submitForm }) =>
          render({
            // Allow user to press next button to see form errors
            isValid: true,
            children,
            onNext: submitForm,
            extra: {
              toolbar: (
                <Toolbar onGoBack={onGoBack} title={t("places.title.create")} />
              ),
            },
          })
        }
      />
    );
  };

  locationPage = ({ render, onSubmit, onGoBack }) => {
    const { t } = this.props;

    return (
      <PlaceEditContainer
        render={({
          address,
          coordinates,
          addressSuggestions,
          addressSuggestionsLoading,
          geocodeLoading,
          changeAddress,
          changeCoordinates,
          selectAddressSuggestion,
        }) => (
          <AddressForm
            autoFocus
            loading={addressSuggestionsLoading || geocodeLoading}
            address={address}
            coordinates={coordinates}
            suggestions={addressSuggestions}
            onAddressChange={changeAddress}
            onCoordinatesChange={changeCoordinates}
            onSuggestionSelect={selectAddressSuggestion}
            onSubmit={onSubmit}
            render={({ children, onSubmit }) =>
              render({
                isValid: address && coordinates,
                children,
                onNext: onSubmit,
                extra: {
                  toolbar: (
                    <Toolbar
                      onGoBack={onGoBack}
                      title={t("places.title.location")}
                    />
                  ),
                },
              })
            }
          />
        )}
      />
    );
  };

  photosPage = ({ render, onSubmit, onGoBack }) => {
    const { t } = this.props;

    return (
      <PlaceEditContainer
        render={({ photos, photoMove, removePhotos, uploadPhotos }) => (
          <PlaceGallery
            photos={photos}
            onUpload={uploadPhotos}
            onRemovePhotos={removePhotos}
            onMovePhoto={photoMove}
            onSubmit={onSubmit}
            render={({
              uploadingPhotos,
              selectedPhotos,
              resetSelectedPhotos,
              removeSelectedPhotos,
              onSubmit,
              children,
            }) => {
              const hasSelectedPhotos = selectedPhotos.size > 0;

              return render({
                isValid: photos.size > 0 && uploadingPhotos.size === 0,
                children,
                onNext: onSubmit,
                extra: {
                  toolbar: (
                    <PlacePhotosToolbar
                      title={
                        hasSelectedPhotos
                          ? t("general.photos_selected", {
                              count: selectedPhotos.size,
                            })
                          : t("places.title.photos")
                      }
                      showUpload={!hasSelectedPhotos}
                      showRemove={hasSelectedPhotos}
                      onGoBack={
                        hasSelectedPhotos ? resetSelectedPhotos : onGoBack
                      }
                      onUpload={uploadPhotos}
                      onRemoveSelected={removeSelectedPhotos}
                    />
                  ),
                },
              });
            }}
          />
        )}
      />
    );
  };

  schedulePage = ({ render, onSubmit, onGoBack }) => {
    const { t } = this.props;

    return (
      <ScheduleForm
        onSubmit={onSubmit}
        render={({ children, onSubmit }) =>
          render({
            isValid: true,
            children,
            onNext: onSubmit,
            extra: {
              toolbar: (
                <Toolbar
                  onGoBack={onGoBack}
                  title={t("places.title.schedule")}
                />
              ),
            },
          })
        }
      />
    );
  };

  handleSubmit = (createPlace, [details, location, photos, schedule]) =>
    createPlace(details, location, photos, schedule);

  render() {
    return (
      <PlaceEditContainer
        render={({ createPlace }) => (
          <Wizard
            onGoPastBack={this.goBack}
            pages={[
              this.detailsPage,
              this.locationPage,
              this.photosPage,
              this.schedulePage,
            ]}
            render={({ children, extra, onSubmit }) => (
              <LayoutContainer toolbar={extra.toolbar}>
                <Form preventDefault onSubmit={onSubmit}>
                  {children}
                </Form>
              </LayoutContainer>
            )}
            onSubmit={
              // TODO: Extract PlaceCreateWizard from this component to get rid of this garbage
              data => this.handleSubmit(createPlace, data)
            }
          />
        )}
      />
    );
  }
}

export default enhance(PlaceCreateScreen);
