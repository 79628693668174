// @flow
import * as React from "react";
import styled from "styled-components";
import { SnackbarContent } from "material-ui/Snackbar";
import { ClickAwayListener } from "material-ui";

const noop = () => {};

type Props = {|
  ...React.ElementProps<typeof SnackbarContent>,
  open?: boolean,
  message?: string,
  action?: React.Node,
  autoHideDuration?: number,
  onClose?: () => void,
  onExited?: () => void,
|};

type State = {|
  height: number,
|};

const defaultProps = {
  open: false,
  message: null,
  action: null,
  autoHideDuration: null,
  onClose: noop,
  onClick: noop,
  onEntered: noop,
  onExited: noop,
};

const Container = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  max-height: ${props =>
    props.snackbarOpen ? `${props.snackbarHeight}px` : 0};
  transition: max-height ${props => (props.snackbarOpen ? ".225s" : ".195s")};
`;

class Snackbar extends React.Component<Props, State> {
  static defaultProps = defaultProps;

  state = {
    height: 0,
  };

  timeout: ?TimeoutID = null;

  componentDidMount() {
    if (this.props.autoHideDuration !== null) {
      this.timeout = setTimeout(
        this.props.onClose,
        this.props.autoHideDuration
      );
    }
  }

  componentWillUnmount() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }

  handleSnackbarRef: React.Ref<"div"> = node => {
    if (node === null) {
      return;
    }

    const { height } = node.getBoundingClientRect();

    this.setState({ height });
  };

  handleContainerRef: React.Ref<"div"> = node => {
    if (node === null) {
      return;
    }

    node.addEventListener(
      "transitionend",
      (e: Event) => {
        if (e.target !== node) {
          return;
        }

        if (this.props.open) {
          this.props.onEntered();
        } else {
          this.props.onExited();
        }
      },
      false
    );
  };

  render() {
    const {
      open,
      message,
      action,
      autoHideDuration,
      onClose,
      onExited,
      ...rest
    } = this.props;
    const { height } = this.state;

    return (
      <Container
        innerRef={this.handleContainerRef}
        snackbarOpen={open}
        snackbarHeight={height}
      >
        <div ref={this.handleSnackbarRef}>
          <ClickAwayListener onClickAway={onClose}>
            <SnackbarContent {...rest} message={message} action={action} />
          </ClickAwayListener>
        </div>
      </Container>
    );
  }
}

export default Snackbar;
