// @flow
import * as React from "react";
import { connect } from "react-redux";
import { compose, branch, renderNothing } from "recompose";
import { createStructuredSelector } from "reselect";
import { Redirect } from "react-router";
import {
  makeIsAuthenticationStatusReady,
  makeGetAuthenticatedMember,
} from "../selectors";
import { AUTH, PLACE_LIST } from "../routes";
import type { Member } from "../models";

type Props = {
  member: ?Member,
};

const getDefaultRedirect = ({ member }: Props) => {
  if (member) {
    return PLACE_LIST;
  }

  return AUTH;
};

const makeMapStateToProps = () =>
  createStructuredSelector({
    ready: makeIsAuthenticationStatusReady(),
    member: makeGetAuthenticatedMember(),
  });

const enhance = compose(
  connect(
    makeMapStateToProps,
    null
  ),
  branch(({ ready }) => !ready, renderNothing)
);

const AutoRedirectContainer = (props: Props) => (
  <Redirect to={getDefaultRedirect(props)} />
);

export default enhance(AutoRedirectContainer);
