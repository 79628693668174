// @flow
import * as React from "react";
import { pure } from "recompose";
import { IconButton as IconButtonBase } from "material-ui";

type Props = {
  icon: React.ComponentType<any>,
};

const enhance = pure;

/**
 * Pure version of IconButton
 *
 * Rendering material-ui's ButtonBase is expensive.
 * To make shallow comparison possible, icon element is replaced by icon component
 */
const IconButton = ({ icon: IconComponent, ...rest }: Props) => (
  <IconButtonBase {...rest}>
    <IconComponent />
  </IconButtonBase>
);

export default enhance(IconButton);
