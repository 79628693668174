// @flow
import * as React from "react";
import {
  pipe,
  when,
  unless,
  startsWith,
  replace,
  isEmpty,
  ifElse,
  equals,
  always,
} from "ramda";
import { pure } from "recompose";
import TextField from "./TextField";

type Props = {
  ...$Exact<React.ElementProps<typeof TextField>>,
};

const normalizePhoneNumber: (value: string) => string = pipe(
  // Remove any non-numeric characters
  replace(/\D/g, ""),
  ifElse(
    // If it's just "+", clear the phone number
    equals("+"),
    always(""),
    // Skip futher processing for empty string
    unless(
      isEmpty,
      pipe(
        // Replace 8... with +7...
        when(startsWith("8"), replace(/^./, "+7")),
        // Phone number must start with "+D..."
        unless(startsWith("+"), replace(/^/, "+")),
        // Apply the following mask to phone number: "+D DDD DDD-DD-DD DDDD"
        replace(/^(\+\d)/, "$1 "),
        replace(/^(\+\d \d{3})/, "$1 "),
        replace(/^(\+\d \d{3} \d{3})/, "$1-"),
        replace(/^(\+\d \d{3} \d{3}-\d{2})/, "$1-"),
        replace(/^(\+\d \d{3} \d{3}-\d{2}-\d{2})/, "$1 ")
      )
    )
  ),
  // Strip any trailing non-numeric characters
  replace(/\D$/, "")
);

const enhance = pure;

class PhoneNumberField extends React.Component<Props, { value: string }> {
  state = { value: normalizePhoneNumber(this.props.value) };

  handleChange = (e: SyntheticEvent<HTMLInputElement>) => {
    const value = normalizePhoneNumber(e.currentTarget.value);

    e.currentTarget.value = value;

    this.setState({ value });

    if (this.props.onChange) {
      this.props.onChange(e);
    }
  };

  render() {
    const { value, onChange, ...rest } = this.props;

    return (
      <TextField
        {...rest}
        value={this.state.value}
        onChange={this.handleChange}
      />
    );
  }
}

export default enhance(PhoneNumberField);
