"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _resolveApplication = require("./resolveApplication");

var _resolveApplication2 = _interopRequireDefault(_resolveApplication);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var rejectApplication = function rejectApplication(firebase, id, comment) {
  return (0, _resolveApplication2.default)(firebase, id, {
    approved: false,
    comment: comment
  });
};
exports.default = rejectApplication;