"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _utils = require("../utils");

var getPlace = function getPlace(firebase, id) {
  return (0, _utils.fromEvent)(firebase.database().ref("/places/" + id), "value", function (snap) {
    return snap.val();
  });
};

exports.default = getPlace;