// @flow
import { compose, evolve, unless, isNil } from "ramda";
import { Record, type RecordOf, type RecordFactory } from "immutable";

export type AddressFields = {
  value: string,
  displayName: string,
};

export type Address = RecordOf<$Exact<AddressFields>>;

export const makeAddress: RecordFactory<$Exact<AddressFields>> = Record({
  value: "",
  displayName: "",
});

export type CoordinatesFields = {
  latitude: number,
  longitude: number,
};

export type Coordinates = RecordOf<$Exact<CoordinatesFields>>;

export const makeCoordinates: RecordFactory<$Exact<CoordinatesFields>> = Record(
  {
    latitude: 0,
    longitude: 0,
  }
);

export type AddressSuggestionFields = {
  type: "geo",
  value: string,
  displayName: string,
  hl: [number, number][],
};

export type AddressSuggestion = RecordOf<AddressSuggestionFields>;

export const makeAddressSuggestion: RecordFactory<
  AddressSuggestionFields
> = Record({
  type: "geo",
  displayName: "",
  value: "",
  hl: [],
});

export type GeocodeResult = RecordOf<{
  address: Address,
  coordinates: Coordinates,
}>;

export const makeGeocodeResult: RecordFactory<{
  address: Address,
  coordinates: Coordinates,
}> = compose(
  Record({
    address: makeAddress(),
    coordinates: makeCoordinates(),
  }),
  evolve({
    address: unless(isNil, makeAddress),
    coordinates: unless(isNil, makeCoordinates),
  })
);
