// @flow
import * as React from "react";
import styled from "styled-components";
import LoadingIndicator from "./LoadingIndicator";

type Props = {
  color?: ?string,
  loading?: boolean,
  children: React.Node,
};

const defaultProps = {
  color: null,
  loading: false,
};

const Container = styled.div`
  position: relative;
`;

const Content = styled(({ visible, ...rest }) => <div {...rest} />)`
  visibility: ${props => (props.visible ? "visible" : "hidden")};
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

const LoadingWrapper = ({ loading, color, children }: Props) => (
  <Container>
    <Content visible={!loading}>{children}</Content>
    {loading && (
      <Overlay>
        <LoadingIndicator color={color} />
      </Overlay>
    )}
  </Container>
);

LoadingWrapper.defaultProps = defaultProps;

export default LoadingWrapper;
