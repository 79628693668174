"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _Observable = require("rxjs/Observable");

var getAuthenticatedUser = function getAuthenticatedUser(firebase) {
  return _Observable.Observable.create(function (observer) {
    return firebase.auth().onAuthStateChanged(observer);
  });
};
exports.default = getAuthenticatedUser;