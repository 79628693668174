// @flow
import * as React from "react";
import { sortableElement } from "react-sortable-hoc";

const enhance = sortableElement;

const SortableElement = ({ children }: { children: React.Node }) =>
  React.Children.only(children);

export default enhance(SortableElement);
