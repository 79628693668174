// @flow
import { List } from "immutable";
import { createSelector, type Selector } from "reselect";
import type { State } from "../reducers";
import type { Member, MemberId, PlaceId } from "../models";

/**
 * Get the list of ids from the member list
 */

export const makeGetMemberListIds = (): Selector<State, {}, List<MemberId>> =>
  createSelector(state => state.member.listIds, listIds => listIds.reverse());

/**
 * Return true when the list is ready to be displayed
 */

export const makeIsMemberListLoaded = (): Selector<
  State,
  {},
  boolean
> => state => state.member.listLoaded;

/**
 * Return details of individual member by ID
 */
export const makeGetMember = (): Selector<State, { id: MemberId }, ?Member> =>
  createSelector(
    state => state.member.byId,
    (state, ownProps) => ownProps.id,
    (byId, id) => byId.get(id, null)
  );

/**
 * Return whether member was loaded
 */
export const makeIsMemberLoaded = (): Selector<
  State,
  { id: MemberId },
  boolean
> => (state, ownProps) => state.member.byId.has(ownProps.id);

/**
 * Return IDs of member's places
 */
export const makeGetMemberPlaceIds = (): Selector<
  State,
  { id: MemberId },
  List<PlaceId>
> => (state, ownProps) => state.member.memberPlaceIds.get(ownProps.id, List());

/**
 * Returns whether member's places were loaded
 */
export const makeIsMemberPlacesLoaded = (): Selector<
  State,
  { id: MemberId },
  boolean
> => (state, ownProps) => state.member.memberPlaceIds.has(ownProps.id);

/**
 * Returns whether the member is currently being suspended or restored
 */
export const makeIsMemberUpdating = (): Selector<
  State,
  { id: MemberId },
  boolean
> => (state, ownProps) => state.member.isUpdatingById.get(ownProps.id, false);
