// @flow
import * as React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { getDisplayName } from "recompose";
import { WindowScroller } from "react-virtualized";

const CONTEXT_KEY = "scrollProps";

export type ScrollProps = {|
  height?: number,
  width?: number,
  isScrolling?: boolean,
  scrollTop?: number,
  registerChild?: Function,
  onChildScroll?: Function,
|};

type Props = {|
  children: React.Node,
|};

type State = {|
  scrollElement: ?Element,
|};

type Context = {|
  [typeof CONTEXT_KEY]: ?Element,
|};

const contextTypes = {
  [CONTEXT_KEY]: PropTypes.instanceOf(Element),
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
`;

const connect = <T: {}>(
  WrappedComponent: React.ComponentType<{
    ...$Exact<T>,
    ...ScrollProps,
  }>
): React.ComponentType<T> => {
  const WithScrollProvider = (props: T, context: Context) => {
    const scrollElement = context[CONTEXT_KEY];

    // Connected component does not have parent ScrollContainer
    if (!scrollElement) {
      return <WrappedComponent {...props} />;
    }

    return (
      <WindowScroller scrollElement={scrollElement}>
        {scrollProps => <WrappedComponent {...props} {...scrollProps} />}
      </WindowScroller>
    );
  };

  WithScrollProvider.displayName = `withScrollProvider<${getDisplayName(
    WrappedComponent
  )}>`;

  WithScrollProvider.contextTypes = contextTypes;

  return WithScrollProvider;
};

class ScrollContainer extends React.Component<Props, State> {
  static connect = connect;
  static childContextTypes = contextTypes;

  state = {
    scrollElement: null,
  };

  getChildContext() {
    return {
      [CONTEXT_KEY]: this.state.scrollElement,
    };
  }

  handleRef = (scrollElement: ?Element) => {
    this.setState({ scrollElement });
  };

  render() {
    return (
      <Container innerRef={this.handleRef}>
        {this.state.scrollElement ? this.props.children : null}
      </Container>
    );
  }
}

export default ScrollContainer;
