// @flow
import * as React from "react";
import styled from "styled-components";
import LoadingIndicator from "./LoadingIndicator";

const Container = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoadingPlaceholder = () => (
  <Container>
    <LoadingIndicator />
  </Container>
);

export default LoadingPlaceholder;
