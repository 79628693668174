"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _ymaps = require("ymaps");

var _ymaps2 = _interopRequireDefault(_ymaps);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var YandexAdapter = function () {
  function YandexAdapter() {
    _classCallCheck(this, YandexAdapter);

    this.api = null;
  }

  _createClass(YandexAdapter, [{
    key: "getApi",
    value: function getApi() {
      if (!this.api) {
        this.api = _ymaps2.default.load();
      }

      return this.api;
    }
  }, {
    key: "getSuggestions",
    value: function getSuggestions(query) {
      return this.getApi().then(function (maps) {
        return maps.suggest(query);
      }).then(function (results) {
        return results.map(function (_ref) {
          var value = _ref.value,
              displayName = _ref.displayName,
              hl = _ref.hl;
          return {
            value: value,
            displayName: displayName,
            hl: hl
          };
        });
      });
    }
  }, {
    key: "getAddress",
    value: function getAddress(coordinates) {
      return this.geocode({ coordinates: coordinates }).then(function (result) {
        return result.address;
      });
    }
  }, {
    key: "getCoordinates",
    value: function getCoordinates(address) {
      return this.geocode({ address: address }).then(function (result) {
        return result.coordinates;
      });
    }
  }, {
    key: "geocode",
    value: function geocode(request) {
      var query = request.address ? request.address.value : [request.coordinates.latitude, request.coordinates.longitude];

      return this.getApi().then(function (maps) {
        return maps.geocode(query, { json: true });
      }).then(function (result) {
        var _result$GeoObjectColl = _slicedToArray(result.GeoObjectCollection.featureMember, 1),
            _result$GeoObjectColl2 = _result$GeoObjectColl[0].GeoObject,
            GeocoderMetaData = _result$GeoObjectColl2.metaDataProperty.GeocoderMetaData,
            Point = _result$GeoObjectColl2.Point;

        var _Point$pos$split$map = Point.pos.split(" ").map(parseFloat),
            _Point$pos$split$map2 = _slicedToArray(_Point$pos$split$map, 2),
            longitude = _Point$pos$split$map2[0],
            latitude = _Point$pos$split$map2[1];

        var coordinates = {
          latitude: latitude,
          longitude: longitude
        };
        var address = {
          value: GeocoderMetaData.text,
          displayName: GeocoderMetaData.AddressDetails.Country.AddressLine
        };

        return {
          address: address,
          coordinates: coordinates
        };
      });
    }
  }]);

  return YandexAdapter;
}();

exports.default = YandexAdapter;