// @flow
import * as React from "react";
import PropTypes from "prop-types";
import { getDisplayName } from "recompose";
import type { App } from "firebase/app";

const CONTEXT_KEY = "firebase";

type Props = {|
  firebase: App,
  children: React.Node,
|};

type Context = {|
  firebase: App,
|};

const contextTypes = {
  firebase: PropTypes.object,
};

const connect = <T: *>(
  WrappedComponent: React.ComponentType<
    {
      firebase: App,
    } & T
  >
): React.ComponentType<T> => {
  const WithFirebase = (props: T, context: Context) => {
    const firebase = context[CONTEXT_KEY];

    return <WrappedComponent {...props} firebase={firebase} />;
  };

  WithFirebase.contextTypes = contextTypes;
  WithFirebase.displayName = `WithFirebase<${getDisplayName(
    WrappedComponent
  )}>`;

  return WithFirebase;
};

class FirebaseProvider extends React.Component<Props> {
  static connect = connect;
  static childContextTypes = contextTypes;

  getChildContext() {
    return {
      [CONTEXT_KEY]: this.props.firebase,
    };
  }

  render() {
    return React.Children.only(this.props.children);
  }
}

export default FirebaseProvider;
