// @flow
import * as React from "react";
import { compose, branch, renderNothing } from "recompose";
import { translate, type TranslatorProps } from "react-i18next";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { List, ListItemIcon, ListItemText } from "material-ui";
import {
  Store as StoreIcon,
  Description as DescriptionIcon,
  Person as PersonIcon,
} from "material-ui-icons";
import { MenuItemLink } from "../components";
import { drawerClose, logout } from "../actions";
import { makeGetAuthenticatedMember } from "../selectors";
import { type Member } from "../models";
import { PLACE_LIST, MEMBER_LIST, APPLICATION_LIST } from "../routes";

type Props = {
  ...$Exact<TranslatorProps>,
  permanent: boolean,
  open: boolean,
  member: Member,
  drawerClose: () => void,
  logout: () => void,
};

const makeMapStateToProps = () =>
  createStructuredSelector({
    member: makeGetAuthenticatedMember(),
  });

const enhance = compose(
  translate(),
  connect(
    makeMapStateToProps,
    { drawerClose, logout }
  ),
  branch(({ member }) => !member, renderNothing)
);

class MenuContainer extends React.Component<Props> {
  renderAdminMenu() {
    const { t } = this.props;

    return (
      <List>
        <MenuItemLink to={PLACE_LIST}>
          <ListItemIcon>
            <StoreIcon />
          </ListItemIcon>
          <ListItemText primary={t("menu.places")} />
        </MenuItemLink>
        <MenuItemLink to={MEMBER_LIST}>
          <ListItemIcon>
            <PersonIcon />
          </ListItemIcon>
          <ListItemText primary={t("menu.members")} />
        </MenuItemLink>
        <MenuItemLink to={APPLICATION_LIST}>
          <ListItemIcon>
            <DescriptionIcon />
          </ListItemIcon>
          <ListItemText primary={t("menu.applications")} />
        </MenuItemLink>
      </List>
    );
  }

  renderPublisherMenu() {
    const { t } = this.props;

    return (
      <List>
        <MenuItemLink to={PLACE_LIST}>
          <ListItemIcon>
            <StoreIcon />
          </ListItemIcon>
          <ListItemText primary={t("menu.places")} />
        </MenuItemLink>
      </List>
    );
  }

  render() {
    const { member } = this.props;

    return member.isAdmin()
      ? this.renderAdminMenu()
      : this.renderPublisherMenu();
  }
}

export default enhance(MenuContainer);
