// @flow
import * as React from "react";
import { compose } from "recompose";
import { withRouter, type ContextRouter } from "react-router";
import { translate, type TranslatorProps } from "react-i18next";
import {
  LayoutContainer,
  PlaceContainer,
  PlaceEditContainer,
} from "../containers";
import { ScheduleForm, Toolbar, FormActionsToolbar } from "../components";
import { formatRoute, PLACE_DETAILS } from "../routes";
import type { PlaceId } from "../models";

type Props = {
  ...$Exact<ContextRouter>,
  ...$Exact<TranslatorProps>,
  id: PlaceId,
};

const enhance = compose(
  withRouter,
  translate()
);

class PlaceEditScheduleContainer extends React.Component<Props> {
  goBack = () => {
    const { id, history } = this.props;

    history.push(formatRoute(PLACE_DETAILS, { id }));
  };

  render() {
    const { t, id } = this.props;

    return (
      <PlaceContainer
        id={id}
        render={() => (
          <PlaceEditContainer
            id={id}
            render={({ schedule, updatePlace }) => (
              <ScheduleForm
                initialValue={schedule.toJS()}
                onSubmit={schedule => updatePlace({ schedule })}
                render={({ children, onSubmit }) => (
                  <LayoutContainer
                    toolbar={
                      <Toolbar
                        onGoBack={this.goBack}
                        title={t("places.title.schedule")}
                      />
                    }
                    footer={
                      <FormActionsToolbar
                        onCancel={this.goBack}
                        onSubmit={onSubmit}
                      />
                    }
                  >
                    {children}
                  </LayoutContainer>
                )}
              />
            )}
          />
        )}
      />
    );
  }
}

export default enhance(PlaceEditScheduleContainer);
