// @flow
import type { Reducer } from "redux";
import { Map, List } from "immutable";
import {
  APPLICATION_LIST_LOADED,
  APPLICATION_RESOLVE,
  APPLICATION_RESOLVE_RESULT,
  APPLICATION_LOADED,
  USER_APPLICATION_SUBMIT_RESULT,
  type Action,
} from "../actions";
import type { ApplicationId, Application } from "../models";
import { combineReducers } from "./utils";

export type State = {|
  byId: Map<ApplicationId, Application>,
  listIds: List<ApplicationId>,
  listLoaded: boolean,
  loadedById: Map<ApplicationId, boolean>,
  submittingResolutionById: Map<ApplicationId, boolean>,
|};

const applicationReducer: Reducer<State, Action> = combineReducers({
  byId: (state = Map(), action) => {
    switch (action.type) {
      case APPLICATION_LIST_LOADED: {
        const { applications } = action.payload;

        return state.merge(applications);
      }

      case APPLICATION_LOADED:
      case USER_APPLICATION_SUBMIT_RESULT: {
        const { application } = action.payload;

        return state.set(application.id, application);
      }

      default:
        return state;
    }
  },
  listIds: (state = List(), action) => {
    switch (action.type) {
      case APPLICATION_LIST_LOADED: {
        const { applications } = action.payload;

        return applications.keySeq().toList();
      }

      case APPLICATION_RESOLVE_RESULT: {
        if (action.error) {
          return state;
        }

        const { application } = action.payload;

        return state.delete(state.indexOf(application.id));
      }

      default:
        return state;
    }
  },
  listLoaded: (state = false, action) => {
    switch (action.type) {
      case APPLICATION_LIST_LOADED:
        return true;

      default:
        return state;
    }
  },
  loadedById: (state = Map(), action) => {
    switch (action.type) {
      case APPLICATION_LOADED:
      case USER_APPLICATION_SUBMIT_RESULT:
        return state.set(action.payload.application.id, true);

      default:
        return state;
    }
  },
  submittingResolutionById: (state = Map(), action) => {
    switch (action.type) {
      case APPLICATION_RESOLVE: {
        const { application } = action.payload;

        return state.set(application.id, true);
      }

      case APPLICATION_RESOLVE_RESULT: {
        const { application } = action.payload;

        return state.set(application.id, false);
      }

      default:
        return state;
    }
  },
});

export default applicationReducer;
