// @flow
import * as React from "react";
import styled from "styled-components";
import { assocPath } from "ramda";
import { translate, type TranslatorProps } from "react-i18next";
import ScheduleRow from "./ScheduleRow";

type Day = "mon" | "tue" | "wed" | "thu" | "fri" | "sat" | "sun";

type MinutesSinceMidnight = number;

// Values is a map of every day of the week associated with either
// null, for a weekend, or open-close pair of TimeValues
type Value = {
  [day: Day]: null | {|
    open: MinutesSinceMidnight,
    close: MinutesSinceMidnight,
  |},
};

type RenderProps = {
  children: React.Node,
  onSubmit: () => void,
};

type Props = {
  ...$Exact<TranslatorProps>,
  initialValue: Value,
  onSubmit: (value: Value) => void,
  render: (props: RenderProps) => void,
};

const defaultDayValue = {
  open: 9 * 60,
  close: 18 * 60,
};

const defaultValue = {
  mon: defaultDayValue,
  tue: defaultDayValue,
  wed: defaultDayValue,
  thu: defaultDayValue,
  fri: defaultDayValue,
  sat: null,
  sun: null,
};

const defaultProps = {
  initialValue: defaultValue,
};

const Container = styled.div`
  flex: 1;
`;

const enhance = translate();

class ScheduleForm extends React.Component<Props, { value: Value }> {
  static defaultProps = defaultProps;

  state = { value: this.props.initialValue };

  handleChange = (e: { target: HTMLInputElement }) => {
    const { name, value, checked } = e.target;
    const [day, openOrClose] = name.split(".");

    if (!openOrClose) {
      this.setState(
        assocPath(["value", day], checked ? defaultDayValue : null)
      );
    } else {
      this.setState(assocPath(["value", day, openOrClose], value));
    }
  };

  handleSubmit = () => {
    if (this.props.onSubmit) {
      this.props.onSubmit(this.state.value);
    }
  };

  render() {
    const { t, render } = this.props;
    const { value } = this.state;

    return render({
      onSubmit: this.handleSubmit,
      children: (
        <Container>
          {[
            ["mon", t("general.days.monday")],
            ["tue", t("general.days.tuesday")],
            ["wed", t("general.days.wednesday")],
            ["thu", t("general.days.thursday")],
            ["fri", t("general.days.friday")],
            ["sat", t("general.days.saturday")],
            ["sun", t("general.days.sunday")],
          ].map(([name, label]) => (
            <ScheduleRow
              key={name}
              name={name}
              label={label}
              value={value[name]}
              onChange={this.handleChange}
            />
          ))}
        </Container>
      ),
    });
  }
}

export default enhance(ScheduleForm);
