// @flow
export const DISMISS_NOTIFICATION = "DISMISS_NOTIFICATION";

export type Action = {
  type: "DISMISS_NOTIFICATION",
};

export const dismissNotification = (): Action => ({
  type: DISMISS_NOTIFICATION,
});
