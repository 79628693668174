import { createStore, applyMiddleware } from "redux";
import { createEpicMiddleware } from "redux-observable";
import { routerMiddleware as createRouterMiddleware } from "react-router-redux";
import { persistStore } from "redux-persist";
import { composeWithDevTools } from "redux-devtools-extension";
import rootReducer from "./reducers";
import rootEpic from "./epics";

const configureStore = (preloadedState, { api, history, geocode }) => {
  const epicMiddleware = createEpicMiddleware({
    dependencies: { api, history, geocode },
  });
  const routerMiddleware = createRouterMiddleware(history);
  const middlewareEnhancer = applyMiddleware(epicMiddleware, routerMiddleware);
  const storeEnhancer = composeWithDevTools(middlewareEnhancer);
  const store = createStore(rootReducer, preloadedState, storeEnhancer);
  const persistor = persistStore(store);

  epicMiddleware.run(rootEpic);

  if (module.hot) {
    module.hot.accept("./reducers", () => {
      store.replaceReducer(rootReducer);
    });

    module.hot.accept("./epics", () => {
      epicMiddleware.replaceEpic(rootEpic);
    });
  }

  return { store, persistor };
};

export default configureStore;
