"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _operators = require("rxjs/operators");

var _fromPromise = require("rxjs/observable/fromPromise");

var _utils = require("../utils");

var updatePlace = function updatePlace(api, placeId, updates) {
  return (0, _fromPromise.fromPromise)(api.database().ref("/places/" + placeId).update(updates)).pipe((0, _operators.switchMap)(function () {
    return (0, _utils.fromEvent)(api.database().ref("/places/" + placeId), "value", function (snap) {
      return snap.val();
    });
  }), (0, _operators.first)());
};
exports.default = updatePlace;