// @flow
import type { Observable } from "rxjs/Observable";
import { of } from "rxjs/observable/of";
import { filter, switchMap, catchError, map } from "rxjs/operators";
import type { Action } from "../../actions";
import ofType from "./ofType";
import logError from "./logError";

type Epic = any; // Can't do right now
type ResultActionCreator<T> = (
  result: T | Error,
  inputAction: Action
) => Action;
type ApiCallMaker<T> = (
  action: Action,
  deps: { api: any, history: any }
) => Observable<T>;

/**
 * Generic creator for epics, which take an action, make an api call,
 * and dispatch the result action.
 */
const createSimpleApiCallEpic = <T>(
  inputActionType: string,
  apiCallMaker: ApiCallMaker<T>,
  resultActionCreator: ResultActionCreator<T>
): Epic => (action$, store, deps) =>
  action$.pipe(
    filter(ofType(inputActionType)),
    switchMap(action =>
      apiCallMaker(action, deps).pipe(
        // Swallow an error after logging it
        catchError(logError(error => of(error))),
        map(result => resultActionCreator(result, action))
      )
    )
  );

export default createSimpleApiCallEpic;
