// @flow
import * as React from "react";
import styled from "styled-components";

type Props = {
  preventDefault: boolean,
  onSubmit: (e: SyntheticEvent<HTMLFormElement>) => void,
};

const BaseForm = styled.form`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

/**
 * A simple wrapper to handle form submission with return key on text fields.
 *
 * To submit with return key from inside a text field, the text field
 * must be wrapped in a form element, and the form element must
 * container either a button or input with type="submit".
 *
 * This is useful in editing details and address. Due to how
 * composition with render props works, the responsibility for
 * rendering form element needs to be delegated to the parent
 * component.
 *
 * What we'd like to achieve using render props is to have components
 * which render its content and allow the parent component to decide
 * when the submission happens. This allows the parent component to
 * put, say PlaceDetailsForm, next to the WizardStepper or
 * FormActionsToolbar depending on context, and assign them onSubmit
 * handler provided by DetailsForm.
 */
class Form extends React.Component<Props> {
  handleSubmit = (e: SyntheticEvent<HTMLFormElement>) => {
    if (this.props.preventDefault) {
      e.preventDefault();
    }

    if (this.props.onSubmit) {
      this.props.onSubmit(e);
    }
  };

  render() {
    const { preventDefault, onSubmit, ...rest } = this.props;

    return <BaseForm {...rest} onSubmit={this.handleSubmit} />;
  }
}

export default Form;
