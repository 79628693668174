// @flow
import * as React from "react";
import { replace } from "ramda";
import { pure } from "recompose";
import { InputAdornment } from "material-ui";
import TextField from "./TextField";

type Props = {
  ...$Exact<React.ElementProps<typeof TextField>>,
};

const normalizeUrl: (value: string) => string = replace(/^\w+:\/\//, "");

const enhance = pure;

class UrlField extends React.Component<Props> {
  handleChange = (e: any) => {
    if (this.props.onChange) {
      e.persist();

      e.target = {
        ...e.target,
        name: e.target.name,
        value: normalizeUrl(e.target.value),
      };

      this.props.onChange(e);
    }
  };

  render() {
    const { onChange, ...rest } = this.props;

    return (
      <TextField
        {...rest}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">http://</InputAdornment>
          ),
        }}
        onChange={this.handleChange}
      />
    );
  }
}

export default enhance(UrlField);
