// @flow
import type { List } from "immutable";

/**
 * Adjust insertin index taking removed index into account
 */
const adjustIndex = (afterIndex: number, itemIndex: number) =>
  afterIndex > itemIndex ? afterIndex : afterIndex + 1;

/**
 * Move item in immutable List after another item
 */
const moveAfterInList = <T>(list: List<T>, item: T, after: ?T): List<T> => {
  const itemIndex = list.indexOf(item);
  const afterIndex = after ? list.indexOf(after) : null;

  if (itemIndex === -1 || afterIndex === -1) {
    return list;
  }

  return list
    .remove(itemIndex)
    .insert(afterIndex === null ? 0 : adjustIndex(afterIndex, itemIndex), item);
};

export default moveAfterInList;
