// @flow
import type { List } from "immutable";
import { createSelector, type Selector } from "reselect";
import type { State } from "../reducers";
import type { Place, PlaceId } from "../models";

/**
 * Get the list of ids from the place list
 */

export const makeGetPlaceListIds = (): Selector<State, {}, List<PlaceId>> =>
  createSelector(state => state.place.listIds, listIds => listIds.reverse());

/**
 * Return true when the list is ready to be displayed
 */

export const makeIsPlaceListLoaded = (): Selector<
  State,
  {},
  boolean
> => state => state.place.listLoaded;

/**
 * Return details of individual place by ID
 */
export const makeGetPlace = (): Selector<State, { id: PlaceId }, ?Place> =>
  createSelector(
    state => state.place.byId,
    (state, ownProps) => ownProps.id,
    (byId, id) => byId.get(id, null)
  );
