// @flow
import * as React from "react";
import styled from "styled-components";
import Logo from "./Logo";

type Props = {
  children: React.Node,
};

const Container = styled.div`
  display: flex;
  align-self: center;
  flex-direction: column;
  max-width: 320px;
  align-items: center;
`;

const PublicLayout = ({ children }: Props) => (
  <Container>
    <Logo />
    {children}
  </Container>
);

export default PublicLayout;
