// @flow
import * as React from "react";
import {
  withRouter,
  Switch,
  Route,
  Redirect,
  type ContextRouter,
} from "react-router";
import {
  AutoRedirectContainer,
  AccessControlContainer,
  NotificationContainer,
  DrawerContainer,
} from "../containers";
import {
  ROOT,
  AUTH,
  INVITE_ACCEPT,
  APPLICATION_CREATE,
  PLACE_LIST,
  PLACE_CREATE,
  PLACE_DETAILS,
  PLACE_EDIT_DETAILS,
  PLACE_EDIT_ADDRESS,
  PLACE_EDIT_PHOTOS,
  PLACE_EDIT_SCHEDULE,
  APPLICATION_LIST,
  MEMBER_LIST,
  MEMBER_DETAILS,
  INVITE_LIST,
  INVITE_DETAILS,
} from "../routes";
import { ErrorBoundary } from "../components";
import { NotFoundError } from "../errors";
import AuthScreen from "./AuthScreen";
import SignupScreen from "./SignupScreen";
import InviteScreen from "./InviteScreen";
import InviteListScreen from "./InviteListScreen";
import InviteDetailsScreen from "./InviteDetailsScreen";
import ApplicationListScreen from "./ApplicationListScreen";
import MemberListScreen from "./MemberListScreen";
import MemberDetailsScreen from "./MemberDetailsScreen";
import PlaceListScreen from "./PlaceListScreen";
import PlaceDetailsScreen from "./PlaceDetailsScreen";
import PlaceEditDetailsScreen from "./PlaceEditDetailsScreen";
import PlaceEditLocationScreen from "./PlaceEditLocationScreen";
import PlaceCreateScreen from "./PlaceCreateScreen";
import PlaceEditPhotosScreen from "./PlaceEditPhotosScreen";
import PlaceEditScheduleScreen from "./PlaceEditScheduleScreen";
import ErrorScreen from "./ErrorScreen";
import NotFoundScreen from "./NotFoundScreen";

type Props = {
  ...$Exact<ContextRouter>,
};

const enhance = withRouter;

const RootScreen = ({ location }: Props) => (
  <React.Fragment>
    <NotificationContainer />
    <DrawerContainer />
    <ErrorBoundary resetToken={location} render={<ErrorScreen />}>
      <ErrorBoundary
        resetToken={location}
        predicate={error => error instanceof NotFoundError}
        render={<NotFoundScreen />}
      >
        <Switch>
          <Route exact path={ROOT} component={AutoRedirectContainer} />
          <Route
            exact
            path={AUTH}
            render={() => (
              <AccessControlContainer allow={member => !member}>
                <AuthScreen />
              </AccessControlContainer>
            )}
          />
          <Route
            exact
            path={INVITE_ACCEPT}
            render={({ match }) =>
              match.params.id ? <InviteScreen id={match.params.id} /> : null
            }
          />
          <Route
            path={APPLICATION_CREATE}
            render={() => (
              <AccessControlContainer allow={member => !member}>
                <SignupScreen />
              </AccessControlContainer>
            )}
          />
          <Route
            path={APPLICATION_LIST}
            render={() => (
              <AccessControlContainer
                allow={member => member && member.isAdmin()}
              >
                <ApplicationListScreen />
              </AccessControlContainer>
            )}
          />
          <Route
            exact
            path={MEMBER_LIST}
            render={() => (
              <AccessControlContainer
                allow={member => member && member.isAdmin()}
              >
                <MemberListScreen />
              </AccessControlContainer>
            )}
          />
          <Route
            path={MEMBER_DETAILS}
            render={({ match }) =>
              match.params.id ? (
                <AccessControlContainer
                  allow={member => member && member.isAdmin()}
                >
                  <MemberDetailsScreen id={match.params.id} />
                </AccessControlContainer>
              ) : null
            }
          />
          <Route
            exact
            path={INVITE_LIST}
            render={() => (
              <AccessControlContainer
                allow={member => member && member.isAdmin()}
              >
                <InviteListScreen />
              </AccessControlContainer>
            )}
          />
          <Route
            path={INVITE_DETAILS}
            render={({ match }) =>
              match.params.id ? (
                <AccessControlContainer
                  allow={member => member && member.isAdmin()}
                >
                  <InviteDetailsScreen id={match.params.id} />
                </AccessControlContainer>
              ) : null
            }
          />
          <Route
            exact
            path={PLACE_LIST}
            render={() => (
              <AccessControlContainer
                allow={member =>
                  member && (member.isPublisher() || member.isAdmin())
                }
              >
                <PlaceListScreen />
              </AccessControlContainer>
            )}
          />
          <Route
            exact
            path={PLACE_CREATE}
            render={() => (
              <AccessControlContainer
                allow={member =>
                  member && (member.isPublisher() || member.isAdmin())
                }
              >
                <PlaceCreateScreen />
              </AccessControlContainer>
            )}
          />
          <Route
            exact
            path={PLACE_DETAILS}
            render={({ match }) =>
              match.params.id ? (
                <AccessControlContainer
                  allow={member =>
                    member && (member.isPublisher() || member.isAdmin())
                  }
                >
                  <PlaceDetailsScreen id={match.params.id} />
                </AccessControlContainer>
              ) : null
            }
          />
          <Route
            exact
            path={PLACE_EDIT_DETAILS}
            render={({ match }) => (
              <AccessControlContainer
                allow={member =>
                  member && (member.isPublisher() || member.isAdmin())
                }
              >
                <PlaceEditDetailsScreen id={match.params.id} />
              </AccessControlContainer>
            )}
          />
          <Route
            exact
            path={PLACE_EDIT_ADDRESS}
            render={({ match }) => (
              <AccessControlContainer
                allow={member =>
                  member && (member.isPublisher() || member.isAdmin())
                }
              >
                <PlaceEditLocationScreen id={match.params.id} />
              </AccessControlContainer>
            )}
          />
          <Route
            exact
            path={PLACE_EDIT_PHOTOS}
            render={({ match }) => (
              <AccessControlContainer
                allow={member =>
                  member && (member.isPublisher() || member.isAdmin())
                }
              >
                <PlaceEditPhotosScreen id={match.params.id} />
              </AccessControlContainer>
            )}
          />
          <Route
            exact
            path={PLACE_EDIT_SCHEDULE}
            render={({ match }) => (
              <AccessControlContainer
                allow={member =>
                  member && (member.isPublisher() || member.isAdmin())
                }
              >
                <PlaceEditScheduleScreen id={match.params.id} />
              </AccessControlContainer>
            )}
          />
          <Redirect to={ROOT} />
        </Switch>
      </ErrorBoundary>
    </ErrorBoundary>
  </React.Fragment>
);

export default enhance(RootScreen);
