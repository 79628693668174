import translations from "./translations.json";
import fields from "./fields.json";
import validations from "./validations.json";
import messages from "./messages.json";

export default {
  translations,
  fields,
  validations,
  messages,
};
