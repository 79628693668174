// @flow
import type { List } from "immutable";
import { createSelector, type Selector } from "reselect";
import type { Application, ApplicationId } from "../models";
import type { State } from "../reducers";

/**
 * Return the id of user own application
 */
export const makeGetUserApplicationId = (): Selector<
  State,
  {},
  ?ApplicationId
> => state => state.signup.applicationId;

/**
 * Return user's own application (if any)
 */
export const makeGetUserApplication = (): Selector<State, any, ?Application> =>
  createSelector(
    state => state.signup.applicationId,
    state => state.application.byId,
    (id, byId) => (id ? byId.get(id, null) : null)
  );

/**
 * Return whether user application has been loaded
 */
export const makeIsUserApplicationLoaded = (): Selector<State, {}, boolean> =>
  createSelector(
    state => state.signup.applicationId,
    state => state.application.loadedById,
    (id, byId) => (id ? byId.get(id, false) : false)
  );

/**
 * Return whether the user is currently submitting their application
 */
export const makeIsUserApplicationSubmitting = (): Selector<
  State,
  any,
  boolean
> => state => state.signup.submitting;

/**
 * Return the list of application ids in the listing
 */
export const makeGetApplicationListIds = (): Selector<
  State,
  any,
  List<ApplicationId>
> =>
  createSelector(
    state => state.application.listIds,
    listIds => listIds.reverse()
  );

/**
 * Return an application by its id
 */
export const makeGetApplication = (): Selector<
  State,
  { id: ApplicationId },
  ?Application
> =>
  createSelector(
    state => state.application.byId,
    (state, props) => props.id,
    (byId, id) => byId.get(id, null)
  );

/**
 * Return whether the list has been loaded
 */
export const makeIsApplicationListLoaded = (): Selector<
  State,
  any,
  boolean
> => state => state.application.listLoaded;

/**
 * Returns whether the application is being approved or rejected
 */
export const makeIsSubmittingResolution = (): Selector<
  State,
  { id: ApplicationId },
  boolean
> =>
  createSelector(
    state => state.application.submittingResolutionById,
    (state, props) => props.id,
    (byId, id) => byId.get(id, false)
  );
