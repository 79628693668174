// @flow
import { List, Map } from "immutable";
import {
  makeInvite,
  type InviteFields,
  type Invite,
  type InviteId,
  type User,
} from "../models";
import { withErrorVariant, type WithErrorVariant } from "./utils";

export const INVITE_LIST_SHOWN = "INVITE_LIST_SHOWN";
export const INVITE_LIST_HIDDEN = "INVITE_LIST_HIDDEN";
export const INVITE_LIST_LOADED = "INVITE_LIST_LOADED";
export const INVITE_LIST_INVITE_ADDED = "INVITE_LIST_INVITE_ADDED";
export const INVITE_LIST_INVITE_MOVED = "INVITE_LIST_INVITE_MOVED";
export const INVITE_LIST_INVITE_REMOVED = "INVITE_LIST_INVITE_REMOVED";
export const INVITE_LIST_INVITE_CHANGED = "INVITE_LIST_INVITE_CHANGED";
export const INVITE_SHOWN = "INVITE_SHOWN";
export const INVITE_HIDDEN = "INVITE_HIDDEN";
export const INVITE_LOADED = "INVITE_LOADED";
export const ACCEPT_INVITE = "ACCEPT_INVITE";
export const ACCEPT_INVITE_RESULT = "ACCEPT_INVITE_RESULT";
export const INVITE_CREATE_RESULT = "INVITE_CREATE_RESULT";
export const INVITE_SET_ADMIN = "INVITE_SET_ADMIN";
export const INVITE_SET_ADMIN_RESULT = "INVITE_SET_ADMIN_RESULT";

export type Action =
  | {|
      type: "INVITE_LIST_SHOWN",
    |}
  | {|
      type: "INVITE_LIST_HIDDEN",
    |}
  | {|
      type: "INVITE_LIST_LOADED",
      payload: {|
        ids: List<InviteId>,
        invites: Map<InviteId, Invite>,
      |},
    |}
  | {|
      type: "INVITE_LIST_INVITE_ADDED",
      payload: {| invite: Invite, after: InviteId |},
    |}
  | {|
      type: "INVITE_LIST_INVITE_MOVED",
      payload: {| invite: Invite, after: InviteId |},
    |}
  | {|
      type: "INVITE_LIST_INVITE_REMOVED",
      payload: {| invite: Invite |},
    |}
  | {|
      type: "INVITE_LIST_INVITE_CHANGED",
      payload: {| invite: Invite |},
    |}
  | {|
      type: "INVITE_SHOWN",
      payload: {| id: InviteId |},
    |}
  | {|
      type: "INVITE_HIDDEN",
      payload: {| id: InviteId |},
    |}
  | {|
      type: "INVITE_LOADED",
      payload: {|
        id: InviteId,
        invite: ?Invite,
      |},
    |}
  | {|
      type: "ACCEPT_INVITE",
      payload: {| user: User, invite: Invite |},
    |}
  | WithErrorVariant<{|
      type: "ACCEPT_INVITE_RESULT",
    |}>
  | {|
      type: "INVITE_CREATE_RESULT",
      payload: {| invite: Invite |},
    |}
  | {|
      type: "INVITE_SET_ADMIN",
      payload: {|
        invite: Invite,
        isAdmin: boolean,
      |},
    |}
  | {|
      type: "INVITE_SET_ADMIN_RESULT",
      payload: {|
        invite: Invite,
      |},
    |};

export const inviteListShown = (): Action => ({
  type: INVITE_LIST_SHOWN,
});

export const inviteListHidden = (): Action => ({
  type: INVITE_LIST_HIDDEN,
});

export const inviteListLoaded = withErrorVariant(
  INVITE_LIST_LOADED,
  (data: Array<InviteFields>): Action => {
    // TODO: Use utils/normalizeEntities
    const { ids, invites } = data.reduce(
      ({ ids, invites }, invite) => {
        ids.push(invite.id);
        invites[invite.id] = invite; // eslint-disable-line no-param-reassign

        return { ids, invites };
      },
      { ids: [], invites: {} }
    );

    return {
      type: INVITE_LIST_LOADED,
      payload: {
        ids: List(ids),
        invites: Map(invites),
      },
    };
  }
);

export const inviteListInviteAdded = (
  invite: InviteFields,
  after: InviteId
): Action => ({
  type: INVITE_LIST_INVITE_ADDED,
  payload: { invite: makeInvite(invite), after },
});

export const inviteListInviteMoved = (
  invite: InviteFields,
  after: InviteId
): Action => ({
  type: INVITE_LIST_INVITE_MOVED,
  payload: { invite: makeInvite(invite), after },
});

export const inviteListInviteRemoved = (invite: InviteFields): Action => ({
  type: INVITE_LIST_INVITE_REMOVED,
  payload: { invite: makeInvite(invite) },
});

export const inviteListInviteChanged = (invite: InviteFields): Action => ({
  type: INVITE_LIST_INVITE_CHANGED,
  payload: { invite: makeInvite(invite) },
});

export const inviteShown = (id: InviteId): Action => ({
  type: INVITE_SHOWN,
  payload: { id },
});

export const inviteHidden = (id: InviteId): Action => ({
  type: INVITE_HIDDEN,
  payload: { id },
});

/**
 * If invite is null, then invite is not found
 */
export const inviteLoaded = (id: InviteId, invite: ?InviteFields): Action => ({
  type: INVITE_LOADED,
  payload: {
    id,
    invite: invite ? makeInvite(invite) : null,
  },
});

export const acceptInvite = (user: User, invite: Invite): Action => ({
  type: ACCEPT_INVITE,
  payload: { user, invite },
});

export const acceptInviteResult = withErrorVariant(
  ACCEPT_INVITE_RESULT,
  (): Action => ({
    type: ACCEPT_INVITE_RESULT,
  })
);

export const inviteCreateResult = withErrorVariant(
  INVITE_CREATE_RESULT,
  (invite: Invite): Action => ({
    type: INVITE_CREATE_RESULT,
    payload: { invite: makeInvite(invite) },
  })
);
export const inviteSetAdmin = (invite: Invite, isAdmin: boolean): Action => ({
  type: INVITE_SET_ADMIN,
  payload: {
    invite,
    isAdmin,
  },
});

export const inviteSetAdminResult = withErrorVariant(
  INVITE_SET_ADMIN_RESULT,
  (invite: Invite): Action => ({
    type: INVITE_SET_ADMIN_RESULT,
    payload: {
      invite,
    },
  })
);
