// @flow
import nanoid from "nanoid";
import {
  Record,
  type RecordOf,
  type RecordFactory,
  type List,
} from "immutable";
import type {
  PlaceDetails,
  PlaceAddress,
  PlaceCoordinates,
  PlaceSchedule,
  PlacePhoto,
} from "./PlaceModel";
import type { AddressSuggestion } from "./AddressModel";

export type PlaceEditPhotoId = string;

export type PlaceEditPhotoUploadProgressFields = {
  bytesTransferred: number,
  totalBytes: number,
};

export type PlaceEditPhotoUploadProgress = RecordOf<
  PlaceEditPhotoUploadProgressFields
>;

export const makePlaceEditPhotoUploadProgress: RecordFactory<
  PlaceEditPhotoUploadProgressFields
> = Record({
  bytesTransferred: 0,
  totalBytes: 0,
});

export type PlaceEditPhotoFields = {
  id: PlaceEditPhotoId,
  result: ?PlacePhoto,
  file: ?File,
  progress: ?PlaceEditPhotoUploadProgress,
};

export type PlaceEditPhoto = RecordOf<PlaceEditPhotoFields>;

export const makePlaceEditPhoto: RecordFactory<PlaceEditPhotoFields> = Record({
  id: "",
  result: null,
  file: null,
  progress: null,
});

export const makePlaceEditPhotoFromFile = (file: File) =>
  makePlaceEditPhoto({
    id: nanoid(),
    file,
  });

export const makePlaceEditPhotoFromPlacePhoto = (placePhoto: PlacePhoto) =>
  makePlaceEditPhoto({
    id: placePhoto.location,
    result: placePhoto,
  });

export type PlaceEditState = {
  details: PlaceDetails,
  address: ?PlaceAddress,
  coordinates: ?PlaceCoordinates,
  addressSuggestions: List<AddressSuggestion>,
  addressSuggestionsLoading: boolean,
  geocodeLoading: boolean,
  photos: List<PlaceEditPhoto>,
  schedule: PlaceSchedule,
};
