// @flow
import * as React from "react";
import { translate, type TranslatorProps } from "react-i18next";
import { compose, pure } from "recompose";
import { Toolbar as BaseToolbar, Typography, withStyles } from "material-ui";
import { ArrowBack as ArrowBackIcon } from "material-ui-icons";
import classnames from "classnames";
import IconButton from "./IconButton";

type Props = {
  ...$Exact<TranslatorProps>,
  classes: Object,
  inverse: boolean,
  title: React.Node,
  onGoBack: () => void,
  actions: React.Node,
};

const styles = theme => ({
  backIcon: {
    marginLeft: -12,
    marginRight: 20,
  },
  actionIcon: {
    marginLeft: 20,
  },
  actionsContainer: {
    marginLeft: "auto",
    marginRight: -12,
  },
  flex: {
    flexGrow: 1,
    flexShrink: 1,
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  inverse: {
    color: theme.palette.primary.contrastText,
  },
});

const enhance = compose(
  pure,
  translate(),
  withStyles(styles)
);

const Toolbar = ({ t, classes, inverse, title, onGoBack, actions }: Props) => (
  <BaseToolbar>
    {onGoBack && (
      <IconButton
        icon={ArrowBackIcon}
        className={classnames(classes.backIcon, {
          [classes.inverse]: inverse,
        })}
        aria-label={t("general.back")}
        onClick={onGoBack}
      />
    )}
    {title && (
      <Typography
        variant="title"
        className={classnames(classes.flex, {
          [classes.inverse]: inverse,
        })}
      >
        {title}
      </Typography>
    )}
    <div className={classes.actionsContainer}>
      {React.Children.toArray(actions).map((action, index) =>
        React.cloneElement(action, {
          key: index, // eslint-disable-line react/no-array-index-key
          className: classnames(action.className, classes.actionIcon, {
            [classes.inverse]: inverse,
          }),
        })
      )}
    </div>
  </BaseToolbar>
);

export default enhance(Toolbar);
