// @flow
export const DRAWER_OPEN = "DRAWER_OPEN";
export const DRAWER_CLOSE = "DRAWER_CLOSE";

export type Action =
  | {|
      type: "DRAWER_OPEN",
    |}
  | {|
      type: "DRAWER_CLOSE",
    |};

export const drawerOpen = (): Action => ({
  type: DRAWER_OPEN,
});

export const drawerClose = (): Action => ({
  type: DRAWER_CLOSE,
});
