"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require("react");

var React = _interopRequireWildcard(_react);

var _url = require("url");

var _url2 = _interopRequireDefault(_url);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

var BASE_URL = "https://maps.googleapis.com/maps/api/staticmap";

exports.default = function (_ref) {
  var language = _ref.language,
      apiKey = _ref.apiKey,
      width = _ref.width,
      height = _ref.height,
      center = _ref.center,
      zoom = _ref.zoom,
      marker = _ref.marker,
      _static = _ref.static,
      onClick = _ref.onClick,
      onBoundsChange = _ref.onBoundsChange,
      rest = _objectWithoutProperties(_ref, ["language", "apiKey", "width", "height", "center", "zoom", "marker", "static", "onClick", "onBoundsChange"]);

  var srcUrl = _url2.default.format(_extends({}, _url2.default.parse(BASE_URL), {
    query: {
      language: language,
      key: apiKey,
      size: width + "x" + height,
      center: center[0] + "," + center[1],
      zoom: "" + zoom,
      markers: marker ? marker[0] + "," + marker[1] : null
    }
  }));

  return React.createElement("img", _extends({}, rest, { width: width, height: height, src: srcUrl }));
};