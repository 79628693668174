"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _Observable = require("rxjs/Observable");

var _geofire = require("geofire");

var _geofire2 = _interopRequireDefault(_geofire);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var getPlacesInRadius = function getPlacesInRadius(firebase, center, radius) {
  return _Observable.Observable.create(function (observer) {
    var geofire = new _geofire2.default(firebase.database().ref("map"));
    var query = geofire.query(center, radius);

    query.on("ready", function () {
      return observer.next({
        type: "ready"
      });
    });

    query.on("key_entered", function (key, location, distance) {
      return observer.next({
        type: "key_entered",
        key: key,
        location: location,
        distance: distance
      });
    });

    query.on("key_exited", function (key) {
      return observer.next({
        type: "key_exited",
        key: key
      });
    });

    query.on("key_moved", function (key, location, distance) {
      return observer.next({
        type: "key_moved",
        key: key,
        location: location,
        distance: distance
      });
    });

    return function () {
      return query.cancel();
    };
  });
};

exports.default = getPlacesInRadius;