import * as React from "react";
import styled from "styled-components";
import { LinearProgress } from "material-ui/Progress";

const Container = styled.div`
  width: 100%;
  height: 0;
  overflow: visible;
`;

const ProgressBar = () => (
  <Container>
    <LinearProgress />
  </Container>
);

export default ProgressBar;
