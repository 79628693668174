// @flow
import * as React from "react";
import { compose, mapProps, defaultProps as withDefaultProps } from "recompose";
import { Avatar, withStyles } from "material-ui";
import classnames from "classnames";
import type { Member } from "../models";
import InitialsAvatar from "./InitialsAvatar";

type Props = {
  ...$Exact<React.ElementProps<typeof Avatar>>,
  member: Member,
  size: "large" | "normal",
};

const defaultProps = {
  size: "normal",
};

const styles = {
  large: {
    width: 96,
    height: 96,
  },
  normal: {
    width: 40,
    height: 40,
  },
};

const enhance = compose(
  withDefaultProps(defaultProps),
  withStyles(styles),
  mapProps(({ classes, size, className, ...rest }) => ({
    className: classnames(className, {
      [classes.large]: size === "large",
      [classes.normal]: size === "normal",
    }),
    ...rest,
  }))
);

const MemberAvatar = ({ classes, member, ...rest }: Props) =>
  member.photoURL ? (
    <Avatar {...rest} src={member.photoURL} />
  ) : (
    <Avatar {...rest}>
      <InitialsAvatar text={member.displayName} />
    </Avatar>
  );

export default enhance(MemberAvatar);
