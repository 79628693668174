"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require("react");

var React = _interopRequireWildcard(_react);

var _url = require("url");

var _url2 = _interopRequireDefault(_url);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

var STATIC_MAPS_BASE_URL = "https://static-maps.yandex.ru/1.x/";

var YandexStaticMap = function YandexStaticMap(_ref) {
  var _static = _ref.static,
      language = _ref.language,
      width = _ref.width,
      height = _ref.height,
      center = _ref.center,
      zoom = _ref.zoom,
      marker = _ref.marker;

  // See: https://tech.yandex.com/maps/doc/staticapi/1.x/dg/concepts/input_params-docpage/
  var query = {
    lang: language,
    l: "map",
    ll: center[1] + "," + center[0],
    z: "" + zoom,
    size: width + "," + height
  };

  if (marker) {
    // See: https://tech.yandex.com/maps/doc/staticapi/1.x/dg/concepts/markers-docpage/
    query.pt = marker[1] + "," + marker[0] + ",comma";
  }

  var srcUrl = _url2.default.format(_extends({}, _url2.default.parse(STATIC_MAPS_BASE_URL), {
    query: query
  }));

  return React.createElement("img", { width: width, height: height, src: srcUrl });
};

exports.default = YandexStaticMap;