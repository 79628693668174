"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _operators = require("rxjs/operators");

var _auth = require("../auth");

var _getMember = require("./getMember");

var _getMember2 = _interopRequireDefault(_getMember);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var getCurrentMember = function getCurrentMember(api) {
  return (0, _auth.getAuthenticatedUser)(api).pipe(
  // $FlowFixMe
  (0, _operators.switchMap)(function (user) {
    return (0, _getMember2.default)(api, user.uid);
  }));
};

exports.default = getCurrentMember;